class GlobaisIsAth {
    static staticIsAuth = false;
}

export async function setStaticIsAuth(bool) {
    GlobaisIsAth.staticIsAuth = bool;
    return {};
}

export function getStaticIsAuth() {
    let boll = GlobaisIsAth.staticIsAuth;
    return boll;
}

export function getAccessTRoken() {
    const copyAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiaccess-tokenIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.H4Qd4JwhH5Mb6O4YtRA35MUv6AWI9fKXdB1qwnpPcZ0';
    return copyAccessToken;
}


class GlobaisPrimaryTourGuiado {
    static primaryTourGuiado = false;
}

export function setPrimaryTourGuiado(boll) {
    GlobaisPrimaryTourGuiado.primaryTourGuiado = boll;
    return boll;
}

export function getPrimaryTourGuiado() {
    let value = GlobaisPrimaryTourGuiado.primaryTourGuiado;
    return value;
}