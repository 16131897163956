import '@aws-amplify/ui-react/styles.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import './App.css';
import arrow from './assets/images/arrow_forward_ios.svg';
import CustomAuthenticator from './components/authenticator/Authenticator';
import Sidebar from './components/sidebar/Sidebar';
import Topbar from './components/topbar/Topbar';
import AppProvider from './hooks';
import AnimesFaq from './pages/animesFaq/AnimesFaq';
import SwitchWorkspace from './pages/changeWorkspace/changeWorkspace';
import CreatePublicationData from './pages/createPublicationData/CreatePublicationData';
import CreatePublicationFree from './pages/createPublicationFree/CreatePublicationFree';
import CreateExternalVideo from './pages/createExternalVideo/CreateExternalVideo';
import DetailsPublication from './pages/detailsPublication/DetailsPublication';
import HelpCenter from './pages/helpCenter/HelpCenter';
import Home from './pages/home/Home';
import ListPublication from './pages/listPublication/ListPublication';
import NotFound from './pages/notFound/NotFound';
import TypePublication from './pages/typePublication/typePublication';
import FormatsControl from './pages/formatsPublication/formatsControl';
import SocialMediaPublication from './pages/socialMediaPublication/SocialMediaPublication';
import SchedulePublication from './pages/schedulePublication/SchedulePublication';
import ListSocialMediaPublication from './pages/listSocialMediaPublication/ListSocialMediaPublication';
import Calendar from './pages/calendar/Calendar';
import WhatsappOnboarding from './components/whatsappOnboarding/WhatsappOnboarding';
import Route from './Route';

function App() {

  const toggleSidebar = () => {
    document.querySelector('#root').classList.toggle("sidebar-collapsed");
  }

  const mediaQuery = window.matchMedia('(max-width: 768px)');

  const handleMediaChange = (e) => {
    if (e.matches) {
      setTimeout(() => {
        document.querySelector('#root')?.classList.add("sidebar-collapsed");
      }, 100)
    }
  }

  useEffect(() => {
    mediaQuery.addListener(handleMediaChange);
    handleMediaChange(mediaQuery);
  }, [mediaQuery]);


  return (
    <AppProvider>
      <Router>
        <CustomAuthenticator>
          <Switch>
            <Route path="/new-group">
              <div className="main">
                <SwitchWorkspace />
              </div>
            </Route>

            <Route tenanted exact path="/switch-workspace">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={() => toggleSidebar()}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <SwitchWorkspace />
                </div>
              </div>
            </Route>

            <Route tenanted path="/edit-workspace/:workspace_id">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={() => toggleSidebar()}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <SwitchWorkspace />
                </div>
              </div>
            </Route>

            <Route exact tenanted path="/">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={() => toggleSidebar()}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <Home />
                </div>
              </div>
            </Route>

            <Route tenanted path="/animation/list">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={() => toggleSidebar()}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <ListPublication />
                </div>
              </div>
            </Route>

            <Route tenanted path="/animation/details/:publication_id">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={() => toggleSidebar()}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <DetailsPublication />
                </div>
              </div>
            </Route>

            <Route tenanted path="/help-center">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={() => toggleSidebar()}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <HelpCenter />
                </div>
              </div>
            </Route>

            <Route tenanted path="/animation/create/free">
              <CreatePublicationFree />
            </Route>

            <Route tenanted path="/animation/create/data">
              <CreatePublicationData />
            </Route>

            <Route tenanted path="/animation/create/externalVideo">
              <CreateExternalVideo />
            </Route>

            <Route tenanted path="/typePublication">
              <TypePublication />
            </Route>

            <Route tenanted path="/animes-faqs">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={() => toggleSidebar()}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <AnimesFaq />
                </div>
              </div>
            </Route>

            <Route tenanted path="/details/formatsPublication/:type_publication/:publication_id">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={() => toggleSidebar()}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <FormatsControl />
                </div>
              </div>
            </Route>

            <Route tenanted path="/socialMediaPublication/:type_publication/:publication_id">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={toggleSidebar}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <SocialMediaPublication/>
                </div>
              </div>
            </Route>

            <Route tenanted path="/schedulePublication/:media_id">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={toggleSidebar}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <SchedulePublication />
                </div>
              </div>
            </Route>

            <Route tenanted path="/listSocialMediaPublication">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={toggleSidebar}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <ListSocialMediaPublication />
                </div>
              </div>
            </Route>

            <Route tenanted path="/calendar">
              <div className="main">
                <Sidebar />
                <div className="sidebarArrow" onClick={toggleSidebar}>
                  <img src={arrow} alt="seta para esquerda" />
                </div>
                <div className="content">
                  <Topbar />
                  <Calendar />
                </div>
              </div>
            </Route>

            <Route tenanted path="/onboarding">
              <WhatsappOnboarding  />
            </Route>
            
            <Route path="*" tenanted >
              <NotFound />
            </Route>

          </Switch>

        </CustomAuthenticator>
      </Router>
    </AppProvider>
  );
}

export default App;