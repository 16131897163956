export const downloadFiles = (url, type) => new Promise(function(resolve, reject) {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', `${url}`, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
      let urlCreator = window.URL || window.webkitURL;
      let imageUrl = urlCreator.createObjectURL(this.response);
      let tag = document.createElement('a');
      tag.href = imageUrl;
      tag.target = '_blank';
      if(type === 'html') tag.download = 'animes.html'; 
      else tag.download = 'animes-videos.mp4'
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      resolve("OK")
    };

    xhr.onerror = err => {
      alert('Failed to download picture');
      reject(`ERROR DOWNLOAD FILE: ${err}`);
    };

    xhr.send();
})