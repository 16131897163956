import "./listSocialMediaPublication.css";
import React, { useState } from 'react';
import { TourGuiadoListSocialMediaPublication } from './TourGuiadoListSocialMediaPublication';
import ListSocialmediaPosts from '../../components/listSocialmedia/ListSocialmediaPosts';
import ListSocialmediaSchedule from '../../components/listSocialmedia/ListSocialmediaSchedule';

export default function ListSocialMediaPublication() {

    const [toggleList, setToggleList] = useState('publications');


    return (
        <TourGuiadoListSocialMediaPublication>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="pageBookmark">
                            <p>Início / </p>
                            <span>Minhas Postagens e Agendamentos</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="titleListSocialMediaPublication">
                            <h1>Publicações e Agendamentos</h1>
                        </div>
                    </div>
                </div>
                <div className="contentListSocialMediaPublication">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="navigation">
                                <button className={toggleList === 'publications' ? 'active' : ''} onClick={() => setToggleList('publications')} data-tour="step-list-social-media-publication-01">Publicados</button>
                                <button className={toggleList === 'schedules' ? 'active' : ''} onClick={() => setToggleList('schedules')} data-tour="step-list-social-media-publication-17">Agendados</button>
                            </div>
                        </div>
                    </div>
                    {toggleList === 'publications' && <ListSocialmediaPosts />}
                    {toggleList === 'schedules' && <ListSocialmediaSchedule/>}
                </div>
            </div>
        </TourGuiadoListSocialMediaPublication>
    )
}