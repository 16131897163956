import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { TourGuiadoSocialMedia } from '../../pages/socialMediaPublication/TourGuiadoSocialMedia';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import calendar from '../../assets/images/calendar.png';
import copy from '../../assets/images/copiar.svg';
import facebookName from '../../assets/images/facebook-name.png';
import logoFacebook from '../../assets/images/logo-facebook.png';
import arrowCollapse from '../../assets/images/seta-collapse.svg';
import upload from '../../assets/images/upload.svg';
import api from '../../config/api';
import apiSocial from "../../config/api-social";
import FacebookLoginButton from '../facebook/FacebookLoginButton';
import mockupFeedTop from '../../assets/images/mockup-facebook-feed-top.png';
import mockupFeedBottom from '../../assets/images/mockup-facebook-feed-bottom.png';
import mockupReelsTop from '../../assets/images/mockup-facebook-reels-top.png';
import mockupReelsRight from '../../assets/images/mockup-facebook-reels-right.png';
import { addUpperCasePrimaryChar } from '../../helpers/addUpperCasePrimaryChar';

export const FacebookSocialMedia = ({ CustomToggle, publication_id }) => {
  const [checkboxActiveFacebook, setCheckboxActiveFacebook] = useState(false);
  const [activeFacebook, setActiveFacebook] = useState(false);
  const [thumbnailFaceFile, setThumbnailFaceFile] = useState({});

  const [pageSelected, setPageSelected] = useState(null)
  const [dataListFacebook, setDataListFacebook] = useState([]);
  const [loadingFacebook, setLoadingFacebook] = useState({
    verifyAuth: true,
    connectionFacebook: true,
    connectionFacebookPages: false,
    disconnectionFacebook: false,
  });
  const [facebookPages, setFacebookPages] = useState([])
  const { user } = useAuthenticator((context) => [context.user]);
  const [publicationFace, setFacePublication] = useState({ document: {}, layers: [] });

  useEffect(() => {
    loadData();

    return () => {
      setLoadingFacebook({
        verifyAuth: true,
        connectionFacebook: true,
        connectionFacebookPages: false,
        disconnectionFacebook: false,
      });
      setFacebookPages([]);
      setFacePublication({ document: {}, layers: [] });
      setCheckboxActiveFacebook(false);
      setActiveFacebook(false);
      setDataListFacebook([]);
      setPageSelected(null);
    }
    // eslint-disable-next-line
  }, [user]);

  const handleClickCopy = (text) => navigator.clipboard.writeText(text);

  const loadData = useCallback(async () => {
    const pub = (await api.get(`/publications/one/${user.attributes.sub}/${publication_id}`)).data;
    const { documentInfo, layerInfo } = pub;

    setFacePublication({
      document: documentInfo,
      layerInfo: layerInfo
    });

    let social_network = 'facebook';
    const medias = (await api.get(`/medias/search/${documentInfo.id}/${social_network}`)).data;

    await apiSocial.get('/api/facebook/find-user-facebook')
      .then(({ data: boll }) => {
        setActiveFacebook(boll);
        setCheckboxActiveFacebook(boll);
      }).catch(e => console.error(e));

    const facebook = medias
      .map(currentMedia => {
        if (currentMedia.extension === 'html') {
          return {
            key: currentMedia.id,
            format: currentMedia.format_social_network,
            size: `${currentMedia.doc_width}x${currentMedia.doc_height}`,
            width: currentMedia.doc_width,
            height: currentMedia.doc_height,
            url_print: currentMedia.url_print,
            type: 'Imagem',
            thumbnail: documentInfo.thumbnail
          }
        } else {
          return {
            key: currentMedia.id,
            format: currentMedia.format_social_network,
            size: `${currentMedia.doc_width}x${currentMedia.doc_height}`,
            width: currentMedia.doc_width,
            height: currentMedia.doc_height,
            url_print: currentMedia.url_print.replace('_playerVideo.html', '.mp4'),
            type: 'Video',
            thumbnail: documentInfo.thumbnail
          }
        }
      });

    setDataListFacebook(facebook);

    await apiSocial.get('/api/facebook/pages').then(async ({ data }) => {
      setFacebookPages(data);
      setCheckboxActiveFacebook(true);
      setActiveFacebook(true);
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      setLoadingFacebook((prevState) => ({ ...prevState, connectionFacebook: false, verifyAuth: false }));
    })

    await apiSocial.get('/api/accounts/facebook-page').then(({ data }) => {
      if (data) setPageSelected(String(data));
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      setLoadingFacebook((prevState) => ({ ...prevState, connectionFacebook: false, verifyAuth: false }));
    })

  }, [publication_id, user.attributes.sub])

  const selectPage = useCallback(async (e) => {
    e.preventDefault();
    let { value: page_id } = e.target

    if (!page_id) {
      setPageSelected(null);
      return;
    };

    try {
      setLoadingFacebook((prevState) => ({ ...prevState, connectionFacebookPages: true }));
      await apiSocial.post(`/api/facebook/choose-page/${page_id}`);
      setPageSelected(String(page_id));
    } catch (error) {
      console.error(error);
      setPageSelected(null);
    } finally {
      setLoadingFacebook((prevState) => ({ ...prevState, connectionFacebookPages: false }));
    }
    // eslint-disable-next-line
  }, [])

  const handlStatusMessage = (elem, status, message) => {
    const statusMessage = elem;

    statusMessage.innerHTML = message;
    statusMessage.classList.add(`status-message-${status}`);

    setTimeout(() => {
      statusMessage.innerHTML = "";
      statusMessage.classList.remove(`status-message-${status}`);
    }, 4000);
  }

  const changeFaceHandler = (event) => {
    if (!(!!event.target.files[0])) return;
    const type = event.target.files[0].name.split('.').pop();
    if (event.target.id === 'selection-file-face-thumbnail') {
      if (type === 'png') {
        setThumbnailFaceFile(event.target.files[0])
      }
    }
  };


  const publishToFacebook = useCallback(async (item) => {

    const { key, type: format, url_print, thumbnail } = item;

    let statusText = document.querySelector(`.text-publish-${key}`)
    statusText.innerHTML = 'Enviando...';

    let media_url = '';
    if (format === 'Imagem') media_url = thumbnail
    else media_url = url_print

    setLoadingFacebook((prevState) => ({ ...prevState, publishing: true }));
    const statusMessage = document.getElementById(`status-message-${key}`);

    const formData = new FormData();
    const headers = { 'headers': { "Content-Type": "multipart/form-data" } };

    formData.append("file", thumbnailFaceFile);
    formData.append("publication_id", publicationFace.document.id);
    formData.append("caption", "")
    formData.append("media_id", key)
    formData.append("type", 'facebook')
    formData.append("media_url", media_url)
    formData.append("channel", 'feed')
    formData.append("schedulingMode", "Publicação Imediata")
    formData.append("thumbnail", thumbnail)

    await apiSocial.post(`/api/facebook/create-post`, formData, headers)
      .then(() => {
        handlStatusMessage(statusMessage, 'success', 'Imagem publicada com sucesso.');
      }).catch(() => {
        handlStatusMessage(statusMessage, 'error', 'Opss... não conseguimos publicar sua postagem!');
      }).finally(() => {
        statusText.innerHTML = 'PUBLICAR';
        setLoadingFacebook((prevState) => ({ ...prevState, publishing: false }));
      })
    // eslint-disable-next-line
  }, [])

  const toggleInputIncorporate = className => {
    const element = document.querySelector('.' + className);
    element?.classList.toggle('open');
  }

  const closeModalDisconnect = className => {
    setCheckboxActiveFacebook(true);
    const element = document.querySelector('.' + className);
    element?.classList.toggle('close');
  }

  const handleDisconnectFacebook = async () => {
    try {
      const type = 'facebook';
      setLoadingFacebook((prevState) => ({ ...prevState, disconnectionFacebook: true }));
      await apiSocial.delete(`/api/accounts/${type}`);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingFacebook((prevState) => ({ ...prevState, disconnectionFacebook: false }));
    }
  }

  return (
    <TourGuiadoSocialMedia>
      <>
        <Accordion defaultActiveKey="1" data-tour="step-social-media-02">
          <Card>
            <Card.Header>
              <div className="headerLeft">
                <img className="img-fluid logo-media" src={logoFacebook} alt="" />
                <img className="img-fluid logo-name" src={facebookName} alt="" />
              </div>
              <div className="headerRight">
                {loadingFacebook.verifyAuth ?
                  <div className='spinnerSocialMedia'>
                    <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
                  </div>
                  :
                  <>

                    <input
                      id="status-facebook"
                      type="checkbox"
                      className="toggle toggle-facebook"
                      onChange={() => setCheckboxActiveFacebook(!checkboxActiveFacebook)}
                      defaultChecked={checkboxActiveFacebook}
                      checked={checkboxActiveFacebook}
                    />

                    <label htmlFor="status-facebook">
                      <span className={checkboxActiveFacebook ? "active" : "no-active"}>
                        {checkboxActiveFacebook ? "Conectado" : "Desconectado"}
                      </span>
                    </label>
                    <CustomToggle eventKey="0">
                      <img src={arrowCollapse} alt="Accordion" />
                    </CustomToggle>
                  </>
                }

              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {!loadingFacebook.verifyAuth && !activeFacebook &&
                  <div className="alertConnect">
                    <span>Antes é necessário ativar a rede</span>
                    <FacebookLoginButton type='facebook' />
                  </div>
                }

                {!checkboxActiveFacebook && pageSelected &&
                  <div className="alertConnect modal-disconnect">
                    <button className="btn-close" onClick={() => closeModalDisconnect('modal-disconnect')}></button>
                    <span>Deseja realmente desconectar-se da rede?</span>
                    <button className="btn-disconnect" onClick={() => handleDisconnectFacebook()}>
                      {loadingFacebook.disconnectionFacebook && <Spinner animation="grow" role="status" aria-hidden="true" />}
                      Desconectar
                    </button>
                  </div>
                }

                {checkboxActiveFacebook && activeFacebook &&
                  <div className="select-pages">
                    <span>Qual página deseja realizar a publicação?</span>
                    <select name="select-pages-facebook" id="select-pages-facebook" onChange={(e) => selectPage(e)}>
                      <option key={0} defaultChecked value="">Selecione uma página</option>
                      {facebookPages.length > 0 ? facebookPages.map((page) => {
                        return <option
                          key={page.id}
                          selected={pageSelected === page.id ? true : false}
                          value={page.id}
                        >
                          {page.name}
                        </option>
                      }) : <>Você não possui nenhuma página!</>}
                    </select>
                  </div>
                }

                {loadingFacebook.connectionFacebookPages &&
                  <div className="loadingMedias_socialMedias mt-3">
                    <Spinner animation="grow" role="status" aria-hidden="true" />
                  </div>
                }

                {!loadingFacebook.connectionFacebookPages && !loadingFacebook.medias && checkboxActiveFacebook &&
                  activeFacebook && pageSelected && dataListFacebook.map((item, index) => {
                    let contentIncorporat = "<div>" +
                      "<iframe width='800px' height='450px' src=" + item.url_print + " title='content Incorporat'></iframe>" +
                      "</div>";
                    return (
                      <div className="row" key={index}>
                        <div className="displayBody">
                          <div className="formatPreview">
                            <div className="mockup-content">
                              {item.format === 'facebook-reels' ? <img src={mockupReelsTop} alt="mockup rede social" className="top-reels" /> : <img src={mockupFeedTop} alt="mockup rede social" className="top" />}
                              {item.format === 'facebook-reels' ? <img src={mockupReelsRight} alt="mockup rede social" className="right-reels" /> : <img src={mockupFeedBottom} alt="mockup rede social" className="bottom" />}
                              {item.type === 'Imagem'
                                ? <img
                                  width={270}
                                  height={item.format === 'facebook-feed-quadrado' ? 270 : item.format === 'facebook-feed-horizontal' ? 230 : 400}
                                  src={publicationFace.document.thumbnail}
                                  alt="thumbnail"
                                />
                              : <>
                                {item.height > item.width
                                  ? <video height="400" width="270" src={item.url_print} autoplay controls></video>
                                  : <video height="auto" width="270" src={item.url_print} autoplay controls></video>
                                }
                              </>
                            } 
                          </div>
                          {item.type !== 'Imagem'
                            ? <div className="select-video-cover">
                                <button onClick={() => toggleInputIncorporate('time-video-face'+ index)}>Escolher capa</button>
                                {/* {thumbnailFaceFile && <button className="m-1" onClick={() => setThumbnailFaceFile({})}>Remover</button>} */}
                                <div className={`time-video time-video-face${index}`} >
                                  <label className="upload-thumbnail-face-pub" for="selection-file-face-thumbnail">
                                    <p>Upload da imagem</p>
                                    <input id="selection-file-face-thumbnail" type="file" onChange={(e) => changeFaceHandler(e)} />
                                    <img className="mb-2" src={upload} alt="upload" />
                                    {thumbnailFaceFile ? (
                                      <p>Filename: {thumbnailFaceFile.name} <br /></p>
                                    ) : (
                                      <span>Selecionar arquivo .png</span>
                                    )}
                                  </label>
                                </div>
                              </div>
                              : ''
                            }
                            <div className="publicationText publicationTextSocialMedia">
                              <textarea name="" id="" placeholder="Insira um texto para postagem."></textarea>
                            </div>
                          </div>
                          <div className="formatInformation">
                            {item.format.includes("STORIES") &&
                              <><strong> Publicação nos Stories está em fases de teste! </strong><br /></>
                            }
                            <div className="formatInformationHeader">
                              <Link to={`/schedulePublication/${item.key}`}>
                                <button className="btn-scheduling">
                                  <img src={calendar} alt="calendário" />
                                </button>
                              </Link>
                              <button className="btn-incorporate" data-tip="Clique para incorporar em sites ou blogues." onClick={() => toggleInputIncorporate('input-incorporat-face' + index)}>
                                <span>INCORPORAR</span>
                              </button>
                              <button className="btn-publish" data-tip="Clique para publicar todos os formatos gerados." onClick={() => publishToFacebook(item)}>
                                < ReactTooltip className="customTooltip" />
                                <span className={`text-publish-${item.key}`}>PUBLICAR</span>
                              </button>
                              < ReactTooltip className="customTooltip" />
                            </div>
                            <div className="incorporateCode">
                              <div className={`displayInput input-incorporat-face${index}`} >
                                <input type="text" defaultValue={contentIncorporat} />
                                <button className="btn-copy">
                                  <img src={copy} alt="" onClick={() => handleClickCopy(contentIncorporat)} />
                                </button>
                              </div>
                            </div>
                            <table>
                              <tbody>
                                <tr>
                                  <td className="typeInformation">
                                    <span>Formato:</span>
                                  </td>
                                  <td className="contentInformation">
                                    <span>{addUpperCasePrimaryChar(item.format, '-')}</span>

                                  </td>
                                </tr>
                                <tr>
                                  <td className="typeInformation">
                                    <span>Tamanho:</span>
                                  </td>
                                  <td className="contentInformation">
                                    <span>{item.size}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="typeInformation">
                                    <span>Tipo:</span>
                                  </td>
                                  <td className="contentInformation">
                                    <span>{item.type}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div id={`status-message-${item.key}`}></div>
                      </div>
                    )
                  })}


                {!loadingFacebook.connectionFacebookPages && !loadingFacebook.medias && checkboxActiveFacebook &&
                  activeFacebook && pageSelected && dataListFacebook.length === 0 &&
                  (<p className="text-no-content-compatible">Você não possui nenhum formato gerado compatível com Facebook.</p>)
                }

              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    </TourGuiadoSocialMedia>

  );
}