import './listSocialMedia.css';
import React, { useState, useEffect } from 'react';
import { memo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import options from '../../assets/images/tres-pontos-hor.svg';
import apiSocial from '../../config/api-social';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import MockupSocialNetwork from '../mockupSocialNetwork/MockupSocialNetwork';

function ModalPreviewPosts({ post, postsFilterNetwork, setPostsFilterNetwork }) {
 
  const spinner = (<Spinner animation="grow" size='sm' variant="secondary" />)
  
  const [currentPots, setCurrentPots] = useState({});
  const [loading, setLoading] = useState({
    publish: false,
    delete: false
  });

  const [showDeletePost, setShowDeletePost] = useState(false);
  const handleDeleteShow = () => setShowDeletePost(true);
  const handleDeleteClose = () => setShowDeletePost(false);

  const [showRepeatPublishPost, setShowPublishPost] = useState(false);
  const handleRepeatPublishShow = () => setShowPublishPost(true);
  const handleRepeatPublishClose = () => setShowPublishPost(false);

  useEffect(() => setCurrentPots(post), [post]);

  const closePreviewPublication = className => {
    const element = document.querySelector('.' + className);
    element?.classList.remove('open');
    element?.classList.toggle('close-preview');
  }

  const publishToTikTok = async () => {
    const data = {
      media_id: currentPots.media_id,
      media_url: currentPots.media_url
    };
  
    setLoading(prev => ({...prev, publish: true}));

    apiSocial.post('/api/tiktok/share_video', data).then(({ data: response }) => {
        handlStatusMessage('success', 'Publicado com sucesso!');   
      }).catch(e => {
        console.error(e);
        handlStatusMessage('error', 'Opps... Tente novamente mais tarde.');
      }).finally(() => {
        setLoading(prev => ({...prev, publish: false}));
      })

  }

  const publishToInstagram = async () => {

    const data = {
      caption: "",
      media_id: currentPots.id,
      type: 'instagram',
      image_url: currentPots.channel === 'feed' ? currentPots.media_url : null,
      video_url: currentPots.channel === 'reels' ? currentPots.media_url : null,
      channel: currentPots.channel
    }

    setLoading(prev => ({...prev, publish: true}));
    await apiSocial.post('/api/instagram/create-post', data)
      .then(({ data: response }) => {
        handlStatusMessage('success', 'Publicado com sucesso!');   
      }).catch(e => {
        console.error(e);
        handlStatusMessage('error', 'Opps... Tente novamente mais tarde.');
      }).finally(() => {
        setLoading(prev => ({...prev, publish: false}));
      })

  }

  const publishToFacebook = async () => {
    const data = {
      caption: "",
      media_id: currentPots.id,
      type: 'facebook', 
      schedulingMode: 'Publicação Imediata',
      media_url: currentPots.media_url,
      channel: currentPots.channel
    } 
 
    setLoading(prev => ({...prev, publish: true}));
    await apiSocial.post('/api/facebook/create-post', data)
      .then(({ data: response }) => {
        handlStatusMessage('success', 'Publicado com sucesso!');   
      }).catch(e => {
        console.error(e);
        handlStatusMessage('error', 'Opps... Tente novamente mais tarde.');
      }).finally(() => {
        setLoading(prev => ({...prev, publish: false}));
      })

  }

  const handleRepeatPublication = async () => {
    // Values de currentPots => caption,channel,created_at,id,identifier,media_id,media_url,schedulingMode,type,updated_at,url

    if (currentPots.type === 'tiktok') {
      await publishToTikTok();
    }

    else if (currentPots.type === 'instagram') {
      await publishToInstagram();
    }

    else if (currentPots.type === 'facebook') {
      publishToFacebook();
    }

  }

  const handleDeletePublicationFacebbok = async () => {
    try {
      setLoading(prev => ({...prev, delete: true}));

      await apiSocial.delete(`/api/facebook/${post.id}`);

      setPostsFilterNetwork(postsFilterNetwork.filter((postFilter) => postFilter.id !== post.id));
      handlStatusMessage('success', 'Postagem deletado com sucesso!');
    } catch (error) {
      console.error(error);
      handlStatusMessage('error', 'Opps... Tente novamente mais tarde.');
    } finally {
      setLoading(prev => ({...prev, delete: false}));
    }
  }

  const handlStatusMessage = (status, message) => {
    const statusMessage = document.querySelector('.message-status-modalPreviewPosts');

    statusMessage.innerHTML = message;
    statusMessage.classList.add(`status-message-${status}`);

    setTimeout(() => {
      statusMessage.innerHTML = "";
      statusMessage.classList.remove(`status-message-${status}`);
      handleDeleteClose();
      handleRepeatPublishClose();
      closePreviewPublication('preview-publication');
    }, 3000);
  }

  return ( 
   <>
    <div className="listPublicationPreview preview-publication">
      <button className="btn-close" onClick={() => closePreviewPublication('preview-publication')}></button>
      <div className="img-fluid" data-tour="step-list-social-media-publication-08" >
        <MockupSocialNetwork post={post} />
      </div>
      <div className="displayDropdown">
        <Dropdown show>
          <Dropdown.Toggle>
            <img src={options} alt="seta" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <DropdownItem data-tour="step-list-social-media-publication-09" onClick={handleRepeatPublishShow}>
              Publicar novamente
            </DropdownItem>

            {/* Modal de publicar novamente */}
            <Modal show={showRepeatPublishPost} onHide={handleRepeatPublishClose} backdrop="static" keyboard={false} >
              <Modal.Header closeButton>
                <Modal.Title>Publicar Novamente</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Ao clicar em "Publicar" seu video será publicado novamente.

              <div className='message-status-modalPreviewPosts'></div>
              
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleRepeatPublishClose}>
                  Cancelar
                </Button>
                <Button variant="primary" onClick={handleRepeatPublication}>
                  {loading.publish
                    ? <span className='span-publish-spinner'>Publicando {spinner} </span>
                    : <span className='span-publish-text'>Publicar</span>
                  }
                </Button>
              </Modal.Footer>
            </Modal>

        
              <DropdownItem data-tour="step-list-social-media-publication-10">
                <Link key={`/schedulePublication/${currentPots.media_id}`} to={`/schedulePublication/${currentPots.media_id}`} className="link">
                  Agendar publicação
                </Link>
              </DropdownItem>
        
            {currentPots.type === 'facebook' &&
              <button className="btn-delete-post" onClick={handleDeleteShow}>
                <DropdownItem data-tour="step-list-social-media-publication-11">
                  Excluir Publicação
                </DropdownItem>
              </button>
            }

            {/* Modal de excluir postagem */}
            <Modal show={showDeletePost} onHide={handleDeleteClose} backdrop="static" keyboard={false} >
              <Modal.Header closeButton>
                <Modal.Title>Excluir publicação?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                A publicação será permanentemente excluída do feed.

                <div className='message-status-modalPreviewPosts'></div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDeleteClose}>
                  Cancelar
                </Button>
                <Button variant="primary" onClick={() => handleDeletePublicationFacebbok()}>
                  {loading.delete
                    ? <span className='span-delete-spinner'>Excluindo {spinner} </span>
                    : <span className='span-delete-text'>Excluir</span>
                  }
                </Button>
              </Modal.Footer>
            </Modal>

          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div></>
  )
}

export default memo(ModalPreviewPosts);