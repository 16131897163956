import "./sidebar.css";
import { Link, useLocation } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import logo from '../../assets/images/logo-alternativo-imagineUP.png';
import home from '../../assets/images/pagina-inicial.svg';
import animations from '../../assets/images/minhas-animacoes.svg';
import link from '../../assets/images/link_black.svg';
import exit from '../../assets/images/exit.svg';
import add from '../../assets/images/sinal-mais-branco.svg';
import settings from '../../assets/images/settings-user.svg';
import minebio from '../../assets/images/dataset_linked.svg';
import logoAlternative from '../../assets/images/imagineup_Alternativo.png';
import { Auth } from 'aws-amplify';
import { setStaticIsAuth } from '../../GlobalValiable';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import help from '../../assets/images/account-question.svg';
import support from '../../assets/images/file-document-alert.svg';
import list from '../../assets/images/list.svg'
import calendar from '../../assets/images/calendar-sharp.svg';

export default function Sidebar() {
  const { pathname } = useLocation();

  const handleLogout = async () => {
    await setStaticIsAuth(false);
    await Auth.signOut();
  }

  return (
    <nav id="sidebar">
      <div className="sidebarHeader" >
        <div className="sidebarLogo">
          <img src={logoAlternative} alt="logotipo ImagineUP" className="modalShow" />
          <img src={logo} alt="logotipo ImagineUP" className="modalHide" />
        </div>
        <Link key="/typePublication" to="/typePublication" className="link" >
          <div className="btnCreatePublication" data-tour="step-details-19" data-tour2="step-listPublication-02">
            <img src={add} alt="sinal soma" />
          </div>
        </Link>
      </div>
      <div className="sidebarBody">
        <ul>
          <Link key="/" to="/" className="link">
            <li className={`sidebarListItem ${pathname === '/' ? 'active' : ''}`}>
              <img src={home} alt="casa" />
              <span className="modalHide">Página Inicial</span>
            </li>
          </Link>
          <Link key="/animation/list" to="/animation/list" className="link" >
            <li className={`sidebarListItem ${pathname === '/animation/list' ? 'active' : ''}`} data-tour="step-home-9" >
              <img src={animations} alt="animações" />
              <span className="modalHide">Minhas Publicações</span>
            </li>
          </Link>
          <Link key="/listSocialMediaPublication" to="/listSocialMediaPublication" className="link" >
            <li className={`sidebarListItem ${pathname === '/listSocialMediaPublication' ? 'active' : ''}`} >
              <img src={list} alt="lista" />
              <span className="modalHide">Minhas Postagens e Agendamentos</span>
            </li>
          </Link>
          <Link key="/calendar" to="/calendar" className="link" >
            <li className={`sidebarListItem ${pathname === '/calendar' ? 'active' : ''}`} >
              <img src={calendar} alt="calendário" />
              <span className="modalHide">Calendário</span>
            </li>
          </Link>

          <Dropdown>
            <Dropdown.Toggle drop="down">
              <li className="sidebarListItem" data-tour="step-home-8" >
                <img src={help} alt="ajuda" />
                <span className="modalHide">Central de Ajuda</span>
              </li>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Link key="/animes-faqs" to="/animes-faqs" className="link">
                <li className={`sidebarListItem ${pathname === '/animes-faqs' ? 'active' : ''}`} data-tour="step-home-10">
                  <HelpCenterIcon className="iconsHelperCenter" />
                  <span className="modalHide">FAQ</span>
                </li>
              </Link>
              <Link key="/help-center" to="/help-center" className="link">
                <li className={`sidebarListItem ${pathname === '/help-center' ? 'active' : ''}`} data-tour="step-home-11">
                  <img src={support} alt="suporte" />
                  <span className="modalHide">Suporte</span>
                </li>
              </Link>
            </Dropdown.Menu>
          </Dropdown>

          {process.env.REACT_APP_APP_URL !== "https://www.app.imagineup.com.br" &&
            <Link key="/user/settngs" to="/user/settngs" className="link mt-4" >
              <li className={`sidebarListItem ${pathname === '/user/config' ? 'active' : ''}`} >
                <img src={settings} alt="ajustes" />
                <span className="modalHide">Settings</span>
              </li>
            </Link>
          }


        </ul>
      </div>
      <div className="sidebarFooter">
        <ul>
          <a href="https://www.mineb.io/" target="_blank" rel="noreferrer" data-tour="step-home-06">
            <li className="link mt-3">
              <img src={minebio} alt="" />
              <span className="modalHide">Mineb.io</span>
            </li>
          </a>
          <a href="https://www.cliq.es/" target="_blank" rel="noreferrer" data-tour="step-home-07">
            <li className="link mt-3">
              <img src={link} alt="clips" />
              <span className="modalHide">Encurtador de Link</span>
            </li>
          </a>
          <a href="#closeApp" className="logout link" rel="noreferrer" >
            <li onClick={handleLogout}>
              <img src={exit} alt="seta para direita" />
              <span className="modalHide">Sair</span>
            </li>
          </a>
        </ul>
      </div>
    </nav>

  )
}
