import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
  {
    selector: '[data-tour="step-typePublication-01"]',
    content: 'Essa é a tela de criação de contéudo animado! Existem dois tipos de criação para sua publicação: "Criar Banner Livre" ou "Criar Banner Data"',
  },
  {
    selector: '[data-tour="step-typePublication-02"]',
    content: 'A criação de Banner Livre se baseia em realizar uma leitura de um arquivo com a extensão ".psd" (arquivo do Adobe Photoshop), gerando assim, uma publicação animada.',
  },
  {
    selector: '[data-tour="step-typePublication-03"]',
    content: 'A criação de Banner Data se baseia em realizar uma leitura de um arquivo com a extensão ".json" com dados determiandos (texto, imagens, etc...), gerando assim, uma publicação animada.',
  },
  {
    selector: '[data-tour="step-typePublication-04"]',
    content: 'Escolhar qual o tipo de publicação que deseja criar.',
  },
  // ... 
]

export const TourGuiadoTypePublication = function({children}) {

    const { setSteps, setCurrentStep } = useTour(false);

    useEffect(() => {
      (async () => {
          setCurrentStep(0);
          setSteps(steps);
       })()
      // eslint-disable-next-line 
    }, []);  

    return (
     <> 
      {children}
     </>
    )
}