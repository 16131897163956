import React, { useEffect, useRef, useState } from 'react';
import api from '../../config/api';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useParams } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from 'react-router-dom';
import { downloadFiles } from '../../helpers/downloadFiles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Spinner } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import arrowBack from '../../assets/images/arrow_back.svg';
import help from '../../assets/images/help-grey.svg';
import copy from '../../assets/images/copiar.svg';
import logoFacebook from '../../assets/images/logo-facebook.png';
import facebookName from '../../assets/images/facebook-name.png';
import logoTiktok from '../../assets/images/logo-tiktok.png';
import tiktokName from '../../assets/images/tiktok-name.svg';
import logoInstagram from '../../assets/images/logo-instagram.png';
import instagramName from '../../assets/images/instagram-name.png';
import logoWhatsapp from '../../assets/images/logo-whatsapp.png';
import whatsappName from '../../assets/images/whatsapp-name.png';
import { addUpperCasePrimaryChar } from '../../helpers/addUpperCasePrimaryChar';

export default function FormatsPublicatioVideoFree() {

    const loadSpinner = (<div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '25px' }}>
        <Spinner animation="border" variant="secondary" />
    </div>)
    const { user } = useAuthenticator((context) => [context.user]);

    const baseURLCLIQES = process.env.REACT_APP_CLIQES_URL;

    const endResizeRef = useRef(null);
    const params = useParams();
    const [loading, setLoading] = useState({
        global: false,
        generationResize: false
    });
    const [publication, setPublication] = useState({ document: {}, layers: [] });
    const [dataListResizes, setDataListResizes] = useState([]);
    const [resolutionsCreated, setResolutionsCreated] = useState([]);
    const handleClickCopy = (text) => {
        navigator.clipboard.writeText(text);
    }

    useEffect(() => {
        async function effectInitial() {
            setLoading((prevState) => ({ ...prevState, global: true }));

            try {
                const pub = (await api.get(`/publications/one/${user.attributes.sub}/${params.publication_id}`)).data
                const { documentInfo, layerInfo } = pub;

                setPublication({
                    document: documentInfo,
                    layers: layerInfo
                });

                const medias = (await api.get(`/medias/all/${params.publication_id}`)).data

                let resolutionsCurrent = [];
                let resource = [];

                for (let index = 0; index < medias.length; index++) {
                    const media = medias[index];
                    resolutionsCurrent.push(media.format_social_network);
                    let obj = {
                        index,
                        doc_height: media.doc_height,
                        doc_width: media.doc_width,
                        format_social_network: media.format_social_network,
                        url_shortened_video: `${baseURLCLIQES}/${media.shortened_hash}`,
                        url_print_video: media.url_print.replace('_playerVideo.html', '.mp4')
                    }
                    resource.push(obj);
                }

                setResolutionsCreated(resolutionsCurrent);
                setDataListResizes(resource);

            } catch (error) {
                console.error('Error: ', error.message)
            }

            setLoading((prevState) => ({ ...prevState, global: false }));
        }
        effectInitial();

        return () => {
            setLoading({
                global: false,
                previewResize: false,
                generationResize: false
            });
            setPublication({ document: {}, layers: [] });
            setDataListResizes([]);
            setResolutionsCreated([]);

        }
        // eslint-disable-next-line
    }, [user, params]);

    const erroResize = (messageError) => {
        const erroResize = document.querySelector('.erroResize');
        erroResize.hidden = false;
        erroResize.innerHTML = `${messageError}`;
        setTimeout(() => {
            erroResize.innerHTML = "";
            erroResize.hidden = true;
        }, 3000);
    }

    const handledownloadFiles = async function (url, event) {

        let field = event.target
        let valueOld = field.innerText;

        field.innerText = "Realizando download...";

        downloadFiles(url, 'mp4')
            .then(() => console.info("Download concluido."))
            .catch(() => console.error("Error no download."))
            .finally(() => field.innerText = `${valueOld}`)
    }

    const generateMp4 = async ({ w, h, format }) => {

        if (!w || !h || !format) {
            console.log("Elemento não existe.");
            return {};
        }

        let isResolution = resolutionsCreated.filter(i => i === format);

        if(isResolution.length !== 0) {
            erroResize("Você já possui essa formato.");
            return {};
        }
     
        const url = publication.document.url_video.replace('_playerVideo.html', '.mp4');

        const data = {
            url: url,  
            size: `${w}x${h}`, 
            id: publication.document.id, 
            user_id: user.attributes.sub, 
            thumbnail: publication.document.thumbnail, 
            format_social_network: format,
            type_publication: publication.document.type_publication
        }

        setLoading((prevState) => ({ ...prevState, generationResize: true }));

        api.post('/medias/video-resize', data).then(({data: res}) => {
            console.log(res);
            let { hash_video, url_long } = res

            let resource = dataListResizes;
            resource.push({
                index: resource.length,
                doc_height: h,
                doc_width: w,
                format_social_network: format,
                url_shortened_video: `${baseURLCLIQES}/${hash_video}`,
                url_print_video: url_long.replace('_playerVideo.html', '.mp4')
            });

            setDataListResizes(resource);
            setResolutionsCreated((prev) => ([...prev, format]));

            window.scrollTo({ behavior: 'smooth', top: endResizeRef.current.offsetTop }); 

        }).catch(e => {
            console.log(e);
            erroResize('Opss... Algo de errado, tenta novamente mais tarde.')
        }).finally(() => {
            setLoading((prevState) => ({ ...prevState, generationResize: false }));
        })

    }
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="pageBookmark">
                            <p>Minhas Publicações / Detalhes da Publicação / </p>
                            <span>Formatos para Redes Sociais</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="topBackPage">
                            <Link key="" to={`/animation/details/${params.publication_id}`} className="link">
                                <button>
                                    <img src={arrowBack} alt="seta" />
                                    Cancelar
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <section className="selectFormats">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <h2 className="titleSelectFormats">Escolha os formatos que deseja usar</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="formatsCarousel" >
                                {loading.global ?
                                    loadSpinner
                                    : (
                                        <Splide options={{
                                            perPage: 4,
                                            breakpoints: {
                                                1245: {
                                                    perPage: 3,
                                                },
                                                990: {
                                                    perPage: 2,
                                                },
                                                590: {
                                                    perPage: 1,
                                                }
                                            },
                                            perMove: 1,
                                            rewind: true,
                                        }}> 
                                            <SplideSlide onClick={() => generateMp4({ w: 1080, h: 1080, format: 'facebook-feed-quadrado' })}>
                                                <div className="formatCard">
                                                    <img className="thumbnail square" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                    <div className="formatInformation">
                                                        <div className="formatLogo">
                                                            <img className="logo" src={logoFacebook} alt="logo rede social" />
                                                            <img className="name-facebook" src={facebookName} alt="nome da rede social" />
                                                        </div>
                                                        <span>Feed Quadrado</span>
                                                        <span>1080x1080</span>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                            <SplideSlide onClick={() => generateMp4({ w: 1080, h: 1350, format: 'facebook-feed-vertical' })}>
                                                <div className="formatCard">
                                                    <img className="thumbnail vertical" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                    <div className="formatInformation">
                                                        <div className="formatLogo">
                                                            <img className="logo" src={logoFacebook} alt="logo rede social" />
                                                            <img className="name-facebook" src={facebookName} alt="nome da rede social" />
                                                        </div>
                                                        <span>Feed Vertical</span>
                                                        <span>1080x1350</span>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                            <SplideSlide onClick={() => generateMp4({ w: 1200, h: 630, format: 'facebook-feed-horizontal' })}>
                                                <div className="formatCard">
                                                    <img className="thumbnail facebook-horizontal" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                    <div className="formatInformation facebook-feed-horizontal">
                                                        <div className="formatLogo">
                                                            <img className="logo" src={logoFacebook} alt="logo rede social" />
                                                            <img className="name-facebook" src={facebookName} alt="nome da rede social" />
                                                        </div>
                                                        <span>Feed Horizontal</span>
                                                        <span>1200x630</span>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                            <SplideSlide onClick={() => generateMp4({ w: 1080, h: 1920, format: 'facebook-reels' })}>
                                                <div className="formatCard">
                                                    <img className="thumbnail high" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                    <div className="formatInformation">
                                                        <div className="formatLogo">
                                                            <img className="logo" src={logoFacebook} alt="logo rede social" />
                                                            <img className="name-facebook" src={facebookName} alt="nome da rede social" />
                                                        </div>
                                                        <span>Reels</span>
                                                        <span>1080x1920</span>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                            <SplideSlide onClick={() => generateMp4({ w: 1080, h: 1920, format: 'tiktok-feed' })}>
                                                <div className="formatCard">
                                                    <img className="thumbnail high" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                    <div className="formatInformation">
                                                        <div className="formatLogo">
                                                            <img className="logo" src={logoTiktok} alt="logo rede social" />
                                                            <img className="name-tiktok" src={tiktokName} alt="nome da rede social" />
                                                        </div>
                                                        <span>Feed</span>
                                                        <span>1080x1920</span>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                            <SplideSlide onClick={() => generateMp4({ w: 1080, h: 1080, format: 'instagram-feed-quadrado' })}>
                                                <div className="formatCard">
                                                    <img className="thumbnail square" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                    <div className="formatInformation">
                                                        <div className="formatLogo">
                                                            <img className="logo" src={logoInstagram} alt="logo rede social" />
                                                            <img className="name-instagram" src={instagramName} alt="nome da rede social" />
                                                        </div>
                                                        <span>Feed Quadrado</span>
                                                        <span>1080x1080</span>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                            <SplideSlide onClick={() => generateMp4({ w: 1080, h: 566, format: 'instagram-feed-horizontal' })}>
                                                <div className="formatCard">
                                                    <img className="thumbnail instagram-horizontal" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                    <div className="formatInformation">
                                                        <div className="formatLogo">
                                                            <img className="logo" src={logoInstagram} alt="logo rede social" />
                                                            <img className="name-instagram" src={instagramName} alt="nome da rede social" />
                                                        </div>
                                                        <span>Feed Horizontal</span>
                                                        <span>1080x566</span>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                            <SplideSlide onClick={() => generateMp4({ w: 1080, h: 1350, format: 'instagram-feed-vertical' })}>
                                                <div className="formatCard">
                                                    <img className="thumbnail vertical" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                    <div className="formatInformation">
                                                        <div className="formatLogo">
                                                            <img className="logo" src={logoInstagram} alt="logo rede social" />
                                                            <img className="name-instagram" src={instagramName} alt="nome da rede social" />
                                                        </div>
                                                        <span>Feed Vertical</span>
                                                        <span>1080x1350</span>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                            <SplideSlide onClick={() => generateMp4({ w: 1080, h: 1920, format: 'instagram-reels' })}>
                                                <div className="formatCard">
                                                    <img className="thumbnail high" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                    <div className="formatInformation">
                                                        <div className="formatLogo">
                                                            <img className="logo" src={logoInstagram} alt="logo rede social" />
                                                            <img className="name-instagram" src={instagramName} alt="nome da rede social" />
                                                        </div>
                                                        <span>Reels</span>
                                                        <span>1080x1920</span>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                            <SplideSlide onClick={() => generateMp4({ w: 1200, h: 800, format: 'whatsapp' })}>
                                                <div className="formatCard">
                                                    <img className="thumbnail square" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                    <div className="formatInformation">
                                                        <div className="formatLogo">
                                                            <img className="logo" src={logoWhatsapp} alt="logo rede social" />
                                                            <img className="name-instagram" src={whatsappName} alt="nome da rede social" />
                                                        </div>
                                                        <span>1200x800</span>
                                                    </div>
                                                </div>
                                            </SplideSlide>
                                        </Splide>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </section>

                {loading.generationResize && <div className='mb-4'>{loadSpinner}</div>}
                <div className='erroResize' hidden={true}> </div>
    
                <div className="row">
                    <div className="col-md-12">
                        <div className="title">
                            <h2 className="titleGridFormats">Todos os Formato</h2>
                        </div>
                    </div>
                </div>

                <section className="linkFormats" ref={endResizeRef} >
                    {!loading.global && dataListResizes.length > 0 ? dataListResizes.map((item, index) => {
                        let size = `${item.doc_width}x${item.doc_height}`;
                        return (
                            <div className="row" key={index}>
                                <div className="col-md-12">
                                    <div className="section">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="fieldDetails">
                                                    <label className="labelDisplay">
                                                        Vídeo: {addUpperCasePrimaryChar(item.format_social_network, '-')} ({size})
                                                        <img data-tip="URL da publicação" src={help} alt="" />
                                                        < ReactTooltip className="customTooltip" />
                                                    </label>
                                                    <div className="displayInput copy">
                                                        <input type="text" name="link-click" value={item.url_shortened_video} disabled />
                                                        <div onClick={() => handleClickCopy(`${item.url_shortened_video}`)}>
                                                            <img src={copy} alt='img-icon-copy' />
                                                            <span>Copiar</span>
                                                        </div>
                                                    </div>
                                                    <div className="downloadFiles" name={size} onClick={(event) => handledownloadFiles(item.url_print_video, event)} >
                                                        <span id='downloadHtml' name={`download_${size}`}> <CloudDownloadIcon fontSize="small" /> Download do Video</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <p className='text-center mb-5'>Vocé não possui nenhum formado criado!</p>}

                    <div className="row">
                        <div className="col-md-12">
                            <div className="btnConfirm">
                                <Link to={`/socialMediaPublication/${params.type_publication}/${params.publication_id}`}>
                                    <button >
                                        Quero Publicar
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}