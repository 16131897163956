import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
    {
      selector: '[data-tour="step-formats-publication-01"]',
      content: 'Escolha o formato que deseja usar.',
    },
    {
        selector: '[data-tour="step-formats-publication-02"]',
        content: 'Veja como fica o banner no formato escolhido.',
    },
    {
        selector: '[data-tour="step-formats-publication-03"]',
        content: 'Crie o formato.',
    },
    {
        selector: '[data-tour="step-formats-publication-04"]',
        content: 'Veja todos os formatos que foram criados.',
    },
    {
        selector: '[data-tour="step-formats-publication-05"]',
        content: 'Clique caso queira gerar vídeo do banner.',
    },
    {
        selector: '[data-tour="step-formats-publication-06"]',
        content: 'Clique para baixar o banner em vídeo.',
    },
    {
        selector: '[data-tour="step-formats-publication-07"]',
        content: 'Clique para baixar o banner animado.',
    },
    {
        selector: '[data-tour="step-formats-publication-08"]',
        content: 'Clique e vá para tela de publicar nas redes sociais.',
    },
    // .
    // ... 
  ]
  
  export const TourGuiadoFormatsPublication= function({children}) {
  
      const { setSteps, setCurrentStep } = useTour(false);
  
      useEffect(() => {
        (async () => {
            setCurrentStep(0);
            setSteps(steps);
         })()
  
        // eslint-disable-next-line 
      }, []);  
  
      return (
       <> 
        {children}
       </>
      )
  }