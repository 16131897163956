import React from 'react'
import './search.css';

export default  function Search ({ onChange, value, placeholder }) {
	return (
		<div className="topbarDisplay mb-5">
			<div className="topbarInput" >
				<input onChange={onChange} type="text" id="" name="" value={value} placeholder={placeholder} data-tour="step-faq-03" />
			</div>
        </div>
	)
}
