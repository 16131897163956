import React, { useState } from "react";
import padlock from '../../assets/images/lock.svg';
import envelope from '../../assets/images/mail.svg'
import { Auth } from 'aws-amplify';
import { setStaticIsAuth } from '../../GlobalValiable';

export default function SignUp() {

    const [formState, setFormState] = useState({
        email: '',
        confirmPassword: '',
        password: '',
        confirmationPassowrds: false,
        submittingSignUp: false,

        signedUp: false,

        confirmationCode: '',
        submittingConfirmation: false,
        confirmed: false
    });


    const [loading, setLoading] = useState(false);
    
    const onChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    }
    
    const handleRecoverCode = () => {
        if(formState.confirmed && formState.signedUp) {
            Auth.resendSignUp(formState.email)
            .then(() => {  
                alertMessages(`Um novo código foi enviado com sucesso!`, 'success'); 
            })
            .catch(error => {
                console.error(error)
                alertMessages(`Algo deu errado ao reenviar o código!`, 'error');
            });
        }
    }


    const alertMessages = (text, type) => { // @type => success || error
        let display = document.getElementById(`display-${type}`);
        display.style.display = 'block';
        let msg = document.getElementById(`msg-${type}`);
        msg.innerText = `${text}`;

        setTimeout(() => {
            display.style.display = 'none';
            msg.innerText = "";
        }, 5000);
    }

    const handleSubmitSignUp = () => {
        let { confirmed, signedUp, password, email, confirmPassword, confirmationPassowrds } = formState;

        let currentEmail = email.trim();
        let currentPassword = password.trim();
        let currentConfirmPassword = confirmPassword.trim();

        if(currentEmail === '' || currentPassword === '') return;

        if(currentPassword === currentConfirmPassword) {
            confirmationPassowrds = true;
        } else {
            alertMessages('Opss... Senhas não conferem!', 'error');
        }
        
        if(!confirmed && confirmationPassowrds && !signedUp) {
            setLoading(true);
            setFormState((prevState) => ({ ...prevState, submittingSignUp: true }));

            Auth.signUp(currentEmail, currentPassword)
            .then(() => {
                setLoading(false);
                setFormState((prevState) => ({ ...prevState, signedUp: true, submittingSignUp: false }));               
            })
            .catch(err => {
                setLoading(false);
                setFormState((prevState) => ({ ...prevState, submittingSignUp: false }));
                if(err.name === "UsernameExistsException") {
                alertMessages('E-mail já existente!', 'error');
                } else {
                    alertMessages('Opss... tente novamente mais tarde!!', 'error');
                }
            });
        } else {
            setFormState((prevState) => ({ ...prevState, confirmationPassowrds: false }));
        }

    }

    const handleSubmitConfirmationSignUp = () => {
        const { confirmed, signedUp, email, confirmationCode, password } = formState;

        if(confirmationCode.trim() === '') return;

        if (!confirmed && signedUp) {
            setLoading(true);
            setFormState((prevState) => ({ ...prevState, submittingConfirmation: true }));

            Auth.confirmSignUp(email, confirmationCode)
            .then(() =>  {
                setFormState((prevState) => ({ ...prevState, submittingConfirmation: false, confirmed: true}));
                Auth.signIn(email.trim(), password.trim())
                .then(() => {
                    setLoading(false);
                    setStaticIsAuth(true).then(() => window.location.href = '/');
                })
                .catch((err) => console.error("Erro ao realizar login ao se cadastrar: ", err))
            })
            .catch((err) => {
                setLoading(false);
                setFormState((prevState) => ({ ...prevState, submittingConfirmation: false}));
                
                if(err.name === "ExpiredCodeException" || err.name === "LimitExceededException") {
                    alertMessages('Código incorreto ou limite expirado!', 'error');
                } else {
                    alertMessages('Opss... tente novamente mais tarde!!', 'error');
                }

            });
        }
    }

    return(<>
        {formState.signedUp === false && (
        <div className="formBody createAccount">
            <div className="displayField">
                <div className="imgInput">
                    <img src={envelope} alt="envelope" />
                </div>
                <input type="email" placeholder="Email" name="email" value={formState.email}  onChange={onChange} />
            </div>
            <div className="displayField">
                <div className="imgInput">
                    <img src={padlock} alt="cadeado aberto" />
                </div>
                <input type="password" placeholder="Senha" name="password" value={formState.password} onChange={onChange} />
            </div>
            <div className="displayField">
                <div className="imgInput">
                    <img src={padlock} alt="cadeado aberto" />
                </div>
                <input type="password" placeholder="Confirmar Senha"  name='confirmPassword' value={formState.confirmPassword}  onChange={onChange}/>
            </div>
            
            <div id="display-error"><p id="msg-error">  </p></div>

            <button onClick={handleSubmitSignUp}>
                {loading 
                    ? ("Carregando...")                             
                    : ("Criar Conta")
                }
            </button>
        </div>
        )}
        {formState.signedUp === true && (
            <div className="confirmCode">
                <div className="formHeader">
                    <h1>Confirme sua Conta</h1>
                </div>
                <div className="formBody">
                    <span className="mb-2">Foi enviado um código para seu E-mail</span>
                    
                    <div className="displayField">
                        <div className="imgInput">
                            <img src={envelope} alt="envelope" />
                        </div>
                        <input type="text" placeholder="Code" name="confirmationCode" value={formState.confirmationCode}  onChange={onChange}/>
                    </div>
                    <p className="mb-4 reenviarCode" onClick={handleRecoverCode} >Reenviar o código</p>

                    <div id="display-success"><p id="msg-success">  </p></div>
                    <div id="display-error"><p id="msg-error">  </p></div>

                    <button onClick={handleSubmitConfirmationSignUp} > 
                        {loading 
                                ? ("Verificando...")                             
                                : ("Enviar Código")
                        }
                    </button>
                </div>
            </div>
        )}
    </>)
}       