import React from 'react';
import "./typePublication.css";
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import bannerLivre from '../../assets/images/banner-livre.svg'
import bannerData from '../../assets/images/banner-dados.svg'
import arrowBack from '../../assets/images/arrow_back.svg'
import uploadVideo from '../../assets/images/upload-video.svg'
import help from '../../assets/images/info.svg'
import { TourGuiadoTypePublication } from './TourGuiadoTypePublication';
import { useTour } from '@reactour/tour';

export default function typePublication() {
    // eslint-disable-next-line
    const { setIsOpen } = useTour(false);
        
    return (
        <TourGuiadoTypePublication>
            <div className="typePublication">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="topBackPage">
                                <Link key="/" to="/" className="link">
                                    <button>
                                    <img src={arrowBack} alt="seta" />
                                        Sair
                                    </button>
                                </Link>
                                <button>
                                    <span onClick={() => setIsOpen(true)}> Guia </span>
                                </button>
                            </div>  
                        </div>
                    </div>

                    <div className="typePublicationContent" data-tour="step-typePublication-01">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title">
                                    <h2>Escolha o tipo de banner</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row" data-tour="step-typePublication-04">
                            <div className="col-md-4">
                                <Link key="animation/create/free" to="animation/create/free" className="link" data-tour="step-typePublication-02" >
                                    <button className="btnCreateBanner">
                                        <img className="imgHelpTypeBanner" data-tip="Faça sua animação utilizando um arquivo em .psd" src={help} alt="informação" />
                                        <ReactTooltip className="customTooltip"/>
                                        <img className="imgTypeBanner" src={bannerLivre} alt="" />
                                        Criar Banner Livre
                                    </button>
                                </Link>
                            </div>

                            <div className="col-md-4">
                                <Link key="animation/create/data" to="animation/create/data" className="link" data-tour="step-typePublication-03" >
                                    <button className="btnCreateBanner">
                                        <img className="imgHelpTypeBanner" data-tip="Faça sua animação utilizando um arquivo em .JSON" src={help} alt="informação" />
                                        <img className="imgTypeBanner" src={bannerData} alt="conchetes" />
                                        Criar Banner Data  
                                    </button>
                                </Link>
                            </div>

                            <div className="col-md-4">
                                <Link key="animation/create/externalVideo" to="animation/create/externalVideo" className="link" /*data-tour="step-typePublication-03"*/ >
                                    <button className="btnCreateBanner">
                                        <img className="imgHelpTypeBanner" data-tip="Faça upload de seu vídeo externo" src={help} alt="informação" />
                                        <img className="imgTypeBanner" src={uploadVideo} alt="upload" />
                                        Criar Vídeo Livre 
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </TourGuiadoTypePublication>
    )
}