import { useAuthenticator } from '@aws-amplify/ui-react';
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import apiSocial from '../../config/api-social';

export default function FacebookLoginButton({ type }) {
  const { user } = useAuthenticator((context) => [context.user]);
  const tenant = user.storage['currentTenant'];

  const responseFacebook = (response) => {
    if (response.id) {
      apiSocial.post(`/api/accounts`, {
        identifier: tenant,
        type: "facebook",
        token: response.accessToken,
        token_expiration: response.expiresIn,
        refresh_token: "",
        refresh_token_expiration: 0,
        page_token: "",
        page_token_expiration: 0,
        page_refresh_token: "",
        page_refresh_token_expiration: 0,
        user_data: ""
      })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        })
    }
  }

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP}
      fields="name,email,picture"
      textButton={`${type === "facebook" ? 'CONECTAR-SE COM FACEBOOK' : 'CONECTAR-SE COM INSTAGRAM'}`}
      cssClass={`${type === "facebook" ? 'btn-facebook' : 'btn-instagram'}`}
      scope="pages_show_list,ads_read,instagram_basic,instagram_content_publish,pages_read_engagement,pages_read_user_content,pages_manage_ads,pages_manage_posts,public_profile"
      callback={responseFacebook}
    />
  )
}

