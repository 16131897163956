import './formLogin.css';
import React, { useState } from "react";
import SignUp from './SignUp';
import SignIn from './SignIn';

export default function FormLogin() {

    const [menuForm, setMenuForm] = useState("signIn");

    return(    
        
        <div className='screenSign'>
            <div className="formLogin">

                <div className="formHeader">
                    <ul>
                        <li className={ menuForm === 'signUp' ? 'itemLogin active' : 'itemLogin'} onClick={() => setMenuForm('signIn')}>Entrar</li>
                        <li className={ menuForm === 'signIn' ? 'itemAccount active' : 'itemAccount'} onClick={() => setMenuForm('signUp')}>Criar Conta</li>
                    </ul>
                </div>

                {menuForm === "signIn" && ( <SignIn /> )}
                {menuForm === "signUp" && ( <SignUp /> )}

            </div>
        </div>
    )
}       