import './listSocialMedia.css';
import React, { useState, useEffect } from 'react';
import { memo } from 'react';
import Table from 'react-bootstrap/Table';
import apiSocial from '../../config/api-social';
import { Spinner } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Button from 'react-bootstrap/Button';
import Calendar from 'react-calendar';
import logoFacebook from '../../assets/images/logo-colorful-facebook.png';
import logoInstagram from '../../assets/images/logo-colorful-instagram.png';
import logoTiktok from '../../assets/images/logo-colorful-tiktok.png';
import arrowCollapse from '../../assets/images/seta-collapse.svg';
import { addUpperCasePrimaryChar } from '../../helpers/addUpperCasePrimaryChar';
import ModalPreviewSchedule from './ModalPreviewSchedule';

function ListSocialmediaSchedule () {
    const [posts, setPosts] = useState([]);
    const [scheduleFilterNetwork, setScheduleFilterNetwork] = useState([]);
    const [loadingList, setLoadingList] = useState(false);
    const [socialNetworkFilter, setSocialNetworkFilter] = useState("Todos");
    const [periodFilter, setPeriodFilter] = useState("Todos");
    const [painelPreview, setPainelPreview] = useState({});

    const logos = {
        facebook: logoFacebook,
        tiktok: logoTiktok,
        instagram: logoInstagram
    }

    const addZeroBefore = (n) => ( (n < 10 ? '0' : '') + n );

    const handleChangePeriodFilter = (e) => {
        e.preventDefault();
        let { innerText } = e.target;
        setPeriodFilter(innerText);
    }

    const togglePreviewPublication = (className, post) => {
        setPainelPreview(post);
        const element = document.querySelector('.' + className);
        element?.classList.remove('close-preview');
        element?.classList.toggle('open');
    }

    const toggleCalendar = className => {
        const element = document.querySelector('.' + className);
        element?.classList.remove('close-calendar');
        element?.classList.toggle('open');
    }

    const GetScheduleType = (scheduleType, repeatEvery) => { // post.repeatEvery => day || week
        let type = '';

        const types = {
            norepeat: 'Não se repete',
            custom: 'Personalizado',
        }

        if (scheduleType === 'repeat' && repeatEvery !== null) {
            type = repeatEvery === 'day' ? 'Todos os dias' : 'Semanalmente'
        } else {
            type = types[scheduleType];
        }

        return type;
    }

    useEffect(() => {
        setLoadingList(true);
        apiSocial.get('/api/scheduler').then(({data}) => {
            
            const auxData = [];
            for (const index in data) {
                const iterator = data[index];
                if (iterator.media_url.includes('/thumbnail_')) {
                    iterator.thumbnail = iterator.media_url
                    auxData.push(iterator);
                } else {
                    const arr = iterator.media_url.split('/');
                    const [id_pub, rest] = (arr[arr.length - 1]).split('_'); 
                    const thumbnail = iterator.media_url.replace(`/${id_pub}_${rest}`, `/thumbnail_${id_pub}.png`);
                    iterator.thumbnail = thumbnail;
                    auxData.push(iterator);
                }
            }
            setPosts(auxData);
            setScheduleFilterNetwork(auxData);
        }).catch(e => {
            console.eroor(e);
        }).finally( ()=> {
            setLoadingList(false);
        })

        return () => {
            setPosts([]);
            setLoadingList(false);
            setScheduleFilterNetwork([]);
        }
    },[])

    const handleChangeNetworkFilter = (e) => {
        e.preventDefault();
        let { innerText } = e.target;
        setSocialNetworkFilter(innerText)
        const postsFilter = handleSocialNetworkFilter(innerText)
        setScheduleFilterNetwork(postsFilter);
    }
    
    const handleSocialNetworkFilter = (network) => {
        let postsFilter = [];

        if (network === "Todos") {
            postsFilter = posts;
        } else {
            let auxPosts = posts;
            postsFilter = auxPosts.filter(post => post.type === network.toLowerCase());  
        }
        
        return postsFilter
    }

    const handleSocialNetworkChannelFilter = (filter) => {
        const [network, channel] = filter.split('-');
        const networkFilter = handleSocialNetworkFilter(network);

        let auxPosts = networkFilter;
        let postsFilter = auxPosts.filter(post => post.channel === channel);
        setSocialNetworkFilter(`${addUpperCasePrimaryChar(filter,'-')}`)
        setScheduleFilterNetwork(postsFilter);
    }

    return (
        <div className="listSchedules">
            <div className="row">
                <div className="col-md-12">
                    <div className="filters">
                    <Dropdown className="socialNetwork" data-tour="step-list-social-media-publication-18">
                        <Dropdown.Toggle className="toggle-primary">
                            <div>
                                Rede Social:
                                <span> {socialNetworkFilter} </span>
                            </div>
                            <img src={arrowCollapse} alt="seta" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => handleChangeNetworkFilter(e)}>
                                Todos
                            </Dropdown.Item>
                            <Dropdown className="dropdown-secondary">
                                <div className="displayToggle">
                                    <Dropdown.Item onClick={(e) => handleChangeNetworkFilter(e)}>
                                        <Button>
                                            <img src={logoFacebook} alt="logotipo colorido facebook" />
                                            Facebook
                                        </Button>
                                    </Dropdown.Item>
                                    <Dropdown.Toggle>
                                        <img src={arrowCollapse} alt="seta" />
                                    </Dropdown.Toggle>
                                </div>
                                <Dropdown.Menu onClick={(e) => handleSocialNetworkChannelFilter(e.target.name)}>
                                    <Dropdown.Item name="facebook-feed">Feed</Dropdown.Item>
                                    <Dropdown.Item name="facebook-reels">Reel</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>   
                            <Dropdown  className="dropdown-secondary">
                                <div className="displayToggle">
                                    <Dropdown.Item onClick={(e) => handleChangeNetworkFilter(e)}>
                                        <Button>
                                            <img src={logoInstagram} alt="logotipo colorido instagram"/>
                                            Instagram
                                        </Button>
                                    </Dropdown.Item>
                                    <Dropdown.Toggle>
                                        <img src={arrowCollapse} alt="seta" />
                                    </Dropdown.Toggle>
                                </div>
                                
                                <Dropdown.Menu onClick={(e) => handleSocialNetworkChannelFilter(e.target.name)}>
                                    <Dropdown.Item name="instagram-feed">Feed</Dropdown.Item>
                                    <Dropdown.Item name="instagram-reels">Reels</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>  
                            <Dropdown className="dropdown-secondary">
                                <div className="displayToggle">
                                    <Dropdown.Item onClick={(e) => handleChangeNetworkFilter(e)}>
                                        <Button>
                                            <img src={logoTiktok} alt="logotipo colorido tiktok"/>
                                            TikTok
                                        </Button>
                                    </Dropdown.Item>
                                    <Dropdown.Toggle split id="dropdown-split-basic">
                                        <img src={arrowCollapse} alt="seta" />
                                    </Dropdown.Toggle>
                                </div>
                                <Dropdown.Menu onClick={(e) => handleSocialNetworkChannelFilter(e.target.name)}>
                                    <Dropdown.Item name="tiktok-feed" >Feed</Dropdown.Item>
                                    <Dropdown.Item name="tiktok-stories" >Story</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>  
                        </Dropdown.Menu>                       
                    </Dropdown>                                  
                        <Dropdown className="date"  data-tour="step-list-social-media-publication-19">
                            <Dropdown.Toggle className="toggle-primary">
                                <div>
                                    Período:
                                    <span>{periodFilter}</span> 
                                </div>
                                <img src={arrowCollapse} alt="seta" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div>
                                    <DropdownItem onClick={(e) => handleChangePeriodFilter(e)}>
                                        Todos
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleChangePeriodFilter(e)}>
                                        Próximos 7 dias
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleChangePeriodFilter(e)}>
                                        Próximos 14 dias
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleChangePeriodFilter(e)}>
                                        Este mês
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleChangePeriodFilter(e)}>
                                        Próximo mês
                                    </DropdownItem>
                                    <button className="btn-personalized-date"  onClick={()=> toggleCalendar('preview-calendar')}>
                                        Personalizado
                                    </button>
                                </div>
                                <div className="calendar-filter preview-calendar">
                                    <Calendar
                                        className="custom-calendar" 
                                        returnValue={'range'}
                                        selectRange={true}
                                        locale={'pt-BR'}
                                    />
                                    <p>Selecione uma data ou um período.</p>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>           
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Table striped responsive="md">
                        <thead>
                            <tr>
                                <th data-tour="step-list-social-media-publication-20">Publicação</th>
                                <th data-tour="step-list-social-media-publication-26">Data / Hora programada</th>
                                <th data-tour="step-list-social-media-publication-27">Recorrência da publicação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loadingList && scheduleFilterNetwork.map((post, index) => {
                                const logo = logos[post.type];
                                const date = new Date(post.created_at);
                                let date_pubs = `${addZeroBefore(date.getDate())}-${addZeroBefore(date.getMonth())}-${date.getFullYear()}  `;
                                date_pubs += `às ${addZeroBefore(date.getHours())}:${addZeroBefore(date.getMinutes())}`;
                                
                                return (
                                    <tr key={index}> 
                                        <td>
                                            <button onClick={()=> togglePreviewPublication('preview-publication', post)}>
                                                <img src={logo} alt={"logo rede social " + post.type} />
                                                { addUpperCasePrimaryChar((String(post.channel))) }
                                            </button> 
                                        </td>
                                        <td>
                                            {date_pubs}
                                        </td>
                                        <td>{GetScheduleType(post.scheduleType, post.repeatEvery)}</td> 
                                    </tr>
                                )
                            })}     
                        </tbody>
                    </Table>
                    {loadingList &&
                    <div className="loadingSpiner-listSocialMedia">
                        <Spinner animation="grow" style={{marginBottom: '10px'}} variant="secondary" />
                    </div>
                    }
                    
                    <ModalPreviewSchedule 
                        post={painelPreview} 
                        scheduleFilterNetwork={scheduleFilterNetwork} 
                        setScheduleFilterNetwork={setScheduleFilterNetwork} 
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(ListSocialmediaSchedule);