import "./createExternalVideo.css";
import React, { useState, useRef } from 'react';
import api from '../../config/api';
import { useHistory, Link } from 'react-router-dom';
import { useAuthenticator } from "@aws-amplify/ui-react";
import ReactTooltip from 'react-tooltip';
import upload from '../../assets/images/upload.svg';
import arrowBack from '../../assets/images/arrow_back.svg';
import audio from '../../assets/images/music_note.svg';
import help from '../../assets/images/help-grey.svg';
import { TourGuiadoCreateExternalVideo } from "./TourGuiadoCreateExternalVideo";
import { toDataUpload } from "../../helpers/convertImgToBase64";
import { Spinner } from "react-bootstrap";

export default function CreateExternalVideo() {

  const history = useHistory();
  const { user } = useAuthenticator((context) => [context.user]);

  const [loading, setLoading] = useState({ preview: false, generation: false });
  const [stepCurrent, setStepCurrent] = useState(1);
  const [audioBase64, setAudioBase64] = useState('');
  const [addAudio, setAddAudio] = useState(false);
  const [audioFile, setAudioFile] = useState({});
  const [selectedFile, setSelectedFile] = useState({});
  const [isSelected, setSelected] = useState(false);
  const [nameAndLabels, setNameAndLabels] = useState({ name: '', labelAll: '' });
  const [click, setClick] = useState('');
  const previewRef = useRef(null);
  const [urlVideo, setUrlVideo] = useState('');

  const changeHandler = (event) => {
    if (!(!!event.target.files[0])) return;
    const type = event.target.files[0].name.split('.').pop();

    if (event.target.id === 'selection-file') {
      if (type === 'mp4') {
        setSelectedFile(event.target.files[0]);
        setSelected(true);

        if (urlVideo) {
          URL.revokeObjectURL(urlVideo);
        }

        const urlCreated = URL.createObjectURL(event.target.files[0]);
        setUrlVideo(urlCreated);
      }
    }

  };

  const handlerChangeAudio = async function (event) {
    if (!(!!event.target.files[0])) return;
    const type = event.target.files[0].name.split('.').pop();
    if (!['mp3'].includes(type)) return;

    const audio = event.target.files[0];
    setAudioFile(audio)
    const audioBase64 = await toDataUpload(audio);

    setAudioBase64(audioBase64);
  };

  const alertMessagesError = (text) => { // @type => error
    let display = document.getElementById(`display-error`);
    display.style.display = 'block';
    let msg = document.getElementById(`msg-error`);
    msg.innerText = `${text}`;

    setTimeout(() => {
      display.style.display = 'none';
      msg.innerText = "";
    }, 5000);
  }

  const selectStepByNavigation = (next) => {
    if (next < stepCurrent) {
      selectStep(next);
    }
  }
  
  const selectStep = (step) => {
    let cheklistSteps = false;

    switch (step) {
      case 1:
        //download mp4
        cheklistSteps = true;
        break;
      case 2:
        if (isSelected === true) cheklistSteps = true;
        break;
      case 3:
        const name = nameAndLabels.name.trim();
        const labelAll = nameAndLabels.labelAll.trim();
        if (name && labelAll) cheklistSteps = true;
        break;
      default:
        console.log("Etapa não encontrada.");
        break;
    }

    if (cheklistSteps) {
      setStepCurrent(step);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }

  function handleChangeNameAndLabels(event) {
    const { name, value } = event.target;
    setNameAndLabels((prev) => ({ ...prev, [name]: value }));
  }

  async function handlerGereration() {

    const formData = new FormData();
    const headers = { 'headers': { "Content-Type": "multipart/form-data" } };

    formData.append("files", selectedFile);
    formData.append("name", nameAndLabels.name);
    formData.append("label", nameAndLabels.labelAll);
    formData.append("effect", 'video');
    formData.append("click", click);
    formData.append("typePublcation", 'video-free');
    if (addAudio) {
      formData.append("files", audioFile);
    }

   try {
    setLoading((prevState) => ({ ...prevState, generation: true }));
    const response = await api.post(`publications/create/video/${user.attributes.sub}`, formData, headers);
    URL.revokeObjectURL(urlVideo);
    history.push(`/animation/details/${response.data}`);
   } catch (error) {
    console.error(error);
    alertMessagesError("Opss... Tente novamente mais tarde.");
   } finally {
    setLoading((prevState) => ({ ...prevState, generation: false }));
   }
   
  }

  function handleChangeConfirmeAddAudio() {
    if (addAudio) setAddAudio(false);
    else setAddAudio(true);
  }

  function handleConfirmeRemoverAudio() {
    setAddAudio(false);
    setAudioBase64('');
  }

  return (
    <TourGuiadoCreateExternalVideo stepCreate={stepCurrent}>
      <div className="product productVideo">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="topBackPage">
                <Link key="/typePublication" to="/typePublication" className="link">
                  <button>
                    <img src={arrowBack} alt="" />
                    Cancelar
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="stepWizard">
                <div className="stepWizard-row">

                  <div className="step">
                    <button href="#step-1" type="button" onClick={() => selectStepByNavigation(1)} className={stepCurrent === 1 ? 'button active' : 'button'}>1</button>
                  </div>
                  <div className="step step-2">
                    <button href="#step-2" type="button" onClick={() => selectStepByNavigation(2)} className={stepCurrent === 2 ? 'button active' : 'button'} >2</button>
                  </div>
                  <div className="step">
                    <button href="#step-3" type="button" onClick={() => selectStepByNavigation(3)} className={stepCurrent === 3 ? 'button active' : 'button'} >3</button>
                  </div>

                </div>
                <p className="infoNavigationSteps"><small>Navega entre as etapas após preenchidas.</small></p>
              </div>
            </div>
          </div>

          <div className="productCreate">
            <div className="displayInfoCreatedPublication mb-5 mt-2">
              {stepCurrent >= 2 && selectedFile.name && (<span> <strong>Arquivo: </strong> {selectedFile.name} </span>)}
              {stepCurrent >= 3 && nameAndLabels.name && nameAndLabels.labelAll && (
                <>
                  <span> <strong>Nome da publicação: </strong> {nameAndLabels.name} </span>
                  <span> <strong>Label da publicação: </strong> {nameAndLabels.labelAll} </span>
                </>
              )}
              {stepCurrent >= 3 && click && (<span> <strong>Url de click: </strong> {click} </span>)}
            </div>


            {stepCurrent === 1 &&
              <div className="stepContent" id="step-1">
                <div className="row">
                  <div className="col-md-12">
                    <div className="title">
                      <h2>Faça upload do seu vídeo</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="contentFile">
                      <label htmlFor="selection-file" data-tour="step-createFree1-01">
                        <input id="selection-file" type="file" name="file" onChange={changeHandler} />
                        <img src={upload} alt="" />
                        {isSelected ? (
                          <p>Filename: {selectedFile.name && selectedFile.name} <br /> Size in bytes: {selectedFile.size && selectedFile.size}</p>
                        ) : (
                          <span>Selecionar arquivo .mp4</span>
                        )}
                      </label>
                      <div className="btnNextPage mt-5">
                        <button href="#step-1" onClick={() => selectStep(2)} data-tour="step-createFree1-02">
                          Próximo
                        </button>
                      </div>
                    </div>
                </div>
              </div>
            </div>

            }
            { stepCurrent === 2 && 
            <div className="stepContent" id="step-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="title">
                    <h2>Detalhes da Publicação</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="productFields">
                    <div className="field" data-tour="step-createFree2-01">
                    <label>Nome da Publicação</label>
                      <input id="namePublication" name="name" type="text" value={nameAndLabels.name} onChange={handleChangeNameAndLabels} 
                      />
                    </div>
                    <div className="field" data-tour="step-createFree2-02" >
                      <label>Tags</label>
                      <input id="labels" type="text" name="labelAll" value={nameAndLabels.labelAll} onChange={handleChangeNameAndLabels} 
                      />
                    </div>
                    <div className="field" data-tour="step-createFree2-03">
                      <label className="labelDisplay" >
                        Clique
                        <img data-tip="URL para redirecionamento após clique na publicação" src={help} alt="" />
                        < ReactTooltip className="customTooltip" />
                      </label>
                      <input type="text" placeholder="Insira o link do clique" className="fieldClique" name="click" value={click} onChange={(e) => setClick(e.target.value.trim())}/>
                    </div>
                    <div className="btnNextPage">
                      <button href="#step-2" onClick={() => selectStep(3)} data-tour="step-createFree2-04">
                        Próximo
                      </button>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
            }
            { stepCurrent === 3 && 
            <div className="stepContent" id="step-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="uploadAudio" data-tour="step-createFree3-03">
                    <div className="title">
                      <h2>Adicione um áudio<span>(opcional)</span></h2>
                    </div>
                        {audioBase64 ? (
                          <div className='contentFileAudio ms-5'>
                              <label htmlFor="selection-file-audio">
                                <audio id="audio" className='audioDisplay' autoPlay={false} controls>
                                    <source id="source" src={audioBase64} type="audio/ogg" />
                                </audio>

                                <span onClick={handleConfirmeRemoverAudio} className="btnAddAudio ms-2 me-1"> Trocar</span>

                                <span 
                                  onClick={handleChangeConfirmeAddAudio} 
                                  className={`btnAddAudio ms-2 me-1 ${addAudio ? 'btnAddAudio-status' : ''}`}
                                > 
                                  {addAudio ? 'Adicionado' : 'Adicionar'}
                                </span>
                            </label>
                          </div>
                        ) : (
                            <div className="contentFileAudio">
                                <label htmlFor="selection-file-audio">
                                  <input id="selection-file-audio" type="file" onChange={handlerChangeAudio}  />
                                  <img src={audio} alt="nota musical" />
                                  <span>Faça upload do seu áudio</span>
                              </label>
                            </div>
                          )
                        }
                        <p className="mt-2"><strong>Atenção:</strong> Caso o video ja possua áudio, não recomendamos essa opção.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="productPreview">
                    <div className="title">
                      <h2>Preview</h2>
                    </div>
                    <div ref={previewRef} className="productPreviewVideo" data-tour="step-createFree3-02"> 
                        <video id="preview-video-one" width="400" controls src={urlVideo}>
                          Your browser does not support HTML5 video.
                        </video>
                      <div id="display-error"><p id="msg-error">  </p></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
              <div className="col-md-12">
                <div className="btnGenerateAnimation">
                  <button onClick={() => handlerGereration()} data-tour="step-createFree3-05">
                    {loading.generation ? (
                      <span><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/> Criando...</span>
                      ) : ("Subir Vídeo")}
                  </button>
                </div>
              </div>
            </div>              
            </div>
            }             
          </div>
        </div>
      </div>
    </TourGuiadoCreateExternalVideo>
  );
}
