import './card.css';
import { Link } from 'react-router-dom';
import visibility from '../../assets/images/visibility.svg';
import showMore from '../../assets/images/tres-pontos.svg';
const baseURL_shortened = process.env.REACT_APP_CLIQES_URL;

export default function Card({ banner }) {

    return (
        <div className='bannerCard'>
            <div className="bannerWrap">
                    <div className="bannerImage" style={{ backgroundImage: "url(" + banner.thumbnail + ")" }}>
                        <div className="opacity-detail"></div>
                    </div>
                    <div className="bannerNamePublication">
                        <h1> {banner.name}</h1>
                        <img src={showMore} alt="três pontos um em cima do outro" />
                        </div>
                    <div className="bannerContent"> 
                        <section>
                            <div className="bannerDescription">
                                <div className="bannerDescriptionDisplay">
                                    <div className="discriptionDataDisplay dataLeft">
                                        <h2>Tipo Animação</h2>
                                        <input type="text" defaultValue={banner.effect} /> 
                                    </div>
                                    <div className="discriptionDataDisplay">
                                        <h2>Largura</h2>
                                        <input type="text" defaultValue={banner.doc_width}/> 
                                    </div>
                                </div>
                                <div className="bannerDescriptionDisplay">
                                    <div className="discriptionDataDisplay dataLeft">
                                        <h2>Print</h2>
                                        <input type="text" defaultValue={banner.shortened_print_hash ? `${baseURL_shortened}/${banner.shortened_print_hash}` : ''} />
                                    </div>
                                    <div className="discriptionDataDisplay">
                                        <h2>Altura</h2>
                                        <input type="text" defaultValue={banner.doc_height}/>
                                    </div>
                                </div>
                                <div className="bannerDescriptionDisplay">
                                    <div className="discriptionDataDisplay dataLeft">
                                        <h2>Video</h2>
                                        <input type="text" defaultValue={banner.shortened_video_hash ? `${baseURL_shortened}/${banner.shortened_video_hash}` : ''} />
                                    </div>
                                    <div className="discriptionDataDisplay">
                                        <h2>Tags</h2>
                                        <div className="tagsDisplay">
                                            <input type="text" defaultValue={banner.label}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bannerNumbers">
                                <div className="cardNumber cardClicks">
                                    <h2>Cliques</h2>
                                    <span>{banner.clicks}</span>
                                </div>
                                <div className="cardNumber">
                                    <h2>Impressões</h2>
                                    <span>{banner.prints}</span>
                                </div>
                            </div>
                        </section>

                        <div className="bannerViewDetails">
                            <Link to={`/animation/details/${banner.id}`} className='disable-link'>
                                <button>
                                    <img src={visibility} alt="olho" />
                                    <span>Visualizar</span>
                                </button>
                            </Link>
                        </div>
                </div>
            </div>   
        </div>
    );
}