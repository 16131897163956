import "./createPublicationFree.css";
import React, { useState, useRef } from 'react';
import api from '../../config/api';
import { useHistory, Link } from 'react-router-dom';
import { createPublicationFilter } from '../../helpers/createPublicationFilter';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import RenderPreviewHTML from "../../components/renderPreviewHTML/RenderPreviewHTML";
import ReactTooltip from 'react-tooltip';
import imageAnimation6 from '../../assets/images/tipografoCadente.jpg';
import imageAnimation5 from '../../assets/images/hiperio.png';
import imageAnimation4 from '../../assets/images/imagem- 4490.png';
import imageAnimation3 from '../../assets/images/imagem-4488.png';
import imageAnimation2 from '../../assets/images/imagem-4487.png';
import imageAnimation1 from '../../assets/images/imagem- 4489.png';
import upload from '../../assets/images/upload.svg';
import arrowBack from '../../assets/images/arrow_back.svg';
import audio from '../../assets/images/music_note.svg';
import help from '../../assets/images/help-grey.svg';
import { toDataUpload } from "../../helpers/convertImgToBase64";
import { Spinner } from "react-bootstrap";
import { TourGuiadoCreateFree } from "./TourGuiadoCreateFree";

export default function CreatePublicationFree() {

  const history = useHistory();
  const { user } = useAuthenticator((context) => [context.user]);

  const [stepCurrent, setStepCurrent] = useState(1);
  const [addLabels, setAddLabels] = useState([]);
  const [audioBase64, setAudioBase64] = useState('');
  const [addAudio, setAddAudio] = useState(false);

  const [selectedFile, setSelectedFile] = useState({});
  const [isSelected, setSelected] = useState(false);
  const [loading, setLoading] = useState({ preview: false, generation: false });
  const [effect, setEffect] = useState('');
  const [viewTemplate, setViewTemplate] = useState();
  const [nameAndLabels, setNameAndLabels] = useState({ name: '', labelAll: '' });
  const [click, setClick] = useState('');
  const [publication, setPublication] = useState({ document: {}, layers: [] });
  const previewRef = useRef(null);

  const changeHandler = (event) => {
    if (!(!!event.target.files[0])) return;
    const type = event.target.files[0].name.split('.').pop();
    if (type === 'psd') {
      setSelectedFile(event.target.files[0]);
      setSelected(true);
    }
  };

  const handlerChangeAudio = async function (event) {
    setAudioBase64('');

    if (!(!!event.target.files[0])) return;
    const type = event.target.files[0].name.split('.').pop();
    if (!['mp3'].includes(type)) return;

    const audio = event.target.files[0];
    const audioBase64 = await toDataUpload(audio);

    setAudioBase64(audioBase64);

    setSelected(true);
  };


  const alertMessagesError = (text) => { // @type => error
    let display = document.getElementById(`display-error`);
    display.style.display = 'block';
    let msg = document.getElementById(`msg-error`);
    msg.innerText = `${text}`;

    setTimeout(() => {
      display.style.display = 'none';
      msg.innerText = "";
    }, 5000);
  }

  const handlerUploadPublication = async (animation = effect, isAddAudio) => {
    if (loading.preview || loading.generation) return;
    if (!isSelected || !animation) return;

    setLoading((prevState) => ({ ...prevState, preview: true }));

    let resize = document.getElementById('resize-psd').value;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("resize-psd", resize);
    const headers = { 'headers': { "Content-Type": "multipart/form-data" } };
    window.scrollTo({ behavior: 'smooth', top: previewRef.current.offsetTop });

    const response = await api.post(`/publications/upload`, formData, headers)
      .then(result => {
        const { documentInfo, layerInfo } = result.data;
        setPublication({
          document: documentInfo,
          layers: layerInfo
        });

        return result.data
      })
      .catch((err) => {
        console.error("Error ao buscar informações sobre PSD!: ", err);
        alertMessagesError("Error ao buscar informações no PSD!");
        setLoading((prevState) => ({ ...prevState, preview: false }));
        return {};
      })

    if (!(!!response)) return;

    try {

      if (!!audioBase64 && isAddAudio) {
        response.documentInfo.url_audio = audioBase64;
      }

      const layerFilter = await createPublicationFilter(response.layerInfo);
      const data = {
        documentInfo: { ...response.documentInfo },
        layerInfo: layerFilter
      }

      const template = (await api.post(`publications/render/${animation}`, data)).data;
      if (!!template) {
        setViewTemplate(template);
      }

    } catch (error) {
      alertMessagesError("Error na renderização da animação!");
      console.error("Error na renderização da animação!: ", error);

    } finally {
      setLoading((prevState) => ({ ...prevState, preview: false }));
    }

  }

  const handlerGereration = async () => {
    if (loading.preview || loading.generation) return;
    // let arrLabel = [];
    // nameAndLabels.labelAll.forEach(label => {
    //   if (label.value.trim()) {
    //     arrLabel.push(label.value.trim());
    //   }
    // });
    // const labels = arrLabel.join();
    if (!nameAndLabels.name || !nameAndLabels.labelAll || publication.layers.length === 0) {
      alert("OPS... Campos name, labels e upload de publicação são obrigatórios");
      return;
    }

    const timeAnimation = Number(document.querySelector(".document").id.split("_")[1]);

    setLoading((prevState) => ({ ...prevState, generation: true }));
    const dataCreate = {
      name: nameAndLabels.name,
      label: nameAndLabels.labelAll,
      doc_height: `${publication.document.doc_height}`,
      doc_width: `${publication.document.doc_width}`,
      effect: effect,
      click: click,
      audio: audioBase64,
      timeAnimation: (timeAnimation), // time animation on seg
      typePublcation: 'banner-free'
    }
    try {
      const layerFilter = await createPublicationFilter(publication.layers);
      const { data } = await api.post(`/publications/create/${user.attributes.sub}`, {
        document: dataCreate,
        layer: layerFilter,
        template: viewTemplate
      });
      setLoading((prevState) => ({ ...prevState, generation: false }));
      history.push(`/animation/details/${data}`);
    } catch (error) {
      console.error("Error: ", error);
      setLoading((prevState) => ({ ...prevState, generation: false }));
    };
  }

  // eslint-disable-next-line
  const handleAddLabels = () => {
    let count = document.querySelectorAll('#labels').length + 1;
    setAddLabels([...addLabels, count]);
  };

  // eslint-disable-next-line
  const handleRemoveLabels = (count) => {
    let elementChildren = document.getElementById(`labelsPublication_${count}`);
    while (elementChildren.firstChild) {
      elementChildren.removeChild(elementChildren.firstChild);
    }
    let newLabels = addLabels.filter((item) => item !== count);
    setAddLabels([...newLabels]);
  };

  const selectStepByNavigation = (next) => {
    if (next < stepCurrent) {
      selectStep(next);
    }
  }

  const selectStep = (step) => {
    let cheklistSteps = false;

    switch (step) {
      case 1:
        //download json
        cheklistSteps = true;
        break;
      case 2:
        if (isSelected === true) cheklistSteps = true;
        break;
      case 3:
        const name = nameAndLabels.name.trim();
        const labelAll = nameAndLabels.labelAll.trim();
        if (name && labelAll) cheklistSteps = true;
        break;

      default:
        console.log("Etapa não encontrada.");
        break;
    }

    if (cheklistSteps) {
      setStepCurrent(step);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }

  function clickAnimation(animation) {
    if (loading.preview || loading.generation) return;

    let removeAction = document.querySelector(`[name="${effect}"]`);
    if (removeAction) removeAction.classList.remove('activeAnimation');

    let addAction = document.querySelector(`[name="${animation}"]`);
    addAction.classList.add('activeAnimation');

    setEffect(animation);
    handlerUploadPublication(animation);
  }

  function handleConfirmeAddAudio() {
    setAddAudio(true);
    handlerUploadPublication(effect, true);
  }

  function handleConfirmeRemoverAudio() {
    setAudioBase64('');
    if (addAudio) {
      handlerUploadPublication(effect, false);
      setAddAudio(false);
    }
  }

  function handleChangeNameAndLabels(event) {
    const { name, value } = event.target;
    setNameAndLabels((prev) => ({ ...prev, [name]: value }));
  }

  return (
    <TourGuiadoCreateFree stepCreate={stepCurrent}>
      <div className="product">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="topBackPage">
                <Link key="/typePublication" to="/typePublication" className="link">
                  <button>
                    <img src={arrowBack} alt="" />
                    Cancelar
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="stepWizard">
                <div className="stepWizard-row">

                  <div className="step">
                    <button href="#step-1" type="button" onClick={() => selectStepByNavigation(1)} className={stepCurrent === 1 ? 'button active' : 'button'}>1</button>
                  </div>
                  <div className="step step-2">
                    <button href="#step-2" type="button" onClick={() => selectStepByNavigation(2)} className={stepCurrent === 2 ? 'button active' : 'button'} disabled="disabled">2</button>
                  </div>
                  <div className="step">
                    <button href="#step-3" type="button" onClick={() => selectStepByNavigation(3)} className={stepCurrent === 3 ? 'button active' : 'button'} disabled="disabled">3</button>
                  </div>

                </div>
                <p className="infoNavigationSteps"><small>Navega entre as etapas após preenchidas.</small></p>
              </div>
            </div>
          </div>

          <div className="productCreate">

            <div className="displayInfoCreatedPublication mb-5 mt-2">
              {stepCurrent >= 2 && selectedFile.name && (<span> <strong>Arquivo: </strong> {selectedFile.name} </span>)}
              {stepCurrent >= 3 && nameAndLabels.name && nameAndLabels.labelAll && (
                <>
                  <span> <strong>Nome da publicação: </strong> {nameAndLabels.name} </span>
                  <span> <strong>Label da publicação: </strong> {nameAndLabels.labelAll} </span>
                </>
              )}
              {stepCurrent >= 3 && click && (<span> <strong>Url de click: </strong> {click} </span>)}
            </div>

            {stepCurrent === 1 &&
              <div className="stepContent" id="step-1">
                <div className="row">
                  <div className="col-md-12">
                    <div className="title">
                      <h2>Faça upload do seu PSD</h2>

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="contentFile mb-5">
                      <label htmlFor="selection-file" data-tour="step-createFree1-01">
                        <input id="selection-file" type="file" name="file" onChange={changeHandler} />
                        <img src={upload} alt="" />
                        {isSelected ? (
                          <p>Filename: {selectedFile.name && selectedFile.name} <br /> Size in bytes: {selectedFile.size && selectedFile.size}</p>
                        ) : (
                          <span>Selecionar arquivo .PSD</span>
                        )}
                      </label>
                      <div className="btnNextPage mt-5">
                        <button href="#step-1" onClick={() => selectStep(2)} data-tour="step-createFree1-02">
                          Próximo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            }
            {stepCurrent === 2 &&
              <div className="stepContent" id="step-2">
                <div className="row">
                  <div className="col-md-12">
                    <div className="title">
                      <h2>Detalhes da Publicação</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="productFields">
                      <div className="field" data-tour="step-createFree2-01">
                        <label>Nome da Publicação</label>
                        <input id="namePublication" name="name" type="text" value={nameAndLabels.name} onChange={handleChangeNameAndLabels}
                        />
                      </div>
                      <div className="field" data-tour="step-createFree2-02" >
                        <label>Tags</label>
                        <input id="labels" type="text" name="labelAll" value={nameAndLabels.labelAll} onChange={handleChangeNameAndLabels}
                        />
                      </div>
                      <div className="field" data-tour="step-createFree2-03">
                        <label className="labelDisplay" >
                          Clique
                          <img data-tip="URL para redirecionamento após clique na publicação" src={help} alt="" />
                          < ReactTooltip className="customTooltip" />
                        </label>
                        <input type="text" placeholder="Insira o link do clique" className="fieldClique" name="click" value={click} onChange={(e) => setClick(e.target.value.trim())} />
                      </div>
                      <div className="btnNextPage">
                        <button href="#step-2" onClick={() => selectStep(3)} data-tour="step-createFree2-04">
                          Próximo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {stepCurrent === 3 && 
            <div className="stepContent" id="step-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="title">
                    <h2>Escolha a animação que mais combina com você</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="productAnimations">
                    <Splide data-tour="step-createFree3-01" aria-label="My Favorite Images" options={ {
                      perPage: 4,
                      breakpoints: {
                        1070: {
                          perPage: 3,
                        },
                        920: {
                          perPage: 2,
                        },
                        840: {
                          perPage: 2,
                        },
                        518: {
                          perPage: 1,
                        }
                      },
                      perMove: 1,
                      rewind : true,
                    } }>
                      <SplideSlide>
                        <div className="btnAnimation" onClick={() => clickAnimation('none')} name="none" id="btnUpload">
                          <div className="animationImg noAnimation">
                            
                          </div>
                          <h3>Sem animação</h3>
                        </div>
                      </SplideSlide>
                      <SplideSlide>
                        <div className="btnAnimation" onClick={() => clickAnimation('criativista')} name="criativista" id="btnUpload">
                          <div className="animationImg">
                            <img className="img-fluid" src={imageAnimation1} alt="criativista" />
                          </div>
                          <h3>Criativista</h3>
                        </div>
                      </SplideSlide>
                      <SplideSlide>
                        <div className="btnAnimation" onClick={() => clickAnimation('animania')} name="animania" id="btnUpload">
                          <div className="animationImg">
                            <img className="img-fluid" src={imageAnimation3} alt="animania" />
                          </div>
                          <h3>Animania</h3>
                        </div>
                      </SplideSlide>
                      <SplideSlide>
                        <div className="btnAnimation" onClick={() => clickAnimation('hiperion')} name="hiperion" id="btnUpload">
                          <div className="animationImg">
                            <img className="img-fluid" src={imageAnimation5} alt="Hipérion" />
                          </div>
                          <h3>Hipérion</h3>
                        </div>
                      </SplideSlide>
                      <SplideSlide>
                        <div style={{ cursor: 'not-allowed', opacity: 0.6, }} className="btnAnimation" /**onClick={() => clickAnimation('tipografoCadente')}*/ name="tipografoCadente" id="btnUpload">
                          <div className="animationImg">
                            <img className="img-fluid" src={imageAnimation6} alt="Tipógrafo cadente" />
                          </div>
                          <h3>Tipógrafo cadente</h3>
                        </div>
                      </SplideSlide>
                      <SplideSlide>
                        <div className="btnAnimation" onClick={() => clickAnimation('carouselScenes')} name="carouselScenes" id="btnUpload">
                          <div className="animationImg">
                            <img className="img-fluid" src={imageAnimation2} alt="Carousel de cenas" />
                          </div>
                          <h3>Carousel de cenas</h3>
                        </div>
                      </SplideSlide>
                      <SplideSlide>
                        <div className="btnAnimation" onClick={() => clickAnimation('carouselImages')} name="carouselImages" id="btnUpload">
                          <div className="animationImg">
                            <img className="img-fluid" src={imageAnimation4} alt="Carousel de imagens" />
                          </div>
                          <h3>Carousel imagens</h3>
                        </div>
                      </SplideSlide>
                    </Splide>
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-12">
                  <div className="uploadAudio" data-tour="step-createFree3-03">
                    <div className="title">
                      <h2>Adicione um áudio<span>(opcional)</span></h2>
                    </div>
                        {audioBase64 ? (
                          <div className='contentFileAudio ms-5'>
                              <label htmlFor="selection-file-audio">
                                <audio id="audio" className='audioDisplay' autoPlay={false} controls>
                                    <source id="source" src={audioBase64} type="audio/ogg" />
                                </audio>

                                <span onClick={handleConfirmeRemoverAudio} className="btnAddAudio ms-2 me-1"> Remover</span>

                                <span onClick={handleConfirmeAddAudio} className="btnAddAudio ms-2 me-1"> Adicionar</span>
                            </label>
                          </div>
                        ) : (
                            <div className="contentFileAudio">
                                <label htmlFor="selection-file-audio">
                                  <input id="selection-file-audio" type="file" onChange={handlerChangeAudio}  />
                                  <img src={audio} alt="nota musical" />
                                  <span>Faça upload do seu áudio</span>
                              </label>
                            </div>
                          )
                        }
                  </div>
                </div>
              </div> 
              <div className="row">
                <div className="col-md-12">
                  <div className="productPreview">

                    <label className="displayLabel">
                      <select onChange={() => handlerUploadPublication()} defaultValue={"1"} name="resize-psd" id="resize-psd" data-tour="step-createFree3-04"> 
                        <option className="option-resize" value="1" >Alta</option>
                        <option className="option-resize" value="2" >Média</option>
                        <option className="option-resize" value="3" >Moderada </option>
                      </select>
                    </label>

                    <h2 className="mt-5">Preview</h2>
                    <div ref={previewRef} className="productPreviewVideo" data-tour="step-createFree3-02"> 
                      {loading.preview ? (
                        <>
                          <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', margin: '20px 0px 20px 0px'}}>
                              <Spinner animation="border" variant="secondary" />
                          </div>
                          <span style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontWeight: 'bold',
                              fontSize: '16px',
                              paddingLeft: '10px'
                            }}> 
                              Processando animação...
                          </span>
                          </>
                        ) : 
                        (<RenderPreviewHTML viewTemplate={viewTemplate} />)
                      }
                      <div id="display-error"><p id="msg-error">  </p></div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-md-12">
                <div className="btnGenerateAnimation">
                  <button onClick={handlerGereration} data-tour="step-createFree3-05">
                    {loading.generation ? (
                      <span><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/> Criando...</span>
                    ) : ("Gerar Animação")}
                  </button>
                </div>
              </div>
            </div>              
            </div>
            }
        </div>
      </div>
    </div>
    </TourGuiadoCreateFree >
  );
}
