import React from 'react';
import {
  Redirect, Route as ReactDOMRoute
} from 'react-router-dom';
import api from './config/api';
import apiSocial from './config/api-social';

const Route = ({ tenanted = false, children, ...rest }) => {

  const setTenantAxios = (callback) => {
    api.defaults.headers.common['x-tenant-id'] = localStorage.getItem('currentTenant');
    apiSocial.defaults.headers.common['x-identifier'] = localStorage.getItem('currentTenant');
    callback(true) 
  };

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        
          if (!localStorage.getItem('currentTenant') && tenanted && rest.location.pathname !== '/new-group') {
            return <Redirect
              to={{
                pathname: '/new-group',
                state: { from: location },
              }}
            />
          } else {
            if (api.defaults.headers.common['x-tenant-id'] !== localStorage.getItem('currentTenant') ||
                apiSocial.defaults.headers.common['x-identifier'] !== localStorage.getItem('currentTenant')
              ) { 
                setTenantAxios(() => {}) 
            } 
            return <> {children} </> 
          }
          
      }}
    />
  );

};

export default Route;