import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
  [
    {
      selector: '[data-tour="step-createData1-01"]',
      content: 'Será preciso um arquivo JSON com os dados para gerar uma publicação. Nessa etapa você poderá realizar o download dos seguintes schemas:',
    },
    {
      selector: '[data-tour="step-createData1-02"]',
      content: 'Vamos para a próxima etapa!',
    },
  ],
  [
    {
      selector: '[data-tour="step-createData2-01"]',
      content: 'Depois de preencher o arquivo json. Faça o upload de seu arquivo JSON aqui',
    },
    {
      selector: '[data-tour="step-createData2-02"]',
      content: 'Vamos para a próxima etapa!',
    },
  ],
  [
    {
      selector: '[data-tour="step-createData3-01"]',
      content: 'Informe um nome para sua publicação.',
    },
    {
      selector: '[data-tour="step-createData3-02"]',
      content: 'Informe as Tags(palavras ou pequenas frases) para sua publicação.',
    },
    {
      selector: '[data-tour="step-createData3-03"]',
      content: 'Esse campo é opcional. Aqui você pode adiciona o link de redirecionamento ao clicar sobre a publicação. Basta informar a URL, se não, deixe em branco.',
    },
    {
      selector: '[data-tour="step-createData3-04"]',
      content: 'Vamos para a próxima etapa!',
    },
  ],
  [
    { 
      selector: '[data-tour="step-createData4-01"]',
      content: 'Escolha sua animação.',
    },
    {
      selector: '[data-tour="step-createData4-02"]',
      content: 'Aguarde... Estamos gerando sua publicação.',
    },
    {
      selector: '[data-tour="step-createData4-03"]',
      content: 'Esse campo é opcional. Podemos adicionar um audio junto ao seu banner!',
    },
    {
      selector: '[data-tour="step-createData4-04"]',
      content: 'Ao final clique em "Gerar Animação" e aguarde...',
    },
  ]
  // ... 
]

export const TourGuiadoCreateData = function({stepCreate, children}) {

    const { setSteps, setCurrentStep } = useTour(false);

    useEffect(() => {
      (async () => {
          setCurrentStep(0); 
          setSteps(steps[stepCreate - 1]); 
       })()
    // eslint-disable-next-line 
    }, []);  

    useEffect(() => {
      setCurrentStep(0); 
      setSteps(steps[stepCreate - 1]); 
    // eslint-disable-next-line 
    }, [stepCreate])
    

    return (
     <> 
      {children}
     </>
    )
}