import axios from 'axios';
import { getAccessTRoken } from '../GlobalValiable';

const accessToken = getAccessTRoken(); 

export default axios.create({
    baseURL: process.env.REACT_APP_SOCIAL_URL,
    headers: {
        "access-token": accessToken
    }
});

