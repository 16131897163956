import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
  {
    selector: '[data-tour="step-details-01"]',
    content: 'Aqui você poderá editar o nome de sua publicação.',
  },
  {
    selector: '[data-tour="step-details-02"]',
    content: 'Aqui você poderá editar as tags de sua publicação. Tags são as palavras ou pequenas frases chaves que fazem referência a essa publicação.',
  },
  {
    selector: '[data-tour="step-details-03"]',
    content: 'Após editar o Nome e/ou as Tags da publicação, salve sua alteração.',
  },
  {
    selector: '[data-tour="step-details-04"]',
    content: 'Marca qual o tipo da animação que sua publicação está utilizando.'
  },
  // ------
  {
    selector: '[data-tour="step-details-05"]',
    content: 'Quantitativo de pessoas que interagiram com essa publicação clicando em seu link encurtado.',
  },
  {
    selector: '[data-tour="step-details-06"]',
    content: 'Quantitativo de pessoas que apenas visualizaram essa publicação.',
  },
  // ------ 
  {
    selector: '[data-tour="step-details-07"]',
    content: 'Nessa área criamos ou alteramos o link do clique de redirecionamento quando o usuário clicar em sua publicação.'
  },
  {
    selector: '[data-tour="step-details-08"]',
    content: 'Você pode adiciona ou alterar a URL de redirecionamento.',
  },
  {
    selector: '[data-tour="step-details-09"]',
    content: 'Após informar a URL, clique em "Encurtar link" para salva.',
  },
  {
    selector: '[data-tour="step-details-10"]',
    content: 'Pronto! Aqui ficará o link encurtado da sua URL.',
  },
  // ------ 
  {
    selector: '[data-tour="step-details-11"]',
    content: 'Esse é o link encurtado para a utilização e visualização de sua publicação.',
  },
  {
    selector: '[data-tour="step-details-12"]',
    content: 'Ainda existe a possibilidade de gerar um video da sua publicação animada em apenas um clique.',
  },
  {
    selector: '[data-tour="step-details-13"]',
    content: 'Depois de gerar seu video, aqui irá exibir o link encurtado do seu video.',
  },
  // ------
  {
    selector: '[data-tour="step-details-14"]',
    content: 'O que acha de adicionar um áudio ?',
  },
  {
    selector: '[data-tour="step-details-15"]',
    content: 'Aqui podemos escolhar o áudio para o upload.',
  },
  {
    selector: '[data-tour="step-details-16"]',
    content: 'Depois de escollher áudio que deseja adicionar em sua publucação, salve clicando em "Adicionar Áudio".',
  },
  // ------
  {
    selector: '[data-tour="step-details-17"]',
    content: 'Vizualização do preview da sua publicação.',
  },
  {
    selector: '[data-tour="step-details-18"]',
    content: 'Altener o preview da sua publicação entre: preview de HTML e preview do Video, clicando aqui.',
  },
  {
    selector: '[data-tour="step-details-19"]',
    content: 'Agora vamos criar uma publicação! Clique aqui para criar.',
  },
  // ... 
]

export const TourGuiadoDetails = function({children}) {

    const { setSteps, setCurrentStep } = useTour(false);

    useEffect(() => {
      (async () => {
          setCurrentStep(0);
          setSteps(steps);
       })()
    // eslint-disable-next-line
    }, []);
       

    return (
     <> 
      {children}
     </>
    )
}