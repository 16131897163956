import './RenderPreviewVideo.css';

export default function RenderPreviewVideo({ video, width, height }) {

    return (
        <div className="videoContainer">
            <video className='player-video' loop controls src={video} style={{ width: `${width}px`, height: `${height}px`, border: 'none' }}
            ></video>
        </div>
    )
}
