import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
  {
    selector: '[data-tour="step-faq-01"]',
    content: 'Encontre as dúvidas mais frequentes do animes.',
  },
  {
    selector: '[data-tour="step-faq-02"]',
    content: 'As dúvidas são separadas por tópicos para ajuda no que procura.',
  },
  {
    selector: '[data-tour="step-faq-03"]',
    content: 'Pesquise e encontre o que procura rapidamente.',
  },
  // ... 
]

export const TourGuiadoFaq = function({children}) {

    const { setSteps, setCurrentStep } = useTour(false);

    useEffect(() => {
      (async () => {
          setCurrentStep(0);
          setSteps(steps);
       })()

      // eslint-disable-next-line 
    }, []);  

    return (
     <> 
      {children}
     </>
    )
}