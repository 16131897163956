import React, { useState, useEffect } from 'react';
import { TourGuiadoSocialMedia } from '../../pages/socialMediaPublication/TourGuiadoSocialMedia';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ReactTooltip from 'react-tooltip';
import copy from '../../assets/images/copiar.svg';
import calendar from '../../assets/images/calendar.png';
import arrowCollapse from '../../assets/images/seta-collapse.svg';
import logoWhatsapp from '../../assets/images/logo-whatsapp.png';
import whatsappName from '../../assets/images/whatsapp-name.png';
import mockup from '../../assets/images/mockup-whatsapp-horizontal.png';
import api from '../../config/api';
import apiSocial from '../../config/api-social';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { addUpperCasePrimaryChar } from '../../helpers/addUpperCasePrimaryChar';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

export const WhatsappSocialMedia = ({ CustomToggle, publication_id }) => {

    const [checkboxActiveWhatsapp, setCheckboxActiveWhatsapp] = useState(false);
    const [isAuthenticatedWhatsapp, setIsAuthenticatedWhatsapp] = useState(false);
    const { user } = useAuthenticator((context) => [context.user]);
    const [medias, setMedias] = useState([]);
    // eslint-disable-next-line
    const [whatsPublication, setWhatsPublication] = useState({})
    const [templatesHSM, setTemplatesHSM] = useState([])
    const [templateHSMSelected, setTemplateHSMSelected] = useState([])
    const [loadingWhats, setLoadingWhats] = useState({
        verifyAuthenticate: false,
        connectionWhats: false,
        disconnectionWhats: false,
        medias: false,
        publish: false
    });

    const isUserTiktok = async () => {
        setLoadingWhats((prevState) => ({ ...prevState, verifyAuthenticate: true }));
        apiSocial.get('/api/whatsapp/find-user-whatsapp')
            .then(({ data }) => {
                setCheckboxActiveWhatsapp(data.status)
                setIsAuthenticatedWhatsapp(data.status)
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoadingWhats((prevState) => ({ ...prevState, verifyAuthenticate: false }));
            })
    }

    const loadData = () => {
        try {
            api.get(`/publications/one/${user.attributes.sub}/${publication_id}`)
                .then(pub => {
                    const { documentInfo, layerInfo } = pub.data;

                    setWhatsPublication({
                        document: documentInfo,
                        layerInfo: layerInfo
                    });

                    let social_network = 'whatsapp';
                    api.get(`/medias/search/${publication_id}/${social_network}`)
                        .then(({ data }) => {
                            const medias = data
                                .map(currentMedia => {
                                    if (currentMedia.extension === 'html') {
                                        return {
                                            key: currentMedia.id,
                                            format: currentMedia.format_social_network,
                                            size: `${currentMedia.doc_width}x${currentMedia.doc_height}`,
                                            width: currentMedia.doc_width,
                                            height: currentMedia.doc_height,
                                            url_print: currentMedia.url_print,
                                            type: 'Imagem',
                                            thumbnail: documentInfo.thumbnail
                                        }
                                    } else {
                                        return {
                                            key: currentMedia.id,
                                            format: currentMedia.format_social_network,
                                            size: `${currentMedia.doc_width}x${currentMedia.doc_height}`,
                                            width: currentMedia.doc_width,
                                            height: currentMedia.doc_height,
                                            url_print: currentMedia.url_print.replace('_playerVideo.html', '.mp4'),
                                            type: 'Video',
                                            thumbnail: documentInfo.thumbnail
                                        }
                                    }
                                });

                            setMedias(medias);
                        })
                })

        } catch (e) {
            console.error(e)
        }
    }

    const findListTemplatesHSM = () => {
        apiSocial.get('/api/whatsapp/list-templates').then((templates) => {
            /**Fields */
            //category, id, language, name, status
            setTemplatesHSM(templates.data);
        }).catch(er => {
            if (er.response.data.message === 'Invalid OAuth access token') {
                const statusMessage = document.querySelector('.message-error-global-whatsapp');
                handlStatusMessage(statusMessage, 'error', 'token expirado, Faça a autenticação novamente.', 10000)
                isUserTiktok()
                console.error(er.response.data);
            }
        })
    }

    useEffect(() => {
        isUserTiktok();
        findListTemplatesHSM();
        loadData();
        // eslint-disable-next-line
    }, [publication_id])

    const handleClickCopy = (text) => navigator.clipboard.writeText(text);

    const toggleInputIncorporate = className => {
        const element = document.querySelector('.' + className);
        element?.classList.toggle('open');
    }

    const closeModalDisconnect = className => {
        setCheckboxActiveWhatsapp(true);
        const element = document.querySelector('.' + className);
        element?.classList.toggle('close');
    }

    const publishWhatsapp = async ({ url_print, type, thumbnail, key }) => {
        const to_text = document.querySelector(`#to_text_${key}`).value;
        const to_number = document.querySelector(`#to_number_${key}`).value;
        const to_text_publish = document.querySelector(`.text-publish-${key}`);
        
        const { template } = templateHSMSelected.find(t => t.key === key);
        if (!to_number || !template) return;

        const data = {
            to_number: to_number,
            url_publication: type === 'Imagem' ? thumbnail : url_print,
            templateName: template.name,
            languageCode: template.language,
            text: to_text || '',
            media_id: key
        };

        to_text_publish.innerText = 'ENVIANDO...';
        setLoadingWhats((prevState) => ({ ...prevState, publish: true }));
        await apiSocial.post('/api/whatsapp/publish', data).then((r) => {
            const statusMessage = document.getElementById(`status-message-${key}`);
            handlStatusMessage(statusMessage, 'success', 'Mensagem disparada com sucesso!');
        }).catch(error => {
            const statusMessage = document.getElementById(`status-message-${key}`);
            if (error.response?.data?.message === 'The template expects a text.') {
                handlStatusMessage(statusMessage, 'error', 'O modelo espera um texto.');
            }
            else {
                handlStatusMessage(statusMessage, 'error', 'Error ao publicar no whatsapp');
            }
        }).finally(() => {
            to_text_publish.innerText = 'ENVIAR'
            setLoadingWhats((prevState) => ({ ...prevState, publish: false }));
        })
    }

    const selectTemplateHSM = (e, id_pub) => {
        const id = e.target.value
        const template = templatesHSM.find(item => item.id === id)
        const existPublicationInTemplates = templateHSMSelected.find((pub) => pub.key === id_pub);
        if (existPublicationInTemplates) {
            existPublicationInTemplates.template = template;
        } else {
            setTemplateHSMSelected((prev) => ([...prev, {key: id_pub, template: template}]))
        }
    }

    const handlStatusMessage = (elem, status, message, time = 4000 ) => {
        const statusMessage = elem;

        statusMessage.innerHTML = message;
        statusMessage.classList.add(`status-message-${status}`);

        setTimeout(() => {
            statusMessage.innerHTML = "";
            statusMessage.classList.remove(`status-message-${status}`);
        }, time);
    }

    const onboardingWindow = () => {
        const onBoardingWhats = window.open('/onboarding', '_blank', 'location=0,height=900,width=900,scrollbars=yes,status=yes')
        const setIntervalOnboarding = setInterval(async () => {
            if (!onBoardingWhats || onBoardingWhats.closed) {
                clearInterval(setIntervalOnboarding);
                await isUserTiktok().then(() => {
                    findListTemplatesHSM();
                });
            } else {
                console.log('Onboarding whatsapp')
            }
        }, 1500);
    }

    const handleDisconnectWhats = (e) => {
        console.log('revoke: ' + e.target.name);

        setLoadingWhats((prevState) => ({ ...prevState, disconnectionWhats: true }));

        apiSocial.delete('/api/whatsapp/revoke')
            .then(() => {
                setIsAuthenticatedWhatsapp(false);
                setCheckboxActiveWhatsapp(false)
            }).catch((er) => {
                console.error(er.data);
            }).finally(() => {
                setLoadingWhats((prevState) => ({ ...prevState, disconnectionWhats: true }));
            });
    }

    return (
        <TourGuiadoSocialMedia>
            <section hidden={false}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="titleMediaPublication">
                            <h2>Compartilhar no WhatsApp</h2>
                            <h3>Compartilhe com seus contatos e nos grupos.</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Accordion defaultActiveKey="1" >
                            <Card>
                                <Card.Header>
                                    <div className="headerLeft">
                                        <img className="img-fluid logo-media" src={logoWhatsapp} alt="logotipo do whatsapp" />
                                        <img className="img-fluid logo-name logo-name-whats" src={whatsappName} alt="nome do whatsapp" />
                                    </div>
                                    <div className="headerRight">
                                        {loadingWhats.verifyAuthenticate ?
                                            <div className='spinnerSocialMedia'>
                                                <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
                                            </div>
                                            :
                                            <>
                                                <input
                                                    id="status-whatsapp"
                                                    type="checkbox"
                                                    className="toggle toggle-whatsapp"
                                                    onChange={() => setCheckboxActiveWhatsapp(!checkboxActiveWhatsapp)}
                                                    defaultChecked={checkboxActiveWhatsapp}
                                                    checked={checkboxActiveWhatsapp}
                                                />

                                                <label htmlFor="status-whatsapp">
                                                    <span className={`${checkboxActiveWhatsapp ? "active" : "no-active"}`}>
                                                        {checkboxActiveWhatsapp ? "Conectado" : "Desconectado"}
                                                    </span>
                                                </label>
                                                <CustomToggle eventKey="0">
                                                    <img src={arrowCollapse} alt="seta" />
                                                </CustomToggle>
                                            </>
                                        }
                                    </div>
                                </Card.Header>

                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>

                                        {!loadingWhats.connectionWhats && !isAuthenticatedWhatsapp &&
                                            <div className="alertConnect">
                                                <span>Antes é necessário ativar a rede</span>
                                                <button className="btn-whatsapp" onClick={() => onboardingWindow()}>Conectar-se com whatsApp</button>
                                            </div>
                                        }

                                        {!loadingWhats.verifyAuthenticate && isAuthenticatedWhatsapp && !checkboxActiveWhatsapp &&

                                            <div className="alertConnect modal-disconnect">
                                                <button className="btn-close" onClick={() => closeModalDisconnect('modal-disconnect')}></button>
                                                <span>Deseja realmente desconectar-se da rede?</span>
                                                <button onClick={(e) => handleDisconnectWhats(e)} className="btn-disconnect">Desconectar</button>
                                            </div>
                                        }
                                        <div className="message-error-global-whatsapp"></div>
                                        {/* {templatesHSM?.length > 0 &&
                                                <div className='select-template-hsc mb-5'>
                                                    <select name="select-template-hsc" id="select-template-hsc" onChange={(e) => selectTemplateHSM(e)}>
                                                        <option key={0} defaultChecked value="">Selecionar Template HSM</option>
                                                        {templatesHSM.map((hsm) => {
                                                            return <option value={hsm.id}>
                                                                {hsm.name}
                                                            </option>
                                                        })}
                                                    </select>
                                                </div>
                                            } */}

                                        {isAuthenticatedWhatsapp && medias.map((item, index) => {
                                            let contentIncorporat = "<div>" +
                                                "<iframe width='800px' height='450px' src=" + item.url_print + " title='content Incorporat'></iframe>" +
                                                "</div>";
                                            return (
                                                <div className="row" key={index}>
                                                    <div id={`status-message-${item.key}`}></div>
                                                    <div className="displayBody">
                                                        <div className="formatPreview">
                                                            <img src={mockup} alt="" width="270px" height="400px" />
                                                            <div className="publicationText publicationTextSocialMedia">
                                                                <textarea
                                                                    name={"to_text_" + item.key}
                                                                    id={"to_text_" + item.key}
                                                                    placeholder="Insira o texto da sua mensagem."
                                                                >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                        <div className="formatInformation">
                                                            <div className="formatInformationHeader">
                                                                <Link to={`/schedulePublication/${item.key}`}>
                                                                    <button className="btn-scheduling">
                                                                        <img src={calendar} alt="calendário" />
                                                                    </button>
                                                                </Link>
                                                                <button className="btn-incorporate" data-tip="Clique para incorporar em sites e blogues." onClick={() => toggleInputIncorporate('input-incorporate-whats-' + index)}>
                                                                    < ReactTooltip className="customTooltip" />
                                                                    INCORPORAR
                                                                </button>
                                                                <button className="btn-publish" data-tip="Clique para publicar todos os formatos gerados." onClick={() => publishWhatsapp(item)}>
                                                                    < ReactTooltip className="customTooltip" />
                                                                    <span className={`text-publish-${item.key}`}>ENVIAR</span>
                                                                </button>
                                                            </div>
                                                            <div className="incorporateCode">
                                                                <div className={`displayInput input-incorporate-whats-${index}`}>
                                                                    <input type="text" defaultValue={contentIncorporat} />
                                                                    <button className="btn-copy">
                                                                        <img src={copy} alt="" onClick={() => handleClickCopy(contentIncorporat)} />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {templatesHSM?.length > 0 &&
                                                                <div className='select-template-hsc mb-5'>
                                                                    <select name="select-template-hsc" id="select-template-hsc" onChange={(e) => selectTemplateHSM(e, item.key)}>
                                                                        <option key={0} defaultChecked value="">Selecionar Template HSM</option>
                                                                        {templatesHSM.map((hsm, i) => {
                                                                            return <option key={i} value={hsm.id}>
                                                                                {hsm.name}
                                                                            </option>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            }
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="typeInformation">
                                                                            <span>Enviar para o telefone:</span>
                                                                        </td>
                                                                        <td className="contentInformation">
                                                                            <input
                                                                                type="text"
                                                                                placeholder="55 (99) 9999-9999"
                                                                                name={'to_number_' + item.key}
                                                                                id={'to_number_' + item.key}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="typeInformation">
                                                                            <span>Formato:</span>
                                                                        </td>
                                                                        <td className="contentInformation">
                                                                            <span>{addUpperCasePrimaryChar(item.format, '-')}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="typeInformation">
                                                                            <span>Tamanho:</span>
                                                                        </td>
                                                                        <td className="contentInformation">
                                                                            <span>{item.size}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="typeInformation">
                                                                            <span>Tipo:</span>
                                                                        </td>
                                                                        <td className="contentInformation">
                                                                            <span>{item.type}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
            </section>
        </TourGuiadoSocialMedia>
    )
}