import "./socialMediaPublication.css";
import React, { useContext } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { TourGuiadoSocialMedia } from './TourGuiadoSocialMedia';
import AccordionContext from "react-bootstrap/AccordionContext";
import { Link, useParams } from 'react-router-dom';
import arrowBack from '../../assets/images/arrow_back.svg';
import { InstagramSocialMedia } from '../../components/instagramSocialMedia/InstagramSocialMedia';
import { TiktokSocialMedia } from '../../components/tiktokSocialMedia/TiktokSocialMedia';
import { FacebookSocialMedia } from '../../components/facebookSocialMedia/FacebookSocialMedia';
import { TwitterSocialMedia } from '../../components/twitterSocialMedia/TwitterSocialMedia';
import { WhatsappSocialMedia } from '../../components/whatsappSocialMedia/WhatsappSocialMedia';

function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return ( 
        <button
            className="btn-collapse"
            type="button"
            style={{ transform: isCurrentEventKey ? 'rotate(0deg)' : 'rotate(180deg)' }}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}


export default function SocialMediaPublication() {

    const params = useParams();

    return(
        <TourGuiadoSocialMedia>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="pageBookmark">
                        <p>Minhas Publicações / Detalhes da Publicação / Formatos para Redes Sociais / </p>
                        <span>Publicar nas Redes Sociais</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="topBackPage">
                        <Link key="" to={`/details/FormatsPublication/${params.type_publication}/${params.publication_id}`} className="link">
                            <button>
                                <img src={arrowBack} alt="seta" />
                                Voltar
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <section>
                <div className="row">
                    <div className="col-md-12">
                        <div className="titleMediaPublication">
                            <h2>Publicar nas Redes Sociais</h2>
                            <h3>Publique com rapidez e facilidade em páginas do Facebook, perfis do Instagram,Tiktok e Twitter.</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <FacebookSocialMedia
                            CustomToggle={CustomToggle} 
                            publication_id={params.publication_id}
                        />
                        <InstagramSocialMedia 
                            CustomToggle={CustomToggle} 
                            publication_id={params.publication_id}
                        />
                        <TiktokSocialMedia 
                            CustomToggle={CustomToggle} 
                            publication_id={params.publication_id} 
                        />
                        <TwitterSocialMedia 
                            CustomToggle={CustomToggle} 
                            type_publication={params.type_publication} 
                            publication_id={params.publication_id} 
                        />
                        <WhatsappSocialMedia 
                            CustomToggle={CustomToggle} 
                            publication_id={params.publication_id} 
                        />
                    </div>
                </div>
            </section>
        </div>
        </TourGuiadoSocialMedia>
        
    )
}