import "./home.css";
import { TourGuiadoHome } from './TourGuiadoHome';
import React, { useEffect, useState } from "react";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import Card from "../../components/card/Card";
import { Link } from 'react-router-dom';
import signAdd from '../../assets/images/sinal-mais.svg';
import api from '../../config/api';
import { Spinner } from "react-bootstrap";

export default function Home() {
  
  const [mostAcessedBanners, setMostAcessedBanners] = useState([]); 
  const [mostRecents, setMostRecents] = useState([]); 
  const loading = (<Spinner animation="border" style={{marginBottom: '10px'}} variant="secondary" />);
  const [load, setLoad] = useState({
    acessedBanners: false,
    mostRecents: false
  });
  
  useEffect(() => {
 
    try {
      setLoad((prev) => ({...prev, acessedBanners: true}));
      api.get(`publications/findMostAccessed`).then(({ data }) => {
          setMostAcessedBanners(data);
          setLoad((prev) => ({...prev, acessedBanners: false}));
      })
    } catch(err) {
      console.error("ERROR: ", err);
      setLoad((prev) => ({...prev, acessedBanners: false}));
    }

    try {
      setLoad((prev) => ({...prev, mostRecents: true}));
      api.get(`publications/findMostRecents`).then(({ data }) => {
          setMostRecents(data);
          setLoad((prev) => ({...prev, mostRecents: false}));
      })
    } catch(err) {
      console.error("ERROR: ", err);
      setLoad((prev) => ({...prev, mostRecents: false}));
    }

    return () => {
      setMostAcessedBanners([]);
    };
    
  }, [])

  return (
    <TourGuiadoHome>
      <div className="home">
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <div className="homeDisplay">
                <div className="homeUsername">
                  <h1>Olá!</h1>
                  <p>Veja como está o desenvolvimento das suas publicações</p>
                </div>
                
                <div className="homeCreatePublication">
                  <span>Criar nova publicação</span>
                  <Link to="typePublication">
                    <button>
                      <img src={signAdd} alt="sinal de adicionar" />
                    </button>
                  </Link>
                </div>

              </div>
            </div>
          </div>

          <FeaturedInfo />

          <div className="homeBestPublications">

            <div className="row">
              <div className="col-md-12">
                <div className="featuredTitle">
                  <h2>Melhores Publicações</h2>
                  <div className="rowTitle"></div>
                  <Link to="/animation/list">
                    <span>Ver mais</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row" data-tour="step-home-04">
              <div className="col-md-12">
                <div className="publicationsCard">
                  {mostAcessedBanners.map((item, index) => (
                    <div key={index}><Card banner={item} /></div>
                  ))}
                  {load.acessedBanners === true && loading}
                </div>   
              </div>
            </div>
            
          </div>

          <div className="homeRecentPublications">
            <div className="row">
              <div className="col-md-12">
                <div className="featuredTitle">
                  <h2>Recentes</h2>
                  <div className="rowTitle2"></div>
                  <Link to="/animation/list">
                    <span>Ver mais</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="row" data-tour="step-home-05">
              <div className="col-md-12">
                <div className="publicationsCard">
                  {mostRecents.map((item, index) => (
                    <div key={index}><Card banner={item} /></div>
                  ))}
                  {load.mostRecents === true && loading}
                </div>            
              </div>
            </div>

          </div>
        </div>
      </div>
    </TourGuiadoHome>
  );
}
