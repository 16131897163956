import React from 'react';
import './notFound.css';
import logo from '../../assets/images/logo2-imagineUP.png';

export default function NotFound() {
  return (
    <div className='content-notFound'>
      <img className='logoAnimation-notFound-float' src={logo} alt=""  />
      <div className='box-shadow'></div>
      <h1 className='text-notFound'>Página não encontrada! </h1>
    </div>
  )
}
