import { useEffect, useState } from "react";
import api from '../../config/api';
import "./featuredInfo.css";
import { Spinner } from "react-bootstrap";

export default function FeaturedInfo() {
  
  const [findDataDashboard, setFindDataDashboard] = useState({
    active: 0,
    inactive: 0,
    reactivated: 0
  });
  const [loading, setLoading] = useState(false);

  const loadingSpinner = (<Spinner animation="grow" style={{marginBottom: '10px'}} variant="secondary" />);

  useEffect(() => {
    try {
      (async()=>{
        setLoading(true);
        await api.get('publications/findDataDashboard').then(({data}) => setFindDataDashboard(data));
        setLoading(false);
      })()
    } catch(err) {
      console.error("ERROR: ", err);
      setLoading(false)
    }

    return () => {
      setFindDataDashboard({})
    };
    
  }, [])

  return (
      <div className="featured">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="featuredTitle publicationInfo">
                <h2>Informações das Publicações</h2>
                <div className="rowTitle">

                </div>
              </div>
            </div>
          </div>

        <div className="row">

          <div className="col-md-4 mb-2" data-tour="step-home-01" >
            <div className="featuredCard">
              <h3>Publicações Ativas</h3>
              <span className="colorStatus active"></span> 
              <h4 className="featuredCardNumber">{loading ? loadingSpinner : findDataDashboard.active}</h4>
              <span>As pessoas estão interagindo na última semana</span>
            </div>    
          </div>

          <div className="col-md-4 mb-2" data-tour="step-home-02" >
            <div className="featuredCard">
              <h3>Publicações Sem Interações</h3>
              <span className="colorStatus paused"></span> 
              <h4 className="featuredCardNumber">{loading ? loadingSpinner : findDataDashboard.inactive}</h4>
              <span>Sem muitas interações na última semana</span>
            </div>    
          </div>

          <div className="col-md-4 mb-2" data-tour="step-home-03" >
            <div className="featuredCard">
              <h3>Publicações Reativadas</h3>
              <span className="colorStatus noInteractions"></span> 
              <h4 className="featuredCardNumber">{loading ? loadingSpinner : findDataDashboard.reactivated}</h4>
              <span>Teve interação após um longo periodo sem.</span>
            </div>
          </div>    

        </div>
      </div>
    </div>
);
}

