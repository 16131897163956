import './mockupSocialNetwork.css';
import React, { memo, useEffect, useState } from "react";
import mockupFacebookFeed from '../../assets/images/mockup-facebook-feed.png';
import mockupFacebookStories from '../../assets/images/mockup-facebook-stories.png';
import mockupFacebookReels from '../../assets/images/mockup-facebook-reels.png';
import mockupInstagramFeed from '../../assets/images/mockup-instagram-feed.png';
import mockupInstagramStories from '../../assets/images/mockup-instagram-stories.png';
import mockupInstagramReels from '../../assets/images/mockup-instagram-reels.png';
import mockupWhatsapp from '../../assets/images/mockup-whatsapp.png';
import mockupTiktok from '../../assets/images/mockup-tiktok.png';
import mockupTwitter from '../../assets/images/mockup-twitter.png';

/**
 * 
 * @param {String} mockup: 
 * @param {String} thumbnail 
 * @param {String} key 
 * @param {String['large'|'small'|'medium']} size
 * @returns componet
 */
function MockupSocialNetwork({post}) {
  
  const [mockup, setMockup] = useState('');
  
  useEffect(() => {
    
    let { type, channel } = post;

    if (type === 'tiktok') {
      setMockup(mockupTiktok); 
      return;
    }
    if (type === 'facebook') {
        if (channel === 'reels') setMockup(mockupFacebookReels)
        if (channel === 'stories') setMockup(mockupFacebookStories)
        else setMockup(mockupFacebookFeed);
        return;
    }
    if (type === 'instagram') {
        if (channel === 'reels') setMockup(mockupInstagramReels)
        if (channel === 'stories') setMockup(mockupInstagramStories);
        else setMockup(mockupInstagramFeed);
        return;
    }
    if (type === 'whatsapp') {
        setMockup(mockupWhatsapp);  // dá ruim // pode manter por enquanto!
        return;
    }
  
  }, [post]);
 
  
  return (
    <div className='publicationPreview'>
      {mockup && mockup !== mockupFacebookFeed && mockup !== mockupInstagramFeed &&
        <>
          <img src={mockup} alt="mockup da rede social" className="img-fluid mockup" />
          <img src={post.thumbnail}  alt="imagem do banner" className={`thumbnail-large`}/>
        </>
      }
      {mockup && mockup === mockupInstagramFeed &&
        <>
          <img src={mockup} alt="mockup da rede social" className="img-fluid mockup" />
          <img src={post.thumbnail}  alt="imagem do banner" className={`thumbnail-small`}/>
        </>
      }
      {mockup && mockup === mockupFacebookFeed &&
        <>
          <img src={mockup} alt="mockup da rede social" className="img-fluid mockup" />
          <img src={post.thumbnail}  alt="imagem do banner" className={`thumbnail-medium`}/>  
        </>
      }
    </div> 
  )
}

export default memo(MockupSocialNetwork)
