import { useTour } from '@reactour/tour';
import { useEffect } from 'react';
import { getPrimaryTourGuiado, setPrimaryTourGuiado } from '../../GlobalValiable';

const steps = [
  {
    selector: '[data-tour="step-home-01"]',
    content: 'Publicações que fazem sucesso durante essa semana!',
  },
  {
    selector: '[data-tour="step-home-02"]',
    content: 'Publicações sem interação durante uma semana ou mais!',
  },
  {
    selector: '[data-tour="step-home-03"]',
    content: 'Publicações que foram reativadas após uma semana ou mais sem interação!',
  },
  {
    selector: '[data-tour="step-home-04"]',
    content: 'Visualize suas 3 melhores e mais interativas publicações do animes!',
  },
  {
    selector: '[data-tour="step-home-05"]',
    content: 'Veja as 3 publicações mais recentes criadas!',
  },
  {
    selector: '[data-tour="step-home-06"]',      
    content: 'Mineb.io é a nossa plataforma de criação de páginas com link de bio. Com o Animes + a Mineb.io, você poderá linkar suas publicações e transformá-las em link de bio em suas páginas de bio.',
  },
  {
    selector: '[data-tour="step-home-07"]',      
    content: 'Encurte seus links com nosso encurtador de links.',
  },
  {
    selector: '[data-tour="step-home-8"]',      
    content: 'Nosso Helper Center para te ajudar durante a jornada! Veja as perguntas frequentes e receba suporte.',
  },
  {
    selector: '[data-tour="step-home-9"]',      
    content: 'Visualize sua lista completa de publicações criadas. Vamos lá, Cique Aqui!',
  },
  // ... 
]

export const TourGuiadoHome = function({children}) {

    const { setSteps, setCurrentStep, setIsOpen } = useTour(false);

    useEffect(() => {
      setCurrentStep(0);
      setSteps(steps);

      const isPrimaryTourGuiado = getPrimaryTourGuiado();
      setIsOpen(isPrimaryTourGuiado);
      if(isPrimaryTourGuiado) setPrimaryTourGuiado(false);
      // eslint-disable-next-line
    }, []);  

    return (
     <> 
      {children}
     </>
    )
}