import Ajv from "ajv";
const ajv = new Ajv();

export const chatWhatsApp_schema = (jsonData) => {

    /** DEFAULT SCHEMA JSON */
    const schema = {
        type: "object",
        properties: {
          names: {
            type: "object",
            properties: {
              customer: {type: "string"},
              company: {type: "string"}
            },
            required: ["customer","company"],
            additionalProperties: false,
          },
          dialog: {
            type: "array",
            uniqueItems: true,
            prefixItems: [
              { from: {type: "string" } },
              { text: {type: "string"} }
            ],
            required: ["from"],
            additionalItems: true
          }
        },
        required: ["names","dialog"],
        additionalProperties: false,
    }

    const validate = ajv.compile(schema)
    const valid = validate(jsonData)
    if (!valid) console.log(validate.errors)
    
    return (!!valid);
}

export const carrouselFree_schema = (jsonData) => {

    /** DEFAULT SCHEMA JSON */
    const schema = {
      type: "object",
      properties: {
        
        config: {
          type: "object",
           properties: {
            transition: {
              type: "object",
              properties: {
                effect: { 
                  type: 'object',
                  properties: {
                    animation: { type: 'array' },
                    interval: { type: "number" }
                  },
                  required: ["animation","interval"],
               },
                visibleTime: { type: 'number' }
              },
              required: ["effect","visibleTime"],
            }
          },
          additionalProperties: false,
        },

        documentInfo: {
          type: "object",
          properties: {
            doc_width: {type: "number"},
            doc_height: {type: "number"}
          },
          required: ["doc_width","doc_height"],
          additionalProperties: false,
        },

        carrousel: {
          type: "array",
          additionalProperties: false,
        },

      },
      required: ["config","documentInfo","carrousel"],
      additionalProperties: false,
    }

    const validate = ajv.compile(schema)
    const valid = validate(jsonData)
    if (!valid) console.log(validate.errors)

    return (!!valid);
}