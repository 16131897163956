import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
    {
      selector: '[data-tour="step-social-media-01"]',
      content: 'Veja os formatos que foram gerados para o Facebook.',
    },
    {
        selector: '[data-tour="step-social-media-02"]',
        content: 'Veja os formatos que foram gerados para o Instagram',
    },
    {
        selector: '[data-tour="step-social-media-03"]',
        content: 'Veja o formato que foi gerado para o Tiktok.',
    },
    {
        selector: '[data-tour="step-social-media-04"]',
        content: 'Conecte e desconecte a rede social com facilidade.',
    },
    {
        selector: '[data-tour="step-social-media-05"]',
        content: 'Veja o banner,',
    },
    {
        selector: '[data-tour="step-social-media-06"]',
        content: 'e algumas informações sobre ele.',
    },
    {
        selector: '[data-tour="step-social-media-07"]',
        content: 'Escolha o(s) canal(is) que deseja publicar e envie.',
    },
    {
        selector: '[data-tour="step-social-media-08"]',
        content: 'Copie o código e incorpore o banner em sites.',
    },
    {
        selector: '[data-tour="step-social-media-09"]',
        content: 'Agende a publicação para a data que quiser.',
    },
    // .
    // ... 
  ]
  
  export const TourGuiadoSocialMedia = function({children}) {
  
      const { setSteps, setCurrentStep } = useTour(false);
  
      useEffect(() => {
        (async () => {
            setCurrentStep(0);
            setSteps(steps);
         })()
  
        // eslint-disable-next-line 
      }, []);  
  
      return (
       <> 
        {children}
       </>
      )
  }