import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
  [
    {
      selector: '[data-tour="step-createFree1-01"]',
      content: 'Faça o upload de seu arquivo PSD',
    },
    {
      selector: '[data-tour="step-createFree1-02"]',
      content: 'Vamos para a próxima etapa!',
    },
  ],
  [
    {
      selector: '[data-tour="step-createFree2-01"]',
      content: 'Informe um nome para sua publicação.',
    },
    {
      selector: '[data-tour="step-createFree2-02"]',
      content: 'Informe as Tags(palavras ou pequenas frases) para sua publicação.',
    },
    {
      selector: '[data-tour="step-createFree2-03"]',
      content: 'Esse campo é opcional. Aqui você pode adicionar o link de redirecionamento ao clicar sobre a publicação. Basta informar a URL, se não, deixer em branco.',
    },
    {
      selector: '[data-tour="step-createFree2-04"]',
      content: 'Vamos para a próxima etapa!',
    },
  ],
  [
    {
      selector: '[data-tour="step-createFree3-01"]',
      content: 'Escolha sua animação.',
    },
    {
      selector: '[data-tour="step-createFree3-02"]',
      content: 'Aguarde... Estamos gerando sua publicação.',
    },
    {
      selector: '[data-tour="step-createFree3-03"]',
      content: 'Esse campo é opcional. Podemos adicionar um audio junto ao seu banner!',
    },
    {
      selector: '[data-tour="step-createFree3-04"]',
      content: 'Aqui podemos alterar o tipo de resolução desejada na criação de sua publicação. O valor padrão é "Alto".',
    },
    {
      selector: '[data-tour="step-createFree3-05"]',
      content: 'Ao final clique em "Gerar Animação" e aguarde...',
    },
  ]
  // ... 
]

export const TourGuiadoCreateFree = function({stepCreate, children}) {

    const { setSteps, setCurrentStep } = useTour(false);

    useEffect(() => {
      (async () => {
          setCurrentStep(0); 
          setSteps(steps[stepCreate - 1]); 
       })()
      // eslint-disable-next-line 
    }, []);  

    useEffect(() => {
      setCurrentStep(0); 
      setSteps(steps[stepCreate - 1]); 
    // eslint-disable-next-line 
    }, [stepCreate])
    

    return (
     <> 
      {children}
     </>
    )
}