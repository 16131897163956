import React, { useState, useEffect } from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import api from '../../config/api';
import apiSocial from '../../config/api-social';
import { TourGuiadoSocialMedia } from '../../pages/socialMediaPublication/TourGuiadoSocialMedia';
import { Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import copy from '../../assets/images/copiar.svg';
import calendar from '../../assets/images/calendar.png';
import arrowCollapse from '../../assets/images/seta-collapse.svg';
import { addUpperCasePrimaryChar } from '../../helpers/addUpperCasePrimaryChar';
import logoTwitter from '../../assets/images/logo-twitter.png';
import twitterName from '../../assets/images/twitter-name.png';
import mockupTwitter from '../../assets/images/mockup-twitter.png';

export const TwitterSocialMedia = ({ CustomToggle, publication_id }) => {

  const [checkboxActiveTwitter, setCheckboxActiveTwitter] = useState(false);

  const toggleInputIncorporate = className => {
    const element = document.querySelector('.' + className);
    element?.classList.toggle('open');
  }

  const closeModalDisconnect = className => {
    setCheckboxActiveTwitter(true);
    const element = document.querySelector('.' + className);
    element?.classList.toggle('close');
  }

  const closeModal = className => {
    const element = document.querySelector('.' + className);
    element?.classList.toggle('close');
  }


  return(
    <TourGuiadoSocialMedia>
      <section hidden={false}>
              <div className="row">
                  <div className="col-md-12">
                      <Accordion defaultActiveKey="1" >
                          <Card>
                              <Card.Header>
                                  <div className="headerLeft">
                                      <img className="img-fluid logo-media" src={logoTwitter} alt="logotipo do twitter" />
                                      <img className="img-fluid logo-name logo-name-twitter" src={twitterName} alt="nome do twitter" />
                                  </div>
                                  <div className="headerRight">
                                  <input
                                      id="status-twitter" 
                                      type="checkbox" 
                                      className="toggle toggle-twitter" 
                                      onChange={() =>setCheckboxActiveTwitter(!checkboxActiveTwitter)} 
                                      defaultChecked={checkboxActiveTwitter}
                                      Checked={checkboxActiveTwitter} />

                                      <label htmlFor="status-twitter">
                                          <span className= {`${checkboxActiveTwitter ? "active" : "no-active"}`}>
                                              {checkboxActiveTwitter ? "Conectado" : "Desconectado"}
                                          </span>
                                      </label>
                                      <CustomToggle eventKey="0">
                                          <img src={arrowCollapse} alt="seta" />
                                      </CustomToggle>
                                  </div>
                              </Card.Header>
                              <Accordion.Collapse eventKey="0">
                                  <Card.Body>
                                      <div className="alertConnect">
                                          <span>Antes é necessário ativar a rede</span>
                                          <button className="btn-twitter">Conectar-se com twitter</button>
                                      </div>
                                      <div className="alertConnect modal-disconnect">
                                          <button className="btn-close" onClick={()=> closeModalDisconnect('modal-disconnect')}></button>
                                          <span>Deseja realmente desconectar-se da rede?</span>
                                          <button className="btn-disconnect">Desconectar</button>
                                      </div>
                                      <div className="row">
                                          <div className="displayBody">
                                              <div className="formatPreview">
                                                <div className="mockup-content">
                                                    <img src={mockupTwitter} alt="mockup rede social" />
                                                </div>
                                                  <div className="publicationText publicationTextSocialMedia">
                                                    <textarea name="" id="" placeholder="Insira o texto da sua mensagem."></textarea>
                                                  </div>
                                              </div>
                                              <div className="formatInformation">
                                                  <div className="formatInformationHeader">
                                                      <button className="btn-scheduling" data-tip="Clique para agendar publicações.">
                                                          < ReactTooltip className="customTooltip" />
                                                          <img className="img-fluid" src={calendar} alt="calendário" />
                                                      </button>
                                                      <button className="btn-incorporate" data-tip="Clique para incorporar em sites e blogues." onClick={() => toggleInputIncorporate('input-incorporate-whats')}>
                                                          < ReactTooltip className="customTooltip" />
                                                          INCORPORAR
                                                      </button>
                                                      <button className="btn-publish" data-tip="Clique para publicar todos os formatos gerados.">
                                                          < ReactTooltip className="customTooltip" />
                                                          PUBLICAR
                                                      </button>
                                                  </div>
                                                  <div className="incorporateCode">
                                                      <div className="displayInput input-incorporate-whats">
                                                          <input type="text" />
                                                          <button className="btn-copy">
                                                              <img src={copy} alt="ícone de página" />
                                                          </button>
                                                      </div>
                                                  </div>
                                                  <table>
                                                      <tbody>
                                                          <tr>
                                                              <td className="typeInformation">
                                                                  <span>Formato:</span>
                                                              </td>
                                                              <td className="contentInformation">
                                                                  <span>Feed Quadrado</span>
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td className="typeInformation">
                                                                  <span>Tamanho:</span>
                                                              </td>
                                                              <td className="contentInformation">
                                                                  <span>1200x800</span>
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td className="typeInformation">
                                                                  <span>Tipo:</span>
                                                              </td>
                                                              <td className="contentInformation">
                                                                  <span>Imagem</span>
                                                              </td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                      </div>
                                  </Card.Body>
                              </Accordion.Collapse>
                          </Card>
                      </Accordion>
                  </div>
              </div>
          </section>
    </TourGuiadoSocialMedia>
  )
}
