export const createPublicationFilter = async (layers) => {

    const layersFilter = [];
    for await (const group of layers) {
        const groupLayers = [];

        for await (const layer of group) {
            if (!!layer) {
                let font = '';
                let color = '';
                let alignment = '';
                let text = '';
                    
                if (layer.text !== "" && layer.font) {
    
                    const fontFamily = 'Montserrat';
                    const style = `${layer.text.font.styles[0]?layer.text.font.styles[0]:''}`;  
                    const weight = `${layer.text.font.weights[0]?layer.text.font.weights[0]:''}`;   
                    const fontSize = `${Math.round((layer.text.font.sizes[0] * layer.text.transform.yy) * 100) * 0.01}px`;  
                    // const fontFamily = `${layer.text.font.names[0]}, ${layer.text.font.names[0]}, ${layer.text.font.names[0]}`;
                
                    font = `${style} ${weight} ${fontSize} ${fontFamily}`;
                    color = `rgba(${layer.text.font.colors[0][0]}, ${layer.text.font.colors[0][1]}, ${layer.text.font.colors[0][2]}, ${layer.text.font.colors[0][3]})`; 
                    alignment = layer.text.font.alignment[0];
                    text = layer.text.value;
                }

                groupLayers.push({
                    name: layer.name,
                    height: layer.height,
                    width: layer.width,
                    opacity: Number(layer.opacity),
                    top: layer.top,
                    right: layer.right,
                    bottom: layer.bottom,
                    left: layer.left,
                    update: false, 
                    z_index: layer.z_index,
                    text: text,
                    font: font,
                    alignment: alignment,
                    top_layer: layer.top_layer || 'none',
                    color: color,
                    base64: layer.base64,
                    animation: "fandIn"
                });
            }
        }   

        layersFilter.push(groupLayers);

    }

    return layersFilter;   

}