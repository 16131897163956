//** Converter a primeira Letra Para Maiuscula Em Cada Palavra */
//**
// * @param {Texto} text 
// * @param {delimitador ou separador} delimiter
// * @returns 
//**/
export const addUpperCasePrimaryChar = (text = '', delimiter = '') => {
  const textArray = text.split(delimiter);
  let newText = '';
  if (delimiter === '') {
    let tx = `${text[0].toUpperCase()}${text.substring(1)}`;
    return tx;
  }
  else {
    for (let index = 0; index < textArray.length; index++) {
      const tx = textArray[index];
      newText += `${tx[0].toUpperCase()}${tx.substring(1)} `;
    }
    return newText;
  }
}

