import React, { useState, useRef} from 'react';
import "./calendar.css";
import FullCalendar from '@fullcalendar/react' ;
import { CalendarNew } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/pt-br';
import scrollgrid from '@fullcalendar/scrollgrid';



export default function Calendar() {

  const events =  [
    {
      id: 'a',
      title: 'Meu evento',
      start: '2022-11-25',
    },
    {
      id: 'b',
      title: 'Meu evento',
      start: '2022-11-29',
    }
  ];

  return (
    <div className="container">
      <div className="row">
                    <div className="col-md-12">
                        <div className="pageBookmark">
                            <p>Início / </p>
                            <span>Calendário</span>
                        </div>
                    </div>
                </div>
      <div className="row">
        <div className="col-md-12">
          <FullCalendar
            plugins={[ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, scrollgrid]}
            locale={esLocale}
            events={events}
            initialView="dayGridMonth"
            headerToolbar= {{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,dayGridDay,listWeek'
            }}
          />
        </div>
      </div>
    </div>
  )
}
