
export const toDataUpload = (file) => {
    return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.onerror = (err) => reject(err);
    });
}

export const toDataURL = async url => {
    return await fetch(url, {method:'GET'})
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
       try {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
       } catch (error) {
           console.error(`ERROR RESOLVER BLOB: ${error}`);
       }
    })).catch(error => console.error(`ERROR GET IMAGE URL in: ${error}`));
}