import './listSocialMedia.css';
import React, { useState, useEffect } from 'react';
import { memo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import options from '../../assets/images/tres-pontos-hor.svg';
import apiSocial from '../../config/api-social';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import MockupSocialNetwork from '../mockupSocialNetwork/MockupSocialNetwork';

function ModalPreviewSchedule({ post, scheduleFilterNetwork, setScheduleFilterNetwork }) {
  console.log("ModalPreviewSchedule");


  const spinner = (<Spinner animation="grow" size='sm' variant="secondary" />)

  const [currentPots, setCurrentPots] = useState({});

  const [loading, setLoading] = useState({
    publish: false,
    delete: false
  });

  const [showDeletePost, setShowDeletePost] = useState(false);
  const handleDeleteShow = () => setShowDeletePost(true);
  const handleDeleteClose = () => setShowDeletePost(false);

  const [showRepeatPublishPost, setShowPublishPost] = useState(false);
  const handleRepeatPublishShow = () => setShowPublishPost(true);
  const handleRepeatPublishClose = () => setShowPublishPost(false);

  useEffect(() => setCurrentPots(post), [post]);

  const closePreviewPublication = className => {
    const element = document.querySelector('.' + className);
    element?.classList.remove('open');
    element?.classList.toggle('close-preview');
  }

  const publishToTikTok =  () => {
     const data = {
      media_id: currentPots.media_id,
      media_url: currentPots.media_url
    };

    apiSocial.post('/api/tiktok/share_video', data)
      .then(({ data: response }) => {
        console.log(response);
        handlStatusMessage('success', 'Publicado com sucesso!');
      }).catch(e => {
        console.log(e);
        handlStatusMessage('error', 'Opps... Tente novamente mais tarde.');
      }).finally(() => {
        setLoading(prev => ({...prev, delete: false}));
      })

  }

  const publishToInstagram = () => {
    const data = {
      caption: currentPots.caption,
      media_id: currentPots.id,
      type: 'instagram',
      image_url: currentPots.channel === 'feed' ? currentPots.media_url : null,
      video_url: currentPots.channel === 'reels' ? currentPots.media_url : null,
      channel: currentPots.channel
    }

    apiSocial.post('/api/instagram/create-post', data)
      .then(({ data: response }) => {
        console.log(response); 
        handlStatusMessage('success', 'Publicado com sucesso!');   
      }).catch(e => {
        console.log(e);
        handlStatusMessage('error', 'Opps... Tente novamente mais tarde.');
      }).finally(() => {
        setLoading(prev => ({...prev, delete: false}));
      })

  }

  const handleRepeatPublication = () => {
    // Values de currentPots => id,identifier,media_id,type,channel,scheduleType,date_start,date_end,time,weekDaysRepeat,repeatEvery,frequenceRepeatEvery,media_url,caption,created_at,updated_at
    if (currentPots.type === 'tiktok') {
      publishToTikTok();
    }
    else if (currentPots.type === 'instagram') {
      publishToInstagram();
    }
  }

  const destroySchedule = () => {
    setLoading(prev => ({...prev, delete: true}));

    apiSocial.delete(`/api/scheduler/${currentPots.id}`).then(() => {
      setScheduleFilterNetwork(scheduleFilterNetwork.filter((postFilter) => postFilter.id !== post.id));
      handlStatusMessage('success', 'Agendamento deletado com sucesso!');
    }).catch(e => {
      console.log(e);
      handlStatusMessage('error', 'Opps... Tente novamente mais tarde.');
    }).finally(() => {
      setLoading(prev => ({...prev, delete: false}));
    })

  }

  const handlStatusMessage = (status, message) => {
    const statusMessage = document.querySelector('.message-status-modalPreviewSchedule');

    statusMessage.innerHTML = message;
    statusMessage.classList.add(`status-message-${status}`);

    setTimeout(() => {
      statusMessage.innerHTML = "";
      statusMessage.classList.remove(`status-message-${status}`);
      handleDeleteClose();
      handleRepeatPublishClose();
      closePreviewPublication('preview-publication');
      }, 2000);
    }

  return (
    <div className="listPublicationPreview preview-publication">
      <button class="btn-close" onClick={() => closePreviewPublication('preview-publication')}></button>
      <div clasName="img-fluid" data-tour="step-list-social-media-publication-22">
        <MockupSocialNetwork post={post} />
      </div>
      <div className="displayDropdown">
        <Dropdown show>
          <Dropdown.Toggle>
            <img src={options} alt="seta" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <DropdownItem data-tour="step-list-social-media-publication-23" onClick={handleRepeatPublishShow}>
              Publicar Agora
             </DropdownItem>

            {/* Modal de publicar novamente */}
            <Modal show={showRepeatPublishPost} onHide={handleRepeatPublishClose} backdrop="static" keyboard={false} >
              <Modal.Header closeButton>
                <Modal.Title>Publicar Agora</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Ao clicar em "Publicar Agora" seu video será publicado.
                <div className='message-status-modalPreviewSchedule'></div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleRepeatPublishClose}>
                  Cancelar
                </Button>
                <Button variant="primary" onClick={handleRepeatPublication}>
                  {loading.publish
                    ? <span className='span-publish-spinner'>Publicando {spinner} </span>
                    : <span className='span-publish-text'>Publicar Agora</span>
                  }
                </Button>
              </Modal.Footer>
            </Modal>

            <DropdownItem data-tour="step-list-social-media-publication-24">
              <Link key={`/schedulePublication/${currentPots.media_id}`} to={`/schedulePublication/${currentPots.media_id}`} className="link">
                Alterar agendamento
              </Link>
            </DropdownItem>

            <button className="btn-delete-post" onClick={handleDeleteShow} data-tour="step-list-social-media-publication-25">
              <DropdownItem>
                Excluir agendamento
              </DropdownItem>
            </button>

            {/* Modal de excluir postagem */}
            <Modal show={showDeletePost} onHide={handleDeleteClose} backdrop="static" keyboard={false} >
              <Modal.Header closeButton>
                <Modal.Title>Excluir agendamento?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                O agendamento será permanentemente excluído.
                <div className='message-status-modalPreviewSchedule'></div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDeleteClose}>
                  Cancelar
                </Button>
                <Button variant="primary" onClick={destroySchedule}>
                  {loading.delete
                    ? <span className='span-delete-spinner'>Excluindo {spinner} </span>
                    : <span className='span-delete-text'>Excluir Agendamento</span>
                  }
                </Button>
              </Modal.Footer>
            </Modal>

          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default memo(ModalPreviewSchedule);
