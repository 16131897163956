import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { TourProvider } from '@reactour/tour';
import { keyframes } from '@emotion/react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const radius = 10
const keyframesRotate = keyframes`
  50% {
    transform: translateY(-5px  );
  }
}`;
const backgroundBaseTourGuiado = '#4e4d4f';

const disableBody = target => disableBodyScroll(target)
const enableBody = target => enableBodyScroll(target)


ReactDOM.render(
  <TourProvider 
    steps={[]}
    afterOpen={disableBody} 
    beforeClose={enableBody} 
    styles={{
      popover: base => ({
        ...base,
        '--reactour-accent': '#5a07ad',
        borderRadius: radius,
      }),
      maskArea: base => ({ ...base, rx: radius }),
      maskWrapper: base => ({ ...base, color: backgroundBaseTourGuiado }),
      badge: base => ({ ...base, left: 'auto', right: '-0.8125em' }),
      controls: base => ({ ...base, marginTop: 50 }),
      close: base => ({ ...base, right: 'auto', left: 8, top: 8 }),
      dot: base => ({
        ...base,
        animationDuration: '1s',
        animationName: keyframesRotate,
        animationIterationCount: 'infinite',
        '&:nth-of-type(1)': {
          animationDelay: '.3s',
        },
        '&:nth-of-type(2)': {
          animationDelay: '.6s',
        },
      }),
    }}
  >
    <App />
  </TourProvider>,
  document.getElementById('root')
);

