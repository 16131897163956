import React, { useState } from "react";
import padlock from '../../assets/images/lock.svg';
import envelope from '../../assets/images/mail.svg'
import { Auth } from 'aws-amplify';
import { setStaticIsAuth } from '../../GlobalValiable';

export default function SignIn() {
    
    const [loading, setLoading] = useState(false);

    const [formState, setFormState] = useState({
        email: '',
        password: '',
        submittingSignIn: false,
        confirmed: false,

        recoverPassword: false,
        confirmRecoverPassword: false,
        newPassword: '',
        confirmNewPassword: '',
        codeValidate: '',
        confirmedRecover: false,
    });
    
    const onChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    }

    const handleSubmitSignIn = () => {
        let { submittingSignIn, email, password, recoverPassword } = formState;

        let currentEmail = email.trim();
        let currentPassword = password.trim();

        if(currentEmail === '' || currentPassword === '') {
            alertMessages('E-mail e senha obrigatório!', 'error');
            return;
        };

        if (!submittingSignIn && !recoverPassword) {
            setLoading(true);
            setFormState((prevState) => ({ ...prevState, submittingSignIn: true  }));
            
            Auth.signIn(currentEmail, currentPassword)
            .then(() => {
                setLoading(false);
                setFormState((prevState) => ({ ...prevState, submittingSignIn: false, confirmed: true }));
                setStaticIsAuth(true).then(() => window.location.href = '/')
                
            }).catch((err) => {
                setLoading(false);
                setFormState((prevState) => ({ ...prevState, submittingSignIn: false  }));
                if(err.name === "NotAuthorizedException") {
                    alertMessages('E-mail ou senha incorreto!', 'error');
                } else {
                    alertMessages('Opss... tente novamente mais tarde!!', 'error');
                }
            });
        }
    }

    const handleChangeLoginRecover = ()  => {
        setFormState((prevState) => ({ ...prevState, recoverPassword: !prevState.recoverPassword  }));
    }

    const alertMessages = (text, type) => { // @type => success || error
        let display = document.getElementById(`display-${type}`);
        display.style.display = 'block';
        let msg = document.getElementById(`msg-${type}`);
        msg.innerText = `${text}`;

        setTimeout(() => {
            display.style.display = 'none';
            msg.innerText = "";
        }, 5000);
    }


    const handleCallRecoverPassword  = async () => {
        const { recoverPassword, email } = formState;
        let emailToRecover = email.trim();
        
        if(!emailToRecover && recoverPassword) return;
        
        try {
            await Auth.forgotPassword(emailToRecover);
            setFormState((prevState) => ({ ...prevState, confirmRecoverPassword: true  }));
        } catch (err) {
            if(err.name === 'UserNotFoundException') {
                alertMessages('E-mail não encontrado!', 'error');
            } else {
                alertMessages('Opss... tente novamente mais tarde!!', 'error');
            }
        }

    }

    const handleecoverPasswordSubmit = async () => {
        const { 
            newPassword, 
            confirmNewPassword, 
            codeValidate,  
            recoverPassword, 
            confirmRecoverPassword, 
            email
        } = formState;

        let code = codeValidate.trim();
        let emailToRecover = email.trim();
        if(!recoverPassword || !confirmRecoverPassword || !code || !emailToRecover) return;

        let password = newPassword.trim();
        let Confirmpassword = confirmNewPassword.trim();
        if(password !== Confirmpassword) {
            alertMessages('Senhas não conferem!', 'error');
            return;
        };
        
        try {
            await Auth.forgotPasswordSubmit(emailToRecover, code, password);
            setFormState((prevState) => ({ ...prevState, confirmedRecover: true  }));            
            alertMessages('Sua senha foi redefinida com sucesso!', 'success');
        } catch (err) {
            setFormState((prevState) => ({ ...prevState, confirmedRecover: false  })); 
            if(err.name === "ExpiredCodeException" || err.name === "LimitExceededException") {
                alertMessages('Código incorreto ou limite expirado!', 'error');
            } else {
                alertMessages('Opss... tente novamente mais tarde!!', 'error');
            }

        }
    }

    return(<> 
        {formState.recoverPassword === false && (    
            <div className="formBody login">
                <div className="displayField">
                    <div className="imgInput">
                        <img src={envelope} alt="envelope" />
                    </div>
                    <input type="email" placeholder="Email" name="email" value={formState.email} onChange={onChange}/>
                </div>
                <div className="displayField">
                    <div className="imgInput">
                        <img src={padlock} alt="cadeado aberto" />
                    </div>
                    <input type="password" placeholder="Senha" name="password"  value={formState.password} onChange={onChange}/>
                </div>
                
                <div id="display-error"><p id="msg-error">  </p></div>

                <button onClick={handleSubmitSignIn} type="submit">                   
                    {loading 
                        ? ("Login...")                             
                        : ("Entrar")
                    }
                </button>
                <span onClick={handleChangeLoginRecover}>Esqueceu sua senha?</span>
            </div>
        )}

        {formState.recoverPassword === true && formState.confirmRecoverPassword === false && (  
            <div className="recoverPassword">

                <div className="formHeader mb-3">
                    <h1>Redefina sua senha</h1>
                </div>
                
                <div className="formBody verificationCode">
                    <div className="displayField">
                        <div className="imgInput">
                            <img src={envelope} alt="envelope" />
                        </div>
                        <input type="email" placeholder="Email" name="email" value={formState.email} onChange={onChange} />
                    </div>

                    <div id="display-error"><p id="msg-error">  </p></div>

                    <button onClick={handleCallRecoverPassword} type="submit">Enviar Código de Solicitação</button>
                    <span onClick={handleChangeLoginRecover} >Voltar</span>
                </div>
            </div>
        )}

        {formState.confirmRecoverPassword === true && formState.recoverPassword === true && (
            <div className="formBody recoverPassword">
                <div className="formHeader mb-3">
                    <h1>Nova senha</h1>
                </div>

                <div className="displayField">
                    <div className="imgInput">
                        <img src={envelope} alt="envelope" />
                    </div>
                    <input type="text" placeholder="Code" name="codeValidate" value={formState.codeValidate}  onChange={onChange}/>
                </div>
                <div className="displayField">
                    <div className="imgInput">
                        <img src={padlock} alt="cadeado aberto" />
                    </div>
                    <input type="password" placeholder="Nova Senha" name="newPassword" value={formState.newPassword} onChange={onChange} />
                </div>
                <div className="displayField">
                    <div className="imgInput">
                        <img src={padlock} alt="cadeado aberto" />
                    </div>
                    <input type="password" placeholder="Confirmar Nova Senha"  name='confirmNewPassword' value={formState.confirmNewPassword}  onChange={onChange}/>
                </div>
                
                <div id="display-success"><p id="msg-success">  </p></div>
                <div id="display-error"><p id="msg-error">  </p></div>

                <button onClick={handleecoverPasswordSubmit} type="submit">
                    {loading 
                        ? ("Carregando...")                             
                        : ("Redefinir senha")
                    }
                </button>
                <span onClick={handleChangeLoginRecover} >Voltar</span>

            </div>
        )}
    </>)
}       