import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import "./schedulePublication.css";
import { Link, useParams } from 'react-router-dom';
import { TourGuiadoSchedulePublication } from './TourGuiadoSchedulePublication';
import arrowBack from '../../assets/images/arrow_back.svg';
import arrow from '../../assets/images/seta-collapse.svg';
import calendarSummary from '../../assets/images/calendar-filled.png';
import calendarDate from '../../assets/images/calendar-void.png';
import watchSummary from '../../assets/images/watch-filled.png';
import watchHour from '../../assets/images/watch.png';
import logoFacebook from '../../assets/images/logo-facebook.png';
import facebookName from '../../assets/images/facebook-name.png';
import logoInstagram from '../../assets/images/logo-instagram.png';
import instagramName from '../../assets/images/instagram-name.png';
import logoWhatsapp from '../../assets/images/logo-whatsapp.png';
import whatsappName from '../../assets/images/whatsapp-name.png';
import logoTiktok from '../../assets/images/logo-tiktok.png';
import tiktokName from '../../assets/images/tiktok-name.svg';
import 'react-calendar/dist/Calendar.css';
import useLongPress from './useLongPress';
import api from '../../config/api';
import apiSocial from '../../config/api-social';
import MockupSocialNetwork from '../../components/mockupSocialNetwork/MockupSocialNetwork';
// import MockupSocialNetwork from '../../components/mockupSocialNetwork/MockupSocialNetwork';

export default function SchedulePublication() {

    const params = useParams();
    const dateCurrente = new Date();
    const month = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    const [hour, setHour] = useState(dateCurrente.getHours());
    const [media, setMedia] = useState({});
    const [minute, setMinute] = useState(dateCurrente.getMinutes());
    const [date, setDate] = useState([dateCurrente, dateCurrente]);
    const [weekDays, setWeekDays] = useState([
        { id: 0, name: 'D', active: 'weekday' },
        { id: 1, name: 'S', active: 'weekday' },
        { id: 2, name: 'T', active: 'weekday' },
        { id: 3, name: 'Q', active: 'weekday' },
        { id: 4, name: 'Q', active: 'weekday' },
        { id: 5, name: 'S', active: 'weekday' },
        { id: 6, name: 'S', active: 'weekday' },
    ]);
    const [networkCurrent, setNetworkCurrent] = useState({ logo: '', name: '' });
    const [occorrence, setOccorrence] = useState(1);
    const [frequenceRepeatEvery, setFrequenceRepeatEvery] = useState(1);
    const [scheduleType, setScheduleType] = useState('norepeat');
    const [typeNetwork, setTypeNetwork] = useState('');
    const [allowDescription, setAllowDescription] = useState(true);
    const [repeatEvery, setRepeatEvery] = useState('day')

    const [scheduleButtonSelected, setScheduleButtonSelected] = useState([]);
    const [publishedLocation, setPublishedLocation] = useState('');

    const scheduleButtons = [
        { id: 1, name: 'FEED' },
        { id: 2, name: 'STORIES' },
        { id: 3, name: 'REELS' },
    ];
    const [publication, setPublication] = useState({});

    const getDate = (dateChange) => {
        if (repeatEvery === 'week') {
            const WeekOld_objRepeat = weekDays.find((item) => item.id === (date[0].getDay()));
            handleRepeat(WeekOld_objRepeat);

            const objRepeat = weekDays.find((item) => item.id === (dateChange[0].getDay()));
            if (objRepeat.active === 'weekday') {
                handleRepeat(objRepeat);
            }
        }

        setDate(dateChange);
    };

    const defaultOptions = {
        shouldPreventDefault: false,
        delay: 50,
    };

    const handleIncrementMinutes = () => setMinute(minute => ((minute < 59 ? minute + 1 : minute)));
    const longPressEventIncrementMinutes = useLongPress(handleIncrementMinutes, () => { }, defaultOptions);

    const handleDecrementMinutes = () => setMinute(minute => ((minute > 0 ? minute - 1 : minute)));
    const longPressEventDecrementMinutes = useLongPress(handleDecrementMinutes, () => { }, defaultOptions);

    const handleIncrementHours = () => setHour(hour => ((hour < 23 ? hour + 1 : hour)));
    const longPressEventIncrementHours = useLongPress(handleIncrementHours, () => { }, defaultOptions);

    const handleDecrementHours = () => setHour(hour => ((hour > 0 ? hour - 1 : hour)));
    const longPressEventDecrementHours = useLongPress(handleDecrementHours, () => { }, defaultOptions);

    const statusMessage = (elem, message, type) => {
        // success or error or info
        elem.hidden = false; 
        elem.innerHTML = message;
        elem.classList.add(`message-schedule-${type}`);

        setTimeout(() => {
            elem.hidden = true;
            elem.innerHTML = '';
            elem.classList.remove(`message-schedule-${type}`);
        }, 4500);

    }

    const addZeroBefore = (n) => {
        return (n < 10 ? '0' : '') + n;
    }

    const defineNetWork = (post) => {
        let { format_social_network } = post;

        let channel = '', type = '';

        let social = format_social_network;

        if (social.includes("tiktok")) {
            setAllowDescription(false);
            setNetworkCurrent({ logo: logoTiktok, name: tiktokName });
            type = 'tiktok';
            channel = 'feed'; 
        }
        else if (social.includes("facebook")) {
            setNetworkCurrent({ logo: logoFacebook, name: facebookName });
            if (social.includes("reels")) channel = 'reels';
            else if (social.includes("stories")) channel = 'stories';
            else channel = 'feed';
            type = 'facebook';
        }

        else if (social.includes("instagram")) {
            setNetworkCurrent({ logo: logoInstagram, name: instagramName });
            if (social.includes("reels")) channel = 'reels';
            else if (social.includes("stories")) channel = 'stories';
            else channel = 'feed';
            type = 'instagram';
        }
        else if (social.includes("WHATSAPP")) {
            setNetworkCurrent({ logo: logoWhatsapp, name: whatsappName });
            type = 'whatsapp';
            channel = 'feed';
        }

        setTypeNetwork(type);
        setPublishedLocation(channel);
        setMedia({ ...post, channel, type });
    }

    const handleRepeat = (item, changeDateCurrent = true) => {
        if ((date[0].getDay()) === item.id && !changeDateCurrent) return;

        if (item.active === 'weekday') {
            item.active = 'weekday active'
            setWeekDays((prev) => ([...prev]));
        } else {
            item.active = 'weekday'
            setWeekDays((prev) => ([...prev]));
        }
    }

    useEffect(() => {
        if (!params.media_id) return;
        api.get(`/medias/one/${params.media_id}`)
            .then((res) => {
                
                api.get(`/publications/find/${res.data.publication_id}`).then(({data: pub}) => {
                    setPublication(pub);
                  }).catch((er) => {
                  console.error("Error publications: ", er)
                })

                const { publication_id, doc_height, doc_width } = res.data;
                let baseUrl_thumbnail = res.data.url_print.split(`/${publication_id}_${doc_width}x${doc_height}`)[0];
                const thumbnail = `${baseUrl_thumbnail}/thumbnail_${publication_id}.png`;
                defineNetWork({...res.data, thumbnail});
            }).catch(e => {
                console.error("Error find media: ", e);
            })

        return () => {
            setMedia({});
        }
    }, [params.media_id])

    const handleConfirmAppointment = () => {
        const time = date[0];

        time.setHours(hour);
        time.setMinutes(minute);
        time.setSeconds(0);
        time.setMilliseconds(0);

        time.setUTCSeconds(0);
        time.setUTCMilliseconds(0);

        function diffHoursTimeZones() {
            let diff_hour = Number(hour - time.getUTCHours());
            if (diff_hour >= 0) {
                let diff = diff_hour < 10 ? '0' + diff_hour : diff_hour;
                let currentHour = time.getUTCHours() + diff_hour;
                time.setUTCHours(currentHour);
                return String(diff);
            } else {
                let diff = diff_hour > -10 ? '0' + time.getTimezoneOffset() / 60 : diff_hour;
                let currentHour = time.getUTCHours() - (diff_hour * -1);
                time.setUTCHours(currentHour);
                return String('-' + diff);
            }
        }

        function diffMinutesTimeZones() {
            let diff_minutes = Number(minute - time.getUTCMinutes());
            let diff = diff_minutes > 0 ? diff_minutes : diff_minutes * -1;
            let currentMinutes = time.getUTCMinutes() + diff;
            time.setUTCMinutes(currentMinutes);
            return diff > 10 ? String(diff) : String('0' + diff);
        }

        let diff_hour = diffHoursTimeZones();
        let diff_minutes = diffMinutesTimeZones();
        let diff_time_zone = `${diff_hour}:${diff_minutes}`;

        const scheduleTime = time.toISOString().replace('Z', diff_time_zone);

        function verifyTypeMediaUrl() {

            if (typeNetwork === 'tiktok') {
                return media.url_print.replace('_playerVideo.html', '.mp4');
            }

            if (typeNetwork === 'instagram') {
                if (media.extension === 'mp4') {
                    return media.url_print.replace('_playerVideo.html', '.mp4');
                } else {
                    return media.thumbnail;
                }
            }

            if (typeNetwork === 'facebook') {
                if (media.extension === 'mp4') {
                    return media.url_print.replace('_playerVideo.html', '.mp4');
                } else {
                    return media.thumbnail;
                }
            }

            else {
                throw new Error("Network não encontrado.")
            }
        }

        const typeMediaUrl = verifyTypeMediaUrl();

        const isPersonalize = scheduleType === "custom" ? true : false;
        const weekDaysRepeat = isPersonalize ? weekDays.filter((item) => item.active === 'weekday active').map((item) => item.id) : [];
        const isRepeatEvery = scheduleType !== "norepeat" ? true : false;

        const scheduleData = {
            url: '', // url do print?
            caption: '', //
            channel: publishedLocation,
            media_id: params.media_id,
            type: typeNetwork,
            media_url: typeMediaUrl,
            scheduleType: scheduleType,
            date_start: date[0].toISOString(),
            date_end: date[1].toISOString(),
            time: scheduleTime,
            weekDaysRepeat: repeatEvery === 'week' ? weekDaysRepeat : null,
            repeatEvery: isRepeatEvery ? repeatEvery : null,
            frequenceRepeatEvery: isPersonalize ? frequenceRepeatEvery : null,
            thumbnail: media.thumbnail
        }

        let elem = document.querySelector(".message-schedule");

        apiSocial.post("/api/scheduler", scheduleData).then(() => {
            console.log("Agedado com sucesso.");
            statusMessage(elem, 'Agendado com sucesso.', 'success');
        }).catch((er) => {
            console.error(er);
            console.log("Eroor no agendamento: " + er);
            statusMessage(elem, 'Errado no agendamento.', 'error');
        })
        
    }

    const toggleChannelOptions = className => {
        const element = document.querySelector('.' + className);
        element?.classList.toggle('open');
    }

    const selectedChannel = (channel) => {
        const isExists = scheduleButtonSelected.find(item => { return item.id === channel.id });
        if (isExists) {
            const current = scheduleButtonSelected.filter(item => { return item.id !== channel.id });
            setScheduleButtonSelected(current);
        } else {
            setScheduleButtonSelected([...scheduleButtonSelected, channel]);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('.header-section');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    const handleCalcDateCustom = (frequence, repeat) => {
        let date_end = new Date();
        if (repeatEvery === 'day') {
            date_end.setDate(date[0].getDate() + frequence * (repeat - 1));
        }
        if (repeatEvery === 'week') { 
            date_end.setDate(date[0].getDate() + frequence * (repeat * 7 - 7));
        }
        if (repeatEvery === 'month') {
            date_end.setMonth(date[0].getMonth() + frequence * (repeat - 1));
        }

        setDate([date[0], date_end]);
    }

    const handleOccorrence = (e) => {
        let occorrence = e.target.value;
        setOccorrence(occorrence);
        handleCalcDateCustom(frequenceRepeatEvery, occorrence);
    }

    const handleRepeatEvery = (e) => {
        setRepeatEvery(e.target.value);
        if (e.target.value === 'week') {
            const objRepeat = weekDays.find((item) => item.id === (date[0].getDay()));
            handleRepeat(objRepeat);
        }
    }

    const handleFrequenceRepeatEvery = (e) => {
        let repeat = e.target.value;
        setFrequenceRepeatEvery(repeat);
        handleCalcDateCustom(repeat, occorrence);
    }

    return (
        <TourGuiadoSchedulePublication>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="pageBookmark">
                            <p>Minhas Publicações / Detalhes da Publicação / Formatos para Redes Sociais / Publicar nas Redes Sociais / </p>
                            <span>Agendar Publicação</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="topBackPage">
                            <Link key={`key-${media.publication_id}`} to={`/socialMediaPublication/${publication.type_publication}/${media.publication_id}`} className="link">
                                <button>
                                    <img src={arrowBack} alt="seta" />
                                    Voltar
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="titleMedia">
                            <h1>Agendar Publicação</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="header-section container">
                            <div className="row">
                                <div className="col no-margin">
                                    <div className="scheduleSummary" data-tour="step-schedule-publication-06">
                                        <h2>Agendamento</h2>
                                        <div className="informationDisplay">
                                            <div className="summaryInformation">
                                                <img src={calendarSummary} alt="calendário" className="icon" />
                                                <p>
                                                    {scheduleType === "repeat" && "Todos os dias de"}
                                                    {`
                                                ${`${addZeroBefore(date[0].getDate())}/${addZeroBefore(date[0].getMonth() + 1)}/${date[0].getFullYear().toString().substring(2, 4)}`}
                                                até
                                                ${`${addZeroBefore(date[1].getDate())}/${addZeroBefore(date[1].getMonth() + 1)}/${date[1].getFullYear().toString().substring(2, 4)}`}
                                                `}
                                                </p>
                                            </div>
                                            <div className="summaryInformation">
                                                <img src={watchSummary} alt="relógio" className="icon" />
                                                <p>{`${addZeroBefore(hour)}h:${addZeroBefore(minute)}min`}</p>
                                            </div>
                                            <div className="summaryInformation">
                                                <img src={networkCurrent.logo} width={50} alt="logo" className="icon" />
                                                {/* <p>{media.format_social_network}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="scheduleDisplay">
                            <div data-tour="step-schedule-publication-01">
                                <MockupSocialNetwork post={media} />
                            </div>
                            {allowDescription &&
                                <div className="publicationText">
                                    <textarea name="" id="" placeholder="Insira um texto para postagem."></textarea>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="scheduleDisplay">
                            <div className="calendar" data-tour="step-schedule-publication-02">
                                <div className="tagInformation">
                                    <img src={calendarDate} alt="calendário" />
                                    <div className="information">
                                        <p>Início: </p>
                                        <span>
                                            {`${addZeroBefore(date[0].getDate())} 
                                            ${month[date[0].getMonth()]}. 
                                            ${date[0].getFullYear()}`}
                                        </span>
                                    </div>
                                </div>
                                <div className="tagInformation">
                                    <img src={calendarDate} alt="calendário" />
                                    <div className="information">
                                        <p>Término: </p>
                                        <span>
                                            {`${addZeroBefore(date[1].getDate())} 
                                            ${(month[date[1].getMonth()])}. 
                                            ${date[1].getFullYear()}`}
                                        </span>
                                    </div>
                                </div>
                                <Calendar
                                    className="custom-calendar"
                                    onChange={getDate}
                                    value={date}
                                    selectRange={['custom', 'norepeat'].includes(scheduleType) ? false : true}
                                    returnValue={'range'}
                                    locale={'pt-BR'}
                                />
                                <p className='markDate mt-2'>Marque uma data de {['custom', 'norepeat'].includes(scheduleType) ? "inicio" : "inicial e outra final."}</p>
                            </div>
                            <div className="scheduleDisplay">
                                <div className="watch" data-tour="step-schedule-publication-03">
                                    <div className="tagInformation">
                                        <img src={watchHour} alt="relógio" />
                                        <span>{`${addZeroBefore(hour)}:${addZeroBefore(minute)}`}</span>
                                    </div>
                                    <div className="chooseTime">
                                        <div className="numberTime">
                                            <button {...longPressEventIncrementHours}>
                                                <img src={arrow} alt="seta" />
                                            </button>
                                            <input type="text" readOnly={true} value={addZeroBefore(hour)} />
                                            <button {...longPressEventDecrementHours}>
                                                <img src={arrow} alt="seta" className="arrowRotation" />
                                            </button>
                                        </div>
                                        <div>
                                            <span>:</span>
                                        </div>
                                        <div className="numberTime">
                                            <button {...longPressEventIncrementMinutes}>
                                                <img src={arrow} alt="seta" />
                                            </button>
                                            <input type="text" readOnly={true} value={addZeroBefore(minute)} />
                                            <button {...longPressEventDecrementMinutes}>
                                                <img src={arrow} alt="seta" className="arrowRotation" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="publicationRepeat" data-tour="step-schedule-publication-04">
                            <h2>Recorrência</h2>
                            <form action="">
                                <div className="repetitionOptions" >
                                    <div className="displayInput" onChange={(e) => setScheduleType(e.target.value)}>
                                        <input defaultChecked={true} type="radio" id="norepeat" name="recorrência" value="norepeat" />
                                        <label htmlFor="norepeat">Não se repete</label>
                                    </div>
                                    <div className="displayInput" onChange={(e) => {
                                        setScheduleType(e.target.value);
                                        setRepeatEvery('day');
                                    }}>
                                        <input type="radio" id="repeat" name="recorrência" value="repeat" />
                                        <label htmlFor="repeat">Todos os dias</label>
                                    </div>
                                    <div className="displayInput" onChange={(e) => {
                                        setScheduleType(e.target.value);
                                        setRepeatEvery('week');
                                    }}>
                                        <input type="radio" id="repeat" name="recorrência" value="repeat" />
                                        <label htmlFor="repeat">Semanalmente</label>
                                    </div>
                                    <div className="displayInput" onChange={(e) => {
                                        setScheduleType(e.target.value);
                                        setRepeatEvery('day');
                                    }}>
                                        <input type="radio" id="custom" name="recorrência" value="custom" />
                                        <label htmlFor="custom">Personalizar os dias</label>
                                    </div>
                                </div>

                                {scheduleType === "custom" && <>
                                    <div>
                                        <h3>Repetir a cada:</h3>
                                        <div>
                                            <input
                                                onChange={(e) => handleFrequenceRepeatEvery(e)}
                                                value={frequenceRepeatEvery}
                                                type="number"
                                                min="1"
                                                max="99"
                                            />
                                            <select name="" id="" defaultValue={"day"} onChange={(e) => handleRepeatEvery(e)}>
                                                <option value="day">dia(s)</option>
                                                <option value="week">semana(s)</option>
                                                <option value="month">mês(es)</option>
                                            </select>
                                        </div>
                                    </div>

                                    {repeatEvery === 'week' &&
                                        <div>
                                            <h3>Repetir:</h3>
                                            <div className="displayWeekday">
                                                {weekDays.map((item, index) => (
                                                    <div
                                                        className={item.active}
                                                        key={index}
                                                        onClick={() => handleRepeat(item, false)}>
                                                        {item.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }

                                    <div className="endRepetition">
                                        <h3>Terminar após:</h3>
                                        <div className="displayInput inputOccurences">
                                            <div className="displayInput">
                                                <input
                                                    type="number"
                                                    min="1"
                                                    onChange={(e) => handleOccorrence(e)}
                                                    value={occorrence}
                                                />
                                                <span>ocorrência(s)</span>
                                            </div>
                                        </div>

                                    </div>
                                </>}
                            </form>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-12">
                        <div className='message-schedule'></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="scheduleButtons">
                            <div className="display">
                                <button
                                    style={{
                                        width: '220px',
                                        height: '60px',
                                        borderRadius: '10px',
                                        padding: '0 4px',
                                        backgroundColor: '#480CA8'
                                    }}
                                    className="btn-confirm-appointment"
                                    onClick={() => handleConfirmAppointment()}
                                    data-tour="step-schedule-publication-05"
                                >
                                    Agendar
                                </button>
                            </div>
                            {/* Esse código ainda precisa de manutenção */}
                            {false &&
                                <>
                                    <div className="display">
                                        <button className="btn-confirm-appointment" onClick={() => handleConfirmAppointment()}>
                                            Agendar para Stories / Reels
                                        </button>
                                        <button className="btn-channel-options" onClick={() => toggleChannelOptions('channelOptions')}>
                                            <img src={arrow} alt="seta" />
                                        </button>
                                    </div>
                                    <div className="displayChannelOptions channelOptions">
                                        {scheduleButtons.map((item, index) => (
                                            <button
                                                className={scheduleButtonSelected.find(button => { return button.id === item.id }) ? 'active' : ''}
                                                key={index}
                                                onClick={() => selectedChannel(item)}>
                                                {item.name}
                                            </button>
                                        ))}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </TourGuiadoSchedulePublication>
        
    )


}