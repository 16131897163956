import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
  {
    selector: '[data-tour="step-listPublication-01"]',
    content: 'Abaixo irá listar suas publicações.',
  },
  {
    selector: '[data-tour2="step-listPublication-02"]',
    content: 'Podemos clicar aqui para criar nossa publicação!!',
  },
  // ... 
]

export const TourGuiadoList = function({children}) {

    const { setSteps, setCurrentStep } = useTour(false);

    useEffect(() => {
      (async () => {
          setCurrentStep(0);
          setSteps(steps);
       })()

      // eslint-disable-next-line 
    }, []);  

    return (
     <> 
      {children}
     </>
    )
}