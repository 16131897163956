import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
  [
    {
      
    }
  ],
  [
    
  ]
  // ... 
]
export const TourGuiadoCreateExternalVideo = function({stepCreate, children}) {

  const { setSteps, setCurrentStep } = useTour(false);

  useEffect(() => {
    (async () => {
        setCurrentStep(0); 
        setSteps(steps[stepCreate - 1]); 
     })()
    // eslint-disable-next-line 
  }, []);  

  useEffect(() => {
    setCurrentStep(0); 
    setSteps(steps[stepCreate - 1]); 
  // eslint-disable-next-line 
  }, [stepCreate])
  

  return (
   <> 
    {children}
   </>
  )
}