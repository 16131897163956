import './animesFaq.css';
import React, { useMemo, useState } from 'react';
import Faq from '../../components/Faq/Faq';
import dataFaqs from './faqs.json';
import Search from '../../components/search/Search';
import { TourGuiadoFaq } from './TourGuiadoFaq';

export default function AnimesFaq () {
  
  // eslint-disable-next-line
  const [faqs, setfaqs] = useState(dataFaqs);
  const [textFilter, setTextFilter] = useState('');
  
  const filterFaqs = useMemo(() => {
    const auxFaqs = [...dataFaqs]
    if(textFilter) {
      return auxFaqs.filter((faq) => {
        const lowerFaqs = faq.question.toLowerCase();
        return lowerFaqs.includes(textFilter.toLowerCase())
      } );
    } else {
      return dataFaqs;
    }
   
  },[textFilter]);

  const toggleFAQ = index => {
    setfaqs(filterFaqs.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open;
      } else {
        faq.open = false; 
      }

      return faq;
    }))
  }


  return (
    <TourGuiadoFaq>
      <div className="product">
        <div className="container">
          <div className="row">

              <div className="col-md-12">
                <Search value={textFilter} onChange={(e) => setTextFilter(e.target.value)} placeholder="Pesquise o que você procurar." />
              </div>

          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="pageBookmark">
                <p>Início/ Central de Ajuda/ </p>
                <span>FAQ</span>
              </div>  
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="commonQuestions" data-tour="step-faq-01">
                <h1>Perguntas Frequentes</h1>
                <div className="commonQuestionsContent">
                  <div className="helperCenterType">
                    <span data-tour="step-faq-02">Criativo</span>
                  </div>
                  <div className="faqs">
                    {filterFaqs.map((faq, i) => (
                      <div key={i}>
                        <Faq faq={faq} index={i} toggleFAQ={toggleFAQ} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TourGuiadoFaq>
  );
}


