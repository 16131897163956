import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../config/api';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import './detailsPublication.css';
import RenderPreviewHTML from '../../components/renderPreviewHTML/RenderPreviewHTML';
import Form from 'react-bootstrap/Form';
import ReactTooltip from 'react-tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toDataUpload } from "../../helpers/convertImgToBase64";
import update from '../../assets/images/autorenew.png';
import arrowDropDown from '../../assets/images/arrow_drop_down.svg';
import showMore from '../../assets/images/tres-pontos-hor.svg';
import help from '../../assets/images/help-grey.svg';
import help2 from '../../assets/images/help.svg';
import edit from '../../assets/images/editar.svg';
import copy from '../../assets/images/copiar.svg';
import audio from '../../assets/images/music_note.svg';
import RenderPreviewVideo from '../../components/renderPreviewVideo/RenderPreviewVideo';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { Button, Spinner } from 'react-bootstrap';
import { TourGuiadoDetails } from './TourGuiadoDetails';
import { downloadFiles } from '../../helpers/downloadFiles';

import TableChannelsResize from '../../components/tableChannelsResize/TableChannelsResize';
import ModalDestroyPublication from '../../components/modalDestroyPublication/ModalDestroyPublication';

const baseURL_shortened = process.env.REACT_APP_CLIQES_URL;

export default function DetailsPublication() {

    const { user } = useAuthenticator((context) => [context.user]);

    const params = useParams();
    const [loading, setLoading] = useState({
        video: false,
        audio: false,
        nameAndLabel: false,
        shortClick: false,
        minebio: false,
        global: false
    });

    const [publication, setPublication] = useState({ document: {}, layers: [] });
    const [formUpdate, setFormUpdate] = useState({});
    const [short_hash, setShort_hash] = useState({ hash_click: '', hash_print: '', hash_video: '' });
    const [viewTemplate, setViewTemplate] = useState('');
    const [shortened_count, setShortened_count] = useState({ clicks: 0, prints: 0 });
    const [video, setvideo] = useState('');
    const videoRef = useRef(null);
    const [hidden_warning_minebio, setHidden_warning_minebio] = useState('');
    const [optionsPagesMinebIO, setOptionsPagesMinebIO] = useState([]);
    const [showChannels, setShowChannels] = useState(false);
    const [showDestroy, setShowDestroy] = useState(false)
    const [bannerActive, setBannerActive] = useState(true);
    const [typePreview, setTypePreview] = useState('html');
    const [audioFile, setAudioFile] = useState({});
    const [audioSelected, setAudioSelected] = useState(false);
    const [audioBase64, setAudioBase64] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isBannerFree, setIsBannerFree] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const handleCallMsgMinebio = (text) => {
        setHidden_warning_minebio(text);
        setTimeout(() => {
            setHidden_warning_minebio('')
            handleCloseModal();
        }, 8000);
    }

    const btnLoadingGrow = (<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />);
    const btnLoadingBorder = (<Spinner as="span" animation="border" role="status" aria-hidden="true" />);


    const handleClickCopy = (text) => navigator.clipboard.writeText(text);

    useEffect(() => {
        async function effectInitial() {
            setLoading((prevState) => ({ ...prevState, global: true }));
            await api.get(`/publications/one/${user.attributes.sub}/${params.publication_id}`)
                .then(async response => {
                    const { documentInfo, layerInfo } = response.data;

                    setPublication({
                        document: documentInfo,
                        layers: layerInfo
                    });

                    if (documentInfo.url_audio) {
                        setAudioFile(documentInfo.url_audio);
                        setAudioSelected(true)
                    };
                    if (documentInfo.url_video) setvideo(documentInfo.url_video);

                    getDataShortener({ hash_click: documentInfo.shortened_hash, hash_print: documentInfo.shortened_print_hash });

                    setShort_hash({
                        hash_click: documentInfo.shortened_hash,
                        hash_print: documentInfo.shortened_print_hash,
                        hash_video: documentInfo.shortened_video_hash
                    });

                    setFormUpdate({
                        name: `${documentInfo.name}`,
                        label: `${documentInfo.label}`,
                        url_click_long: documentInfo.url_click
                    })

                    if (documentInfo.type_publication !== 'video-free') {
                        api.post(`publications/render/${documentInfo.effect}`, { documentInfo, layerInfo, type: 'update' })
                            .then(template => setViewTemplate(template.data))
                            .catch(err => console.log(err))
                    }

                    if (!(["Data", 'data']).includes(documentInfo.effect)) setIsBannerFree(true);

                });

            setLoading((prevState) => ({ ...prevState, global: false }));
        }
        effectInitial();

        return () => {
            setLoading((prevState) => ({ ...prevState, global: false }));
            setPublication({ document: {}, layers: [] });
            setShort_hash({ hash_click: '', hash_print: '', hash_video: '' });
            setShortened_count({ clicks: 0, prints: 0 });
            setFormUpdate({});
            setViewTemplate('');
        }
        // eslint-disable-next-line
    }, [user, params]);

    const getDataShortener = async ({ hash_click, hash_print }) => {

        let clicks = 0, prints = 0;

        await api.get(`/publications/getShortenerDetails/${hash_print}`)
            .then(short => prints = short.data.clicks)
            .catch(err => console.error("ERROR: ", err));

        if (hash_click) {
            await api.get(`/publications/getShortenerDetails/${hash_click}`)
                .then(short => clicks = short.data.clicks)
                .catch(err => console.error("ERROR: ", err));

        }

        setShortened_count({ clicks, prints });

    }

    const handlerChangeDetails = (event) => {
        let { name, value } = event.target;
        setFormUpdate({ ...formUpdate, [name]: value });
    }

    const handlStatusMessage = (elem, status, message) => {
        const statusMessage = elem;

        statusMessage.innerHTML = message;
        statusMessage.classList.add(`status-message-${status}`);

        setTimeout(() => {
            statusMessage.innerHTML = "";
            statusMessage.classList.remove(`status-message-${status}`);
        }, 4000);
    }

    const handleNameAndLabels = async () => {
        setLoading((prevState) => ({ ...prevState, nameAndLabel: true }));

        if (formUpdate.name.trim() === '' || formUpdate.label.trim() === '') {
            alert("OPS... Campos name e labels são obrigatórios");
            setLoading((prevState) => ({ ...prevState, nameAndLabel: false }));
            return;
        }

        if (formUpdate.name === publication.document.name &&
            formUpdate.label === publication.document.label
        ) {
            alert("OPS... Campos name e labels são iguais");
            setLoading((prevState) => ({ ...prevState, nameAndLabel: false }));
            return;
        }

        const data_update = {
            name: formUpdate.name.trim(),
            label: formUpdate.label.trim()
        }
        const { id: publication_id } = publication.document;

        let elem = document.querySelector('.message-handleChangeNameAndLabels');

        await api.patch(`/publications/edit/nameAndLabels/${user.attributes.sub}/${publication_id}`, data_update)
            .then(() => {
                setLoading((prevState) => ({ ...prevState, nameAndLabel: false }));
                handlStatusMessage(elem, 'success', 'Nome e Label atualizado com sucesso.');
            })
            .catch(function (error) {
                console.error("ERROR: ", error);
                setLoading((prevState) => ({ ...prevState, nameAndLabel: false }));
                handlStatusMessage(elem, 'error', 'Opss.. tente novamente mais tarde.');
            });
    }

    const handlerClick = async () => {
        setLoading((prevState) => ({ ...prevState, shortClick: true }));

        if (formUpdate.url_click_long.trim() === "" ||
            formUpdate.url_click_long === publication.document.url_click
        ) {
            setLoading((prevState) => ({ ...prevState, shortClick: false }));
            return;
        }

        const data_update = { url_click_long: formUpdate.url_click_long }
        const { id: publication_id } = publication.document;

        let elem = document.querySelector('.message-clik');

        await api.patch(`/publications/edit/linkClick/${user.attributes.sub}/${publication_id}`, data_update)
            .then(response => {
                setShort_hash({ ...short_hash, hash_click: response.data.hash });
                setLoading((prevState) => ({ ...prevState, shortClick: false }));
                handlStatusMessage(elem, 'success', 'Clik atualizado com sucesso.');
            })
            .catch(function (error) {
                console.error("ERROR: ", error);
                setLoading((prevState) => ({ ...prevState, shortClick: false }));
                handlStatusMessage(elem, 'error', 'Opss.. tente novamente mais tarde.');
            });
    }

    const selectPreviewType = () => {
        if (typePreview === 'html') {
            setTypePreview('video')
        } else {
            setTypePreview('html')
        }
    }

    const generateMp4 = async () => {

        let timeAnimation = publication.document.time_animation || Number(document.querySelector(".document").id.split("_")[1]);

        if (timeAnimation) {
            setvideo('');
            setLoading((prevState) => ({ ...prevState, video: true }));

            let elem = document.querySelector('.message-generationVideo');

            await api.post(`/publications/convertMp4`, {
                publication_id: params.publication_id,
                duration: (timeAnimation),
                preview: false,
            }).then((response) => {
                handlStatusMessage(elem, 'success', 'Video criado com sucesso.');
                setShort_hash({ ...short_hash, hash_video: response.data.hash_video });
                setvideo(response.data.url_long);
                setLoading((prevState) => ({ ...prevState, video: false }));

                window.scrollTo({ behavior: 'smooth', top: videoRef.current.offsetTop });

            }).catch(() => {
                setLoading((prevState) => ({ ...prevState, video: false }));
                handlStatusMessage(elem, 'error', 'Opss.. tente novamente mais tarde.');
            })
        } else {
            console.info("Tente novamento ao finalizar a animação do preview");
        }
    }

    const handlerUploadAudio = async (event) => {
        event.preventDefault();
        if (!audioSelected || !audioFile) return;
        setLoading((prevState) => ({ ...prevState, audio: true }));

        const formdate = new FormData();
        formdate.append('file', audioFile)

        let elem = document.querySelector('.message-audio');
        try {
            await api.patch(`/publications/edit/audio/${user.attributes.sub}/${publication.document.id}`, formdate)
            handlStatusMessage(elem, 'success', 'Áudio atualizado com sucesso.')
            setLoading((prevState) => ({ ...prevState, audio: false }));
        } catch (error) {
            console.error("ERROR: ", error);
            setLoading((prevState) => ({ ...prevState, audio: false }));
            handlStatusMessage(elem, 'error', 'Opss.. tente novamente mais tarde.');
        }

    }

    const handlerChangeAudio = async (event) => {
        if (!(!!event.target.files[0])) return;
        const type = event.target.files[0].name.split('.').pop();
        if (!['mp3'].includes(type)) return;

        const audio = event.target.files[0];
        const audioBase64 = await toDataUpload(audio);
        setAudioFile(audio)
        setAudioBase64(audioBase64)
        setAudioSelected(true);
    };

    const handleLinkarMinebio = async () => {
        handleShowModal();

        let isAuthMinebio = false;

        if (user.attributes['custom:minebio_access'] && user.attributes['custom:minebio_access'] === '1') {
            isAuthMinebio = true;
        } else {
            const userInfo = await Auth.currentUserInfo();
            const authMinebio = userInfo.attributes['custom:minebio_access'];
            if (authMinebio && authMinebio === '1') isAuthMinebio = true;
        }

        if (isAuthMinebio) {
            setLoading((prevState) => ({ ...prevState, minebio: true }));

            await axios.get(`${process.env.REACT_APP_MINEBIO_API_URL}/pages/user/${user.attributes.sub}`)
                .then(({ data }) => {
                    if (data.length) {
                        setOptionsPagesMinebIO(data);
                    } else {
                        const elem = (<label> *Não foi encontrada nenhuma page em sua conta da Mineb.io!</label>);
                        handleCallMsgMinebio(elem);
                    }
                    setLoading((prevState) => ({ ...prevState, minebio: false }));
                })
                .catch((err) => {
                    console.error("ERROR: ", err)
                    setLoading((prevState) => ({ ...prevState, minebio: false }));
                    const elem = (<label> *Error ao encontrar as pages de bio!</label>);
                    handleCallMsgMinebio(elem);
                });

        } else {
            const elem = (<label>
                *Você não possui uma conta na mineb.io,
                <a style={{ cursor: 'pointer', textDecoration: 'none' }}
                    href="https://app.mineb.io"
                    target="_blank"
                    rel="noopener noreferrer"
                > CLIQUE AQUI PARA CRIAR SUA CONTA!
                </a>
            </label>)
            handleCallMsgMinebio(elem);
            return;
        }


    }

    const handlePublicationMinebIO = async () => {
        const id_page_minebio = document.getElementById('selectPagesMinebio').value;
        if (!id_page_minebio) return;
        setLoading((prevState) => ({ ...prevState, minebio: true }));

        const pageUpdate = optionsPagesMinebIO.find(page => page.id === id_page_minebio);

        if (pageUpdate.links.findIndex(link => link.label === publication.document.name) !== -1) {
            setLoading((prevState) => ({ ...prevState, minebio: false }));
            return;
        } else {
            pageUpdate.links.push({
                label: publication.document.name,
                url: `${process.env.REACT_APP_CLIQES_URL}/${publication.document.shortened_print_hash}`,
                icon: publication.document.thumbnail
            });
        }

        await axios.put(`${process.env.REACT_APP_MINEBIO_API_URL}/pages/${pageUpdate.username}`, pageUpdate)
            .then(() => {
                setLoading((prevState) => ({ ...prevState, minebio: false }));
                setOptionsPagesMinebIO([]);
                handleCallMsgMinebio((<label>Sucesso, enviado para sua conta da mineb.io</label>));
            })
            .catch(err => {
                setLoading((prevState) => ({ ...prevState, minebio: false }));
                setOptionsPagesMinebIO([]);
                console.error("Error: ", err);
                handleCallMsgMinebio((<label>Erro ao enviar</label>));
            });
    }

    const handledownloadFiles = async (url, type) => {
        let time_download = '';
        if (type === 'html') time_download = 'downloadHtml';
        else time_download = 'downloadVideo';

        let field = document.getElementById(`${time_download}`);
        let valueOld = field.innerText;

        field.innerText = "Realizando download...";

        await downloadFiles(url, type);

        field.innerText = `${valueOld}`;
    }

    const handleRemoveAudio = () => {
        setAudioBase64('')
        setAudioSelected(false);
    }

    return (
        <TourGuiadoDetails>
            <div className="product">
                <div className="container">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="pageBookmark">
                                <p>Início / Minhas Publicações /</p>
                                <span>Detalhes da Publicação</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="detailsTop">
                                <div className="detailsTopLeft">
                                    <div className="statusPublication">
                                        <h1>Detalhes</h1>
                                        <input id="status" type="checkbox" className="toggle" onChange={() => setBannerActive(!bannerActive)} checked={bannerActive} />
                                        <label htmlFor="status">
                                            <span className={bannerActive ? "active" : "no-active"}>
                                                {bannerActive ? "Ativo" : "Pausado"}
                                            </span>
                                        </label>
                                    </div>
                                    <Dropdown>
                                        <Dropdown.Toggle drop="down">
                                            <img src={showMore} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleLinkarMinebio} >Adicionar na Mineb.io</Dropdown.Item>
                                            {isBannerFree &&
                                                <Dropdown.Item onClick={() => setShowChannels(true)} >Canais da Publicação</Dropdown.Item>
                                            }
                                            <Dropdown.Item onClick={() => setShowDestroy(true)} >Excluir Publicação</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading.global && (
                        <div className='loadingGlobalDetails'>
                            {btnLoadingBorder}
                        </div>
                    )}

                    <div className="row">

                        <div className="col-md-6">

                            <div className="section">
                                <div className="productField">

                                    <div className="fieldDetails" data-tour="step-details-01">
                                        <label>Nome da Publicação</label>
                                        <input
                                            onChange={handlerChangeDetails}
                                            id="details-publication-input"
                                            value={formUpdate.name || ''}
                                            name="name"
                                            type="text"
                                        />
                                    </div>
                                    <div className="fieldDetails" data-tour="step-details-02">
                                        <label>Tags</label>
                                        <input
                                            onChange={handlerChangeDetails}
                                            id="details-publication-input"
                                            className='input-details'
                                            value={formUpdate.label || ''}
                                            name="label"
                                            type="text"
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="btnSaveEdit">
                                                <button onClick={handleNameAndLabels} data-tour="step-details-03">
                                                    {loading.nameAndLabel ?
                                                        <span>{btnLoadingGrow} Salvando...</span>
                                                        : <span>Salvar Edição</span>
                                                    }
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className='message-handleChangeNameAndLabels'></div>

                            </div>

                            <div className="section">
                                <div className="productField">
                                    <div className="fieldDetails" data-tour="step-details-04">
                                        <label>Tipo de Animação</label>
                                        <input type="text" name="effect" value={publication.document.effect || 'none'} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="section">
                                <div className="productField">

                                    {publication.document.type_publication !== 'video-free' &&
                                        <div className="fieldDetails" data-tour="step-details-11">
                                            <label className="labelDisplay">
                                                Print
                                                <img data-tip="URL da publicação" src={help} alt="" />
                                                < ReactTooltip className="customTooltip" />
                                            </label>
                                            <div className="displayInput copy">
                                                <input type="text" name="link-click" value={short_hash.hash_print ? `${baseURL_shortened}/${short_hash.hash_print}` : publication.url_print || ''} disabled />
                                                {short_hash.hash_print &&
                                                    <div onClick={() => handleClickCopy(`${baseURL_shortened}/${short_hash.hash_print}`)}>
                                                        <img src={copy} alt='img-icon-copy' />
                                                        <span>Copiar</span>
                                                    </div>
                                                }
                                            </div>


                                            <div className="downloadFiles" onClick={() => handledownloadFiles(publication.document.url_print, 'html')}>
                                                <CloudDownloadIcon fontSize="small" />
                                                <span id='downloadHtml'> Download do html</span>
                                            </div>


                                        </div>
                                    }

                                    {short_hash.hash_video &&
                                        <div className="fieldDetails" data-tour="step-details-13">
                                            <label className="labelDisplay">
                                                Vídeo
                                                <img data-tip="URL da publicação em MP4" src={help} alt="" />

                                            </label>

                                            <div className="displayInput copy">
                                                <input type="text" name="link-click" value={short_hash.hash_video ? `${baseURL_shortened}/${short_hash.hash_video}` : publication.url_video || ''} disabled />
                                                <div onClick={() => handleClickCopy(`${baseURL_shortened}/${short_hash.hash_video}`)}>
                                                    <img src={copy} alt='img-icon-copy' />
                                                    <span>Copiar</span>
                                                </div>
                                            </div>


                                            <div className="downloadFiles" onClick={() => handledownloadFiles((video.replace('_playerVideo.html', '.mp4')), 'video')}>
                                                <CloudDownloadIcon fontSize="small" />
                                                <span id='downloadVideo'> Download do video</span>
                                            </div>

                                        </div>
                                    }

                                    <div className="displayButtonsDetails">
                                        {publication.document.id && publication.document.type_publication !== 'video-free' &&
                                            <div className="btnGenerateVideo">
                                                <button onClick={generateMp4} data-tour="step-details-12" >
                                                    {loading.video ?
                                                        <span>{btnLoadingGrow} Criando seu video...</span>
                                                        : <span>Gerar Banner em Vídeo</span>
                                                    }
                                                </button>
                                            </div>
                                        }
                                        {isBannerFree &&
                                            <div className="btnGenerateFormats">
                                                <Link to={`/details/formatsPublication/${publication.document.type_publication}/${publication.document.id}`}>
                                                    <button className="btnGenerateFormats">
                                                        Gerar Banner para Redes Sociais
                                                    </button>
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='message-generationVideo'></div>
                            </div>
                        </div>


                        <div className="col-md-6">
                            <div className="cardNumbers">

                                <div className="card" data-tour="step-details-05">
                                    <div className="labelDisplay">
                                        <h2>Cliques</h2>
                                        <img data-tip="Quantidade de cliques na publicação" src={help2} alt="" />

                                    </div>
                                    <span>{shortened_count.clicks || 0}</span>
                                </div>

                                <div className="card" data-tour="step-details-06">
                                    <div className="labelDisplay">
                                        <h2>Impressões</h2>
                                        <img data-tip="Quantidade de visualizações da publicação" src={help2} alt="" />

                                    </div>
                                    <span> {shortened_count.prints || 0}</span>
                                </div>

                            </div>

                            <div className="section" data-tour="step-details-07">
                                <div className="productField" >
                                    <div className="fieldDetails" data-tour="step-details-10">
                                        <label className="labelDisplay">
                                            Clique
                                            <img data-tip="URL de redirecionamento após clique na publicação " src={help} alt="" />
                                        </label>

                                        <div className="displayInput copy">
                                            <input type="text" name="link-click" value={short_hash.hash_click ? `${baseURL_shortened}/${short_hash.hash_click}` : publication.url_click || ''} disabled />
                                            {short_hash.hash_click &&
                                                <div onClick={() => handleClickCopy(`${baseURL_shortened}/${short_hash.hash_click}`)}>
                                                    <img src={copy} alt='img-icon-copy' />
                                                    <span>Copiar</span>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    <div className="productField">
                                        <div className="fieldDetails">
                                            <section data-tour="step-details-08">
                                                <div className="displayInput pointer">
                                                    <input
                                                        className="fieldCliqueDetails"
                                                        placeholder="Insira o link do clique"
                                                        onChange={handlerChangeDetails}
                                                        value={formUpdate.url_click_long || ''}
                                                        id="details-publication-input"
                                                        name="url_click_long"
                                                        type="text"
                                                    />
                                                    <img src={edit} alt="" />
                                                    <span>Editar</span>
                                                </div>
                                                <div className="displayWhither">
                                                    <img src={update} alt="" />
                                                    <p><span>Destino: </span>www.seulinkoriginal.com.br</p>
                                                </div>
                                            </section>
                                            <div className="btnShortener" >
                                                <button onClick={handlerClick} className='btn-short' data-tour="step-details-09">
                                                    {loading.shortClick
                                                        ? <span>{btnLoadingGrow} Encurtando link...</span>
                                                        : <span>Encurtar Link</span>
                                                    }
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className='message-clik'></div>
                            </div>
                            <div className="section" data-tour="step-details-14">

                                <div className="fieldAudio">
                                    <label>Áudio da Publicação</label>
                                    <div className="displayInput pointer" data-tour="step-details-15">
                                        {audioSelected ? (
                                            <div className='displayAudioDetails'>
                                                <audio id="audio" className='audioDisplay' autoPlay={false} controls>
                                                    <source id="source" src={audioFile} type="audio/ogg" />
                                                    <source id="source" src={audioBase64} type="audio/ogg" />
                                                </audio>
                                                <div className='removerAudioDetails'>
                                                    <span onClick={() => handleRemoveAudio()}><img src={edit} width={24} alt="chanegAudio" /> remover </span>
                                                </div>
                                            </div>
                                        ) :
                                            <div className="contentFileAudioDetails">
                                                <label htmlFor="selection-file-audio">
                                                    <input id="selection-file-audio" type="file" name="fileAudio" onChange={handlerChangeAudio} />
                                                    <img src={audio} alt="nota musical" />
                                                    <span>Faça upload do seu áudio</span>
                                                </label>
                                            </div>

                                        }
                                    </div>

                                </div>

                                <div className="btnSalveAudio">
                                    <button onClick={handlerUploadAudio} className='btn-short' data-tour="step-details-16">
                                        {loading.audio
                                            ? <span>{btnLoadingGrow} Adicionando áudio...</span>
                                            : <span>Adicionar Áudio</span>
                                        }
                                    </button>
                                </div>

                                <div className='message-audio'></div>

                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="productPreview" data-tour="step-details-17">
                                <div className="selectPreview" onClick={selectPreviewType} data-tour="step-details-18">
                                    {typePreview === "html" && publication.document.type_publication !== 'video-free' ? "Preview HTML" : "Preview Vídeo"}
                                    <img src={arrowDropDown} alt="" />
                                </div>


                                {typePreview === 'html' && publication.document.type_publication !== 'video-free' ? (
                                    <div className="preview">
                                        {!!viewTemplate ? (
                                            <RenderPreviewHTML viewTemplate={viewTemplate} />
                                        ) : (
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        )
                                        }
                                    </div>
                                ) : !!video && (

                                    <div ref={videoRef} className="preview">
                                        <RenderPreviewVideo video={video.replace('_playerVideo.html', '.mp4')} width={publication.document.doc_width} height={publication.document.doc_height} />
                                    </div>

                                )}
                            </div>
                        </div>
                    </div>

                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header>
                            <Modal.Title>Sincronização com Mineb.io</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {hidden_warning_minebio && (hidden_warning_minebio)}
                            {loading.minebio && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {btnLoadingBorder}
                                </div>
                            )}
                            {optionsPagesMinebIO.length > 0 && <>
                                <h3>Escolha uma de suas páginas da mineb.io: </h3>
                                <Form.Select id='selectPagesMinebio'>
                                    {optionsPagesMinebIO.map((item, key) => (
                                        <option value={item.id} key={key}> {item.name} </option>
                                    ))}
                                </Form.Select>
                            </>}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>Fechar</Button>
                            {optionsPagesMinebIO.length ? <>
                                <Button variant="primary" onClick={handlePublicationMinebIO} >Linkar</Button>{' '}
                            </> : <></>}
                        </Modal.Footer>
                    </Modal>

                    {showDestroy && <ModalDestroyPublication publication_id={publication.document.id} show={showDestroy} setShow={(setShowDestroy)} />}
                    {showChannels && <TableChannelsResize publication_id={publication.document.id} show={showChannels} setShow={(setShowChannels)} />}

                </div>
            </div>
        </TourGuiadoDetails>
    );
}
