import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { TourGuiadoSocialMedia } from '../../pages/socialMediaPublication/TourGuiadoSocialMedia';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import calendar from '../../assets/images/calendar.png';
import copy from '../../assets/images/copiar.svg';
import tiktokName from '../../assets/images/instagram-name.png';
import logoInstagram from '../../assets/images/logo-instagram.png';
import arrowCollapse from '../../assets/images/seta-collapse.svg';
import api from '../../config/api';
import apiSocial from "../../config/api-social";
import FacebookLoginButton from '../facebook/FacebookLoginButton';
import mockupReelsTop from '../../assets/images/mockup-instagram-reels-top.png';
import mockupReelsRight from '../../assets/images/mockup-instagram-reels-right.png';
import mockupFeedTop from '../../assets/images/mockup-instagram-feed-top.png';
import mockupFeedBottom from '../../assets/images/mockup-instagram-feed-bottom.png';
import { addUpperCasePrimaryChar } from '../../helpers/addUpperCasePrimaryChar';

export const InstagramSocialMedia = ({ CustomToggle, publication_id }) => {

  const [instagramPageSelected, setInstagramPageSelected] = useState(null);
  const [checkboxActiveInstagram, setCheckboxActiveInstagram] = useState(false);
  const [activeInstagram, setActiveInstagram] = useState(false);
  const [dataListInstagram, setDataListInstagram] = useState([]);
  const [loadingInstagram, setLoadingInstagram] = useState({
    verifyAuth: true,
    connectionInstagram: true,
    connectionFacebookPages: false,
    disconnectionInstagram: false,
  });
  const [facebookPages, setFacebookPages] = useState([])
  const { user } = useAuthenticator((context) => [context.user]);
  const [publication, setPublication] = useState({
    document: {},
    layers: []
  });

  useEffect(() => {
    loadData();

    return () => {
      setDataListInstagram([])
      setActiveInstagram(false)
      setInstagramPageSelected(null);
      setCheckboxActiveInstagram(false);
      setLoadingInstagram({
        verifyAuth: true,
        connectionInstagram: true,
        connectionFacebookPages: false,
        disconnectionInstagram: false,
      });
      setFacebookPages([]);
      setPublication({ document: {}, layers: [] });
    }
    // eslint-disable-next-line
  }, [user]);

  const loadData = useCallback(async () => {
    const pub = (await api.get(`/publications/one/${user.attributes.sub}/${publication_id}`)).data;
    const { documentInfo, layerInfo } = pub;
    setPublication({
      document: documentInfo,
      layerInfo: layerInfo
    });

    let social_network = 'instagram';
    const medias = (await api.get(`/medias/search/${documentInfo.id}/${social_network}`)).data;
    
    const mediasInstagram = [];

    medias.forEach(currentMedia => {
      //"instagram larger" || "instagram square" || "instagram horizontal" || "instagram cover"
      let data = {
        key: currentMedia.id,
        format: currentMedia.format_social_network,
        size: `${currentMedia.doc_width}x${currentMedia.doc_height}`,
        width: currentMedia.doc_width,
        height: currentMedia.doc_height,
        url_print: '',
        type: '',
        thumbnail: documentInfo.thumbnail
      }

      if (currentMedia.format_social_network.includes('feed') && currentMedia.extension === 'html') {
        data.type = 'Imagem';
        data.url_print = currentMedia.url_print;
        mediasInstagram.push(data)
      } 
      
      else if (currentMedia.format_social_network.includes('reels')  && currentMedia.extension === 'mp4') {
        data.type = 'Video';
        if (currentMedia.url_print.includes('_playerVideo')) {
          data.url_print = currentMedia.url_print.replace('_playerVideo.html', '.mp4');
        } else {
          data.url_print = currentMedia.url_print.replace('.html', '.mp4');
        }
        mediasInstagram.push(data)
      }

    });

    setDataListInstagram(mediasInstagram);

    await apiSocial.get('/api/facebook/pages').then(async ({ data }) => {
      setFacebookPages(data);

      setActiveInstagram(true);
      setCheckboxActiveInstagram(true);
       await apiSocial.get('/api/accounts/instagram-page').then(({ data }) => {
        if (data) setInstagramPageSelected(String(data));
      })
    }).catch(() => {
      setCheckboxActiveInstagram(false);
      setActiveInstagram(false);
    }).finally(() => {
      setLoadingInstagram((prevState) => ({ ...prevState, verifyAuth: false }));
    })

  }, [publication_id, user.attributes.sub])

  const selectPage = useCallback(async (e) => {
    e.preventDefault();

    let { value: page_id } = e.target

    if (!page_id) {
      setInstagramPageSelected(null);
      return;
    };

    setLoadingInstagram((prevState) => ({ ...prevState, connectionFacebookPages: true }));

    await apiSocial.post(`/api/facebook/choose-page/${page_id}`)
      .then(async () => {
        await apiSocial.get('/api/instagram/business-account').then(() => {
          setInstagramPageSelected(String(page_id));
        }).catch((e) => {
          console.error(e);
          setInstagramPageSelected(null);
        }).finally (() => {
          setLoadingInstagram((prevState) => ({ ...prevState, connectionFacebookPages: false }));
        })
      }).catch((er) => {
        console.error(er);
      }).finally (() => {
        setLoadingInstagram((prevState) => ({ ...prevState, connectionFacebookPages: false }));
      })
  }, [])

  const handlStatusMessage = (elem, status, message) => {
    const statusMessage = elem;

    statusMessage.innerHTML = message;
    statusMessage.classList.add(`status-message-${status}`);

    setTimeout(() => {
      statusMessage.innerHTML = "";
      statusMessage.classList.remove(`status-message-${status}`);
    }, 4000);
  }

  const publishToInstagram = useCallback(async (item) => {

    const { key, type: format, url_print, thumbnail } = item;

    let channel = '';
    if (format === "Imagem") channel = 'feed';
    else if (format === "Video") channel = 'reels';
    else throw new Error("channel not supported")

    let statusText = document.querySelector(`.text-publish-${key}`)
    const statusMessage = document.getElementById(`status-message-${key}`);
    let thumbOffset = Number(document.getElementById(`time-video-insta${key}`).value);

    if (thumbOffset > Number(publication.document.time_animation)) {
      handlStatusMessage(statusMessage, 'error', 'O campo "Tempo em segundo" é maior  que o tempo do video.');
    }

    statusText.innerHTML = 'Enviando...';

    const createPost = {
      caption: "",
      media_id: key,
      type: 'instagram',
      image_url: channel === 'feed' ? thumbnail : null,
      video_url: channel === 'reels' ? url_print : null,
      channel: channel,
      scheduleMode: "Publicação Imediata",
      thumbnail: thumbnail,
      thumb_offset: Number(thumbOffset)
    }

    await apiSocial.post(`/api/instagram/create-post`, createPost).then(() => {
      handlStatusMessage(statusMessage, 'success', 'Imagem publicada com sucesso.');
    }).catch(() => {
      handlStatusMessage(statusMessage, 'error', 'Opss... não conseguimos publicar sua postagem!');
    }).finally(() => {
     statusText.innerHTML = 'PUBLICAR';
    })
     // eslint-disable-next-line
  }, [])

  const toggleInputIncorporate = className => {
    const element = document.querySelector('.' + className);
    element?.classList.toggle('open');
  }


  const closeModalDisconnect = className => {
    setCheckboxActiveInstagram(true);
    const element = document.querySelector('.' + className);
    element?.classList.toggle('close');
  }

  const handleClickCopy = (text) => navigator.clipboard.writeText(text);

  const handleDisconnectInstagram = async () => {
    try {
      const type =  'instagram';
      setLoadingInstagram((prevState) => ({ ...prevState, disconnectionInstagram: true }));
      await apiSocial.delete(`/api/accounts/${type}`);
    } catch(e) {
      console.error(e);
    } finally {
      setLoadingInstagram((prevState) => ({ ...prevState, disconnectionInstagram: false }));
    }
  }


  return (
    <TourGuiadoSocialMedia>
      <>
        <Accordion defaultActiveKey="1" data-tour="step-social-media-02">
          <Card>
            <Card.Header>
              <div className="headerLeft">
                <img className="img-fluid logo-media" src={logoInstagram} alt="" />
                <img className="img-fluid logo-name" src={tiktokName} alt="" />
              </div>
              <div className="headerRight">
                {loadingInstagram.verifyAuth ?
                  <div className='spinnerSocialMedia'>
                    <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
                  </div>
                  :
                  <>
                    <input
                      id="status-instagram"
                      type="checkbox"
                      className="toggle toggle-instagram"
                      onChange={() => setCheckboxActiveInstagram(!checkboxActiveInstagram)}
                      defaultChecked={checkboxActiveInstagram}
                      checked={checkboxActiveInstagram}
                    />

                    <label htmlFor="status-instagram">
                      <span className={checkboxActiveInstagram ? "active" : "no-active"}>
                        {checkboxActiveInstagram ? "Conectado" : "Desconectado"}
                      </span>
                    </label>
                    <CustomToggle eventKey="0">
                      <img src={arrowCollapse} alt="Accordion" />
                    </CustomToggle>
                  </>
                }

              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {!loadingInstagram.verifyAuth && !activeInstagram &&
                  <div className="alertConnect">
                    <span>Antes é necessário ativar a rede</span>
                    <FacebookLoginButton type='instagram' />
                  </div>
                }

                {!checkboxActiveInstagram && activeInstagram  &&
                  <div className="alertConnect modal-disconnect">
                    <button className="btn-close" onClick={()=> closeModalDisconnect('modal-disconnect')}></button>
                    <span>Deseja realmente desconectar-se da rede?</span>
                    <button className="btn-disconnect" onClick={()=> handleDisconnectInstagram()}>
                      {loadingInstagram.disconnectionInstagram && <Spinner animation="grow" role="status"  aria-hidden="true" />} 
                      Desconectar
                    </button>
                  </div>
                }

                {checkboxActiveInstagram && activeInstagram &&
                  <div className="select-pages">
                  <span>Qual página deseja realizar a publicação?</span>
                  <select name="select-pages-instagram" id="select-pages-instagram" onChange={(e) => selectPage(e)}>
                    <option key={0} defaultChecked value="">Selecione uma página</option>
                    {facebookPages.length > 0 ? facebookPages.map((page) => {
                      return <option 
                        key={page.id} 
                        selected={ instagramPageSelected === page.id ? true : false } 
                        value={page.id} 
                      >
                        {page.name}
                      </option>
                    }) : <>Você não possui nenhuma página!</>}
                  </select>
                </div>
                }

                {loadingInstagram.connectionFacebookPages &&
                  <div className="loadingMedias_socialMedias mt-3">
                    <Spinner animation="grow" role="status"  aria-hidden="true" />
                  </div>
                }

                {!loadingInstagram.connectionFacebookPages && !loadingInstagram.medias && checkboxActiveInstagram &&
                  activeInstagram && instagramPageSelected && dataListInstagram.map((item, index) => {
                  let contentIncorporat = "<div>" +
                    "<iframe width='800px' height='450px' src=" + item.url_print + " title='content Incorporat'></iframe>" +
                    "</div>";
                    
                  return (
                    <div className="row" key={index}>
                      <div className="displayBody">
                        <div className="formatPreview">
                          <div className="mockup-content">
                            { item.format === 'instagram-reels' ? <img src={mockupReelsTop} alt="mockup rede social" className="top-reels"/> : <img src={mockupFeedTop} alt="mockup rede social" className="top" /> }
                            { item.format === 'instagram-reels' ? <img src={mockupReelsRight} alt="mockup rede social" className="right-reels"/> : <img src={mockupFeedBottom} alt="mockup rede social" className="bottom" /> }
                            {item.type === 'Imagem'
                            ? <img 
                                width={270}
                                height={item.format === 'instagram-feed-quadrado' ? 270: item.format === 'instagram-feed-horizontal' ? 230 : 400 }
                                src={publication.document.thumbnail}
                                alt="thumbnail"
                              />
                            : <>
                              {item.height > item.width
                                ? <video className="video-mockup" height="400" width="270" src={item.url_print} controls></video>
                                : <video className="video-mockup" height="auto" width="270" src={item.url_print} controls></video>
                              }
                            </>
                            } 
                          </div>
                          {item.type !== 'Imagem'
                            ? <div className="select-video-cover">
                                <button data-tip="Escolhar o tempo em segundos do video que deseje manter como capa."  onClick={() => toggleInputIncorporate('time-video-insta'+ index)}>Escolher capa</button>
                                <div className={`time-video time-video-insta${index}`}>
                                  <p>  Tempo em segundo</p>
                                  <input id={`time-video-insta${item.key}`} type="number" max={Number(publication.document.time_animation)} />
                                </div>
                              </div>
                            : ''
                          }
                          <div className="publicationText publicationTextSocialMedia">
                              <textarea name="" id="" placeholder="Insira um texto para postagem."></textarea>
                          </div>
                        </div>
                        <div className="formatInformation">
                          {item.format.includes("STORIES") &&
                            <><strong> Publicação nos Stories está em fases de teste! </strong><br /></>
                          }
                          <div className="formatInformationHeader">
                            <Link to={`/schedulePublication/${item.key}`}>
                              <button className="btn-scheduling">
                                <img src={calendar} alt="calendário" />
                              </button>
                            </Link>
                            <button className="btn-incorporate" data-tip="Clique para incorporar em sites ou blogues." onClick={() => toggleInputIncorporate ('input-incorporat-insta-' + index)}>
                              <span>INCORPORAR</span>
                            </button>
                            <button className="btn-publish" data-tip="Clique para publicar todos os formatos gerados." onClick={() => publishToInstagram(item)}>
                              < ReactTooltip className="customTooltip" />
                              <span className={`text-publish-${item.key}`}>PUBLICAR</span>
                            </button>
                            < ReactTooltip className="customTooltip" />
                          </div>
                          <div className="incorporateCode">
                            <div className={`displayInput input-incorporat-insta-${index}`} >
                              <input type="text" defaultValue={contentIncorporat} />
                              <button className="btn-copy">
                                <img src={copy} alt="" onClick={() => handleClickCopy(contentIncorporat)} />
                              </button>
                            </div>
                          </div>
                          <table>
                            <tbody>
                              <tr>
                                <td className="typeInformation">
                                  <span>Formato:</span>
                                </td>
                                <td className="contentInformation">
                                  <span>{addUpperCasePrimaryChar(item.format, '-')}</span>

                                </td>
                              </tr>
                              <tr>
                                <td className="typeInformation">
                                  <span>Tamanho:</span>
                                </td>
                                <td className="contentInformation">
                                  <span>{item.size}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className="typeInformation">
                                  <span>Tipo:</span>
                                </td>
                                <td className="contentInformation">
                                  <span>{item.type}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div id={`status-message-${item.key}`}></div>
                    </div>
                  )
                })}

                {!loadingInstagram.connectionFacebookPages && !loadingInstagram.medias && checkboxActiveInstagram &&
                  activeInstagram && instagramPageSelected && dataListInstagram.length === 0 && 
                    ( <p className="text-no-content-compatible">Você não possui nenhum formato gerado compatível com Instagram.</p>)
                }

              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    </TourGuiadoSocialMedia>

  );
}