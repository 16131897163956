import React from "react";
import "./topbar.css";
import userPhoto from '../../assets/images/foto-perfil.png';
import workspace from '../../assets/images/workspace.svg';
import information from '../../assets/images/information-circle.svg';
import { useTour } from '@reactour/tour';
import { Link } from "react-router-dom";

export default function Topbar() {
  // eslint-disable-next-line
  const { setIsOpen } = useTour(false);

  return (
      <div className="container">
        <div className="topbarDisplay-default">
          <Link key="/switch-workspace" to="/switch-workspace" className="topbarItem link ">
            <img src={workspace} alt="espaço de trabalho" />
            <span>Workspace</span>
          </Link>
          <div className="topbarItem" onClick={() => setIsOpen(true)} style={{ cursor: 'pointer' }}>
            <img src={information} alt="informação" />
            <span style={{ marginLeft: '-1px' }}> Guia </span>
          </div>
          <div className="topbarItem">
            <img src={userPhoto} alt="foto de um rosto masculino" />
          </div>
        </div>
      </div>
  );
}

