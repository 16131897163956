import "./formatsPublication.css";
import { useParams } from "react-router-dom";
import FormatsPublication from "./FormatsPublication";
import FormatsPublicatioVideoFree from "./FormatsPublication-video-free";

export default function FormatsControl() {
  const params = useParams();

  if (params.type_publication === 'video-free') {
    return  (<FormatsPublicatioVideoFree /> );
  } else {
    return ( <FormatsPublication /> );
  }  
}
