import React, { useEffect } from "react";

export default function RenderPreviewHTML({ viewTemplate }) {
    
    useEffect(() => {
        handleScriptBehavior();
    }, [viewTemplate])
    
    function handleScriptBehavior() {

        let newCurrent = document.querySelector("[id='scriptJs_pug_behavior']");
        let radom_id_pub = Math.floor(Date.now() * Math.random()).toString(36).concat(Math.floor(Math.random() * 65536).toString());
        
        if(newCurrent) {
        
            let script = document.createElement('script');
            script.type = "text/javascript";
            script.id = `behavior-script-publication${radom_id_pub}`;
            script.name = `behavior-script-publication`;
            script.textContent = newCurrent.textContent;
            document.body.append(script)

           setTimeout(() => {
            document.getElementById(`behavior-script-publication${radom_id_pub}`).remove();
           }, 1500);
           
        }

    }

    return (
        <div key={"effetc_unique_key"} dangerouslySetInnerHTML={{ __html: viewTemplate }} />
    )
}

