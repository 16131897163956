import { useRef } from 'react';
import { useState } from 'react'
import api from '../../config/api';
import './HelpCenter.css'
import upload from '../../assets/images/upload.svg';
import { TourGuiadoSupport } from './TourGuiadoSupport';
import { useAuthenticator } from '@aws-amplify/ui-react';

export default function HelpCenter() {
  const { user } = useAuthenticator((context) => [context.user]);

  const [formState, setFormState] = useState({
    name: '',
    description: '',
    email: user.attributes['email'],
    tel: '',
    typeReport: 'Problema Técnico'
  });
  const [attachments, setAttachments] = useState([]);
  const inputFile = useRef(null);

  const maskTel = (event) => {
    const { name, value } = event.target;
    let keyPress = event.key
    let tel = value.replace(/\D+/g, "");

    if (/^[0-9]$/i.test(keyPress)) {

        tel = tel + keyPress;
        let tamanho = tel.length;

        if (tamanho >= 12) { 
            return false;
        }
        if (tamanho > 10) {
            tel = tel.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (tamanho > 5) {
            tel = tel.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (tamanho > 2) {
            tel = tel.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else {
            tel = tel.replace(/^(\d*)/, "($1");
        }

        setFormState((prevState) => ({ ...prevState, [name]: tel }));
    }

    else if (["Backspace", "Delete"].includes(keyPress)) {
      const newTel = tel.substring(0, tel.length - 1);
      setFormState((prevState) => ({ ...prevState, [name]: newTel }));
      return true;
    } 
    
    else {
      return false
    }
    
  }

  const onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name !== "attachments") {
      if(name === 'typeReport') 
        setFormState((prevState) => ({ ...prevState, [name]: ( value === 1 ? 'Problema Técnico' : 'Dúvida Sobre a Ferramenta') }));
      if(name === 'tel') 
        maskTel(event);
      else 
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    }

  }
  const [onloadingSubmit, setOnloadingSubmit] = useState(false);

  const onChangeFiles = (event) => {
    event.preventDefault();
    const { files } = event.target;
    const tempFiles = []
    for (let i = 0; i < files.length; i++) {
      tempFiles.push(files[i]);
    }
    setAttachments(tempFiles);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    
    const {name, description, email, tel, typeReport} = formState;
    
    let nameTrim = name.trim();
    let emailTrim = email.trim();
    let telTrim = tel.trim();
    let descriptionTrim = description.trim();
    let typeReportTrim = typeReport.trim();

    if(!nameTrim || !emailTrim || !telTrim || !descriptionTrim || !typeReportTrim) {
      alertMessages('preencha todos os campos obrigatórios!', 'error');
      return {}
    }
    if(!validatedEmail(emailTrim)) {
      alertMessages('E-mail inválido!', 'error');
      return {}
    }
        
    setOnloadingSubmit(true);

    const formData = new FormData();
    formData.append('name', nameTrim)
    formData.append('user_email', emailTrim)
    formData.append('tel', telTrim)
    formData.append('description', descriptionTrim)
    formData.append('typeReport', typeReportTrim)
    for (let i = 0; i < attachments.length; i++) {
      formData.append('attachments', attachments[i]);
    }

    api.post('/help-center', formData)
      .then(() => {
        setOnloadingSubmit(false);
        alertMessages('Mensagem enviada com sucesso!', 'success');
        console.log("ENVIADO COM SUCESSO!");
        
      })
      .catch((err) => {
        setOnloadingSubmit(false);
        alertMessages('Opss... tente novamente mais tarde!!', 'error');
        console.error(err)
      })

  }

    const alertMessages = (text, type) => { // @type => success || error
      let display = document.getElementById(`display-${type}`);
      display.style.display = 'block';
      let msg = document.getElementById(`msg-${type}`);
      msg.innerText = `${text}`;

      setTimeout(() => {
          display.style.display = 'none';
          msg.innerText = "";
      }, 5000);
  }

  function validatedEmail(email) {
    var re = /\S+@\S+\.\S+/;
    const test = re.test(email);
    const elem = document.querySelector('.email-invalided ');
    if(test) {
      elem.innerText = "";
      return test
    } else {
      elem.innerText = " E-mail inválido";
      return test
    }
  }

  return (
    <TourGuiadoSupport>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="pageBookmark">
              <p>Início/ Central de Ajuda/ </p>
              <span>Suporte</span>
            </div>  
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="reportProblem" data-tour="step-support-01">
              <h1>Relate seu problema</h1>
              <form>   
                <div className="display-form">
                  <section data-tour="step-support-02" >
                    <label>Nome completo*</label>
                    <input name="name" type="text" value={formState.name} onChange={onChange} />
                  
                    <label>E-mail* <span htmlFor="email" className='email-invalided'>  </span> </label> 
                    <input name="email" type="email" onBlur={() => validatedEmail(formState.email)} value={formState.email} onChange={onChange} />

                    <label>Telefone*</label>
                    <input name="tel" type="tel" value={formState.tel} onKeyDown={onChange} />
                  </section>
                
                  <section data-tour="step-support-03" >
                    <div onChange={onChange}>
                      <h4 htmlFor="[type=radio]">Que tipo de ajuda você procura?</h4>
                    
                      <label htmlFor="radioProblem">
                      <input type="radio" name="typeReport" defaultChecked value={1} id="radioProblem" /> Problema Técnico</label>
                      <label htmlFor="radioQuestion">
                      <input type="radio" name="typeReport" value={2} id="radioQuestion" /> Dúvida Sobre a Ferramenta</label>
                    </div> 
                                
                    <label>Ajude-nos a entender*</label>
                    <textarea name="description" value={formState.description} onChange={onChange} rows="5"  />

                    <button onClick={() => inputFile.current.click()} type="button" className="btn-select-file" >
                      <img src={upload} alt="" />
                      {attachments.length < 1 ?
                        'Selecionar anexos'
                        :
                        <>
                        {attachments.map((attachment, index) => (
                          <>
                            {index !== attachments.length - 1 ?
                              <span key={attachment.name}>{attachment.name}, </span>
                              :
                              <span key={attachment.name}>{attachment.name}</span>
                            }
                          </>
                        ))}
                        </>
                      }
                      <input 
                        type="file" 
                        name="attachments" 
                        multiple onChange={onChangeFiles} 
                        value={formState.fileName} 
                        ref={inputFile} 
                        style={{ display: 'none' }} 
                      />
                    </button>

                  </section>
                </div>         
                
                <div id="display-success"><p id="msg-success">  </p></div>
                <div id="display-error"><p id="msg-error">  </p></div>
              
              <div className='btnDisplay'>
                <button className="btn-submit" onClick={onSubmit} data-tour="step-support-04" >
                  {onloadingSubmit 
                    ? <>Enviado sua mensagem...</>
                    : <>Enviar</>
                  }
                </button>
              </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </TourGuiadoSupport>
  )
}