import "./createPublicationData.css";
import React, { useState, useRef } from 'react';
import api from '../../config/api';
import { useHistory, Link } from 'react-router-dom';
import { createPublicationFilter } from '../../helpers/createPublicationFilter';
import { useAuthenticator } from "@aws-amplify/ui-react";
import RenderPreviewHTML from "../../components/renderPreviewHTML/RenderPreviewHTML";
import chat_whatsApp_data from '../../assets/tmps/chat_whatsApp_data.json';
import { chatWhatsApp_schema, carrouselFree_schema } from '../../config/schemaJson';
import { toDataUpload, toDataURL } from "../../helpers/convertImgToBase64";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import ReactTooltip from 'react-tooltip';
import upload from '../../assets/images/upload.svg';
import arrowBack from '../../assets/images/arrow_back.svg';
import download from '../../assets/images/download.svg';
import mockupWhats from '../../assets/images/celular-whatsapp.svg';
import whats from '../../assets/images/whats-app.png';
import carousel from '../../assets/images/carousel-app.png';
import audio from '../../assets/images/music_note.svg';
import help from '../../assets/images/help-grey.svg';
import { Spinner } from "react-bootstrap";
import { TourGuiadoCreateData } from "./TourGuiadoCreateData";

const pathBaseAssest =  '/downloads/files';

export default function CreatePublicationData() {

    const [stepCurrent, setStepCurrent] = useState(1);
    const history = useHistory();
    const { user } = useAuthenticator((context) => [context.user]);
  
    const [addLabels, setAddLabels] = useState([]);
  
    const [selectedFileData, setSelectedFileData] = useState({});
    const [isSelected, setSelected] = useState(false);
    const [loading, setLoading] = useState({ preview: false, generation: false });
    const [effect, setEffect] = useState('');
    const [viewTemplate, setViewTemplate] = useState();
    const [nameAndLabels, setNameAndLabels] = useState({ name: '', labelAll: '' });
    const [click, setClick] = useState('');
    const [publication, setPublication] = useState({ document: {}, layers: [] });
    const previewRef = useRef(null);
    const [data_template, setData_template] = useState();
    const [audioBase64, setAudioBase64] = useState('');
    const [addAudio, setAddAudio] = useState(false);
    const [getTimeAnimationData, setGetTimeAnimationData] = useState(0);

    const changeHandler = (event) => {
      if (!(!!event.target.files[0])) return;
      const type = event.target.files[0].name.split('.').pop();
      if (type === 'json') {
        setSelectedFileData(event.target.files[0]);
        setSelected(true);
      }
    };
  
    const handlerChangeAudio = async function (event) {
      setAudioBase64('');
      
      if (!(!!event.target.files[0])) return;
      const type = event.target.files[0].name.split('.').pop();
      if (!['mp3'].includes(type)) return;
  
      const audio = event.target.files[0];
      const audioBase64 = await toDataUpload(audio);
  
      setAudioBase64(audioBase64);
  
      setSelected(true);
  };

    const readabledDialog = (file) => {
      try {
        const render = new FileReader();
        return new Promise((resolve, reject) => {
          render.onload = async function() {          
            setData_template(this.result.toString());
            resolve( JSON.parse(this.result.toString()) );
          }
          render.onerror = (err) => reject(err);
          render.readAsText(file);
        });
      } catch (error) {
        console.error("ERROR READABLE JSON: ", error);
      }    
    }
  
    const handlerUploadPublication = async (animation = effect, isAddAudio) => {
      if(loading.preview || loading.generation) return;

      if (!isSelected || animation.trim() === '') return;
      setLoading((prevState) => ({ ...prevState, preview: true }));

      let response = {};
      window.scrollTo({ behavior: 'smooth', top: previewRef.current.offsetTop });
      
      switch (animation) {
        case 'chat_whatsApp_data':
        case 'chat_deputado_data':
          response = {...chat_whatsApp_data};
          try {
            const dialogParse = await readabledDialog(selectedFileData); 
            const schemaValidator = chatWhatsApp_schema(dialogParse);
            if(schemaValidator) {
              for await (const iterator of dialogParse.dialog) {
                if(!!iterator.image) {
                  const base64 = await toDataURL(iterator.image.url);
                  iterator.image.url = `${base64}`;
                }
              }
            }
            response.type = "update";
            response.dialog = dialogParse;
            response.status = schemaValidator;

          } catch (error) {
            setLoading((prevState) => ({ ...prevState, preview: false }));
            console.error("Error: ", error)
          }
          break;
          case 'carouselScenesData':
            const dataCarousel = await readabledDialog(selectedFileData); 
            const schemaValidator = carrouselFree_schema(dataCarousel);
            response.status = schemaValidator; 
            
            if(schemaValidator) {
              response.documentInfo = {...dataCarousel.documentInfo, data_template: JSON.stringify(dataCarousel.config)};

              const layers = [];
              for ( const [index, base64] of dataCarousel.carrousel.entries() ) {
                let lastname = (index+1) < 10 ? '0'+(index+1) : (index+1);
                layers.push({
                  name: `00_${lastname}`,
                  height: dataCarousel.documentInfo.doc_height,
                  width: dataCarousel.documentInfo.doc_width,
                  opacity: 1,
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  text: "",
                  z_index: index,
                  base64:  base64.split(',')[0].includes('base64') ?  `${base64}` : `data:image/png;base64,${base64}`,
                  update: false, 
                  animation: "fandIn"
                })
              }
              response.layerInfo = [layers];
              setData_template(JSON.stringify(dataCarousel.config));
            }
            
          break;
      
        default:
          console.info("Template não encontrado...");
          break;
      }
  
      if(response.status) {

        if(isAddAudio) {
          response.documentInfo.url_audio = audioBase64;
        }

        const template = await api.post(`publications/render/${animation}`, response)
        .then(response => response.data)
        .catch(error => {
          setLoading((prevState) => ({ ...prevState, preview: false }));
          console.error("Error: ", error)
        });
  
        setViewTemplate(template);
        
        setPublication({
          document: response.documentInfo,
          layers: response.layerInfo
        });
        
        handleAwaitGetTimeAnimation();
        
      }
  
      setLoading((prevState) => ({ ...prevState, preview: false }));
    }
  
    const handlerGereration = async () => {
      if(loading.preview || loading.generation) return;
      if (!nameAndLabels.name || !nameAndLabels.labelAll) {
        alert("OPS... Campos name, labels e upload de publicação são obrigatórios");
        return;
      }
      setLoading((prevState) => ({ ...prevState, generation: true }));
      const dataCreate = {
        name: nameAndLabels.name,
        label: nameAndLabels.labelAll,
        doc_height: `${publication.document.doc_height}`,
        doc_width: `${publication.document.doc_width}`,
        data_template: data_template,
        effect: effect,
        click: click,
        audio: audioBase64,
        timeAnimation: getTimeAnimationData,
        typePublcation: 'banner-data'
      }
      try {
        const layerFilter = publication.layers.length > 0 ? await createPublicationFilter(publication.layers) : null;

        const { data } = await api.post(`/publications/create/${user.attributes.sub}`, {
          document: dataCreate,
          layer: layerFilter,
          template: viewTemplate
        });
  
        setLoading((prevState) => ({ ...prevState, generation: false }));
        history.push(`/animation/details/${data}`);
      } catch (error) {
        console.error("Error: ", error);
        setLoading((prevState) => ({ ...prevState, generation: false }));
      };
    }
  
    // eslint-disable-next-line
    const handleAddLabels = () => {
      let count = document.querySelectorAll('#labels').length + 1;
      setAddLabels([...addLabels, count]);
    };
  
    // eslint-disable-next-line
    const handleRemoveLabels = (count) => {
      let elementChildren = document.getElementById(`labelsPublication_${count}`);
      while (elementChildren.firstChild) {
        elementChildren.removeChild(elementChildren.firstChild);
      }
      let  newLabels = addLabels.filter( (item) => item !== count);
      setAddLabels([...newLabels]);
    };

    const selectStep = (step) => {
      let cheklistSteps = false;

      switch (step) {
        case 1:
        case 2:
          //download json
          cheklistSteps = true;
          break;
          case 3:
            if(isSelected === true) cheklistSteps = true;
          break;
        case 4:
          const name = document.querySelector('#namePublication').value.trim();
          const labelAll = document.querySelector("#labels").value.trim();
          if(name && labelAll) {
            setNameAndLabels({ name, labelAll });
            cheklistSteps = true;
          }
          break;
      
        default:
          console.log("Etapa não encontrada.");
          break;
      }

      if(cheklistSteps){
        setStepCurrent(step);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    }

    const selectStepByNavigation = (next) => {
      if (next < stepCurrent) {
        selectStep(next);
      }
    }

    function clickAnimation(animation) {
      if(loading.preview || loading.generation) return;

      let removeAction = document.querySelector(`[name="${effect}"]`);      
      if(removeAction) removeAction.classList.remove('activeAnimation'); 

      let addAction = document.querySelector(`[name="${animation}"]`); 
      addAction.classList.add('activeAnimation');

      setEffect(animation);
      handlerUploadPublication(animation);
    }


  function handleConfirmeAddAudio() {
    setAddAudio(true);
    handlerUploadPublication(effect, true);
  }

  function handleConfirmeRemoverAudio() {
    setAudioBase64('');
    if(addAudio) {
      handlerUploadPublication(effect, false);
      setAddAudio(false);
    }
  }

  function handleChangeNameAndLabels(event) {
    const { name, value } = event.target;
    setNameAndLabels((prev) => ({...prev, [name]: value}));
  }

  function handleAwaitGetTimeAnimation() {
    const searchClassInterval = setInterval(() => {
      const doc = document.querySelector(".document");
      if(doc) {
        clearInterval(searchClassInterval);
        const callback = setInterval( function() {
          let timeAnimation = Number(document.querySelector(".document").id.split("_")[1]);
          if(timeAnimation) {
            clearInterval(callback);
            setGetTimeAnimationData(timeAnimation);
          } 
        }, 1000);
      }

    }, 1000);    
  }

    return (
      <TourGuiadoCreateData stepCreate={stepCurrent}>
        <div className="product">
          <div className="container">

            <div className="row">
                <div className="col-md-12">
                  <div className="topBackPage">
                    <Link key="/typePublication" to="/typePublication" className="link">
                      <button>
                        <img src={arrowBack} alt="" />
                          Cancelar
                      </button>
                    </Link>
                  </div>  
                </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="stepWizard">
                  <div className="stepWizard-row-2">
                    <div className="step">
                      <button href="#step-1" type="button" onClick={() => selectStepByNavigation(1)} className={stepCurrent === 1 ? 'button active' : 'button'}>1</button>
                    </div>
                    <div className="step step-2">
                      <button href="#step-2" type="button" onClick={() => selectStepByNavigation(2)} className={stepCurrent === 2 ? 'button active' : 'button'} disabled="disabled">2</button>
                    </div>
                    <div className="step">
                      <button href="#step-3" type="button" onClick={() => selectStepByNavigation(3)} className={stepCurrent === 3 ? 'button active' : 'button'} disabled="disabled">3</button>
                    </div>
                    <div className="step step-4">
                      <button href="#step-4" type="button" className={stepCurrent === 4 ? 'button active' : 'button'}>4</button>
                    </div>
                  </div>
                  <p className="infoNavigationSteps"><small>Navega entre as etapas após preenchidas.</small></p>
                </div>
              </div>
            </div>

            <div className="productCreate">

              <div className="displayInfoCreatedPublication mb-5 mt-2">
                {stepCurrent >= 3 && selectedFileData.name && (<span> <strong>Arquivo: </strong> {selectedFileData.name} </span>)}
                {stepCurrent >= 4 && nameAndLabels.name && nameAndLabels.labelAll && (
                  <>
                    <span> <strong>Nome da publicação: </strong> {nameAndLabels.name} </span>
                    <span> <strong>Label da publicação: </strong> {nameAndLabels.labelAll} </span>
                  </>
                )}
                {stepCurrent >= 4 && click && (<span> <strong>url de click: </strong> {click} </span>)}
              </div>

              { stepCurrent === 1 &&  
                <div className="stepContent" id="step-1"> 
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title titleDownload">
                        <h2>Primeiro faça o download do template do JSON</h2>
                      </div>
                    </div>
                  </div>

                  <div className="row" data-tour="step-createData1-01">

                    <div className="col-md-4">
                      <div className="contentDownload">
                        <div className="backgroundContentDownload">
                          <img className="img-fluid" src={mockupWhats} alt="" />
                          <Link 
                            to={`${pathBaseAssest}/chat_whatsapp_model.json`}
                            target="_blank" 
                            download
                            className="displayContentDownload"
                          >
                            <h3> Chat de Venda </h3>
                            <img src={download} alt="" />
                          </Link>
                        </div>
                      </div>     
                    </div>

                    <div className="col-md-4">
                      <div className="contentDownload">
                        <div className="backgroundContentDownload">
                          <img className="img-fluid" src={mockupWhats} alt="" />
                          <Link 
                            to={`${pathBaseAssest}/chat_deputado_data.json`}
                            target="_blank" 
                            download
                            className="displayContentDownload"
                          >
                            <h3>Chat Livre</h3>
                            <img src={download} alt="" />
                          </Link>
                        </div>
                      </div>     
                    </div>

                    <div className="col-md-4">
                      <div className="contentDownload">
                        <div className="backgroundContentDownload">
                          <img className="img-fluid" src={mockupWhats} alt="" />
                          <Link 
                            to={`${pathBaseAssest}/carrouselImagens.json`}
                            target="_blank" 
                            download
                            className="displayContentDownload"
                          >
                            <h3>Carrossel de Imagens </h3>
                            <img src={download} alt="" />
                          </Link>
                        </div>
                      </div>     
                    </div>
                    


                  </div>

            
                  <div className="row">
                    <div className="col-md-12">
                      <div className="btnNextPage">
                        <button href="#step-1" onClick={() => setStepCurrent(2)} data-tour="step-createData1-02">
                          Próximo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
              { stepCurrent === 2 &&
                <div className="contentStep" id="step-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title">
                        <h2>Faça upload do seu JSON</h2>  
                      </div>
                      </div>
                  </div> 
                  <div className="row">
                    <div className="col-md-12">
                        <div className="contentFile mb-5">
                          <label htmlFor="selection-file" data-tour="step-createData2-01">
                            <input id="selection-file" type="file" name="fileData" onChange={changeHandler} />
                            <img src={upload} alt="" />
                            {isSelected ? (
                              <p>Nome: {selectedFileData.name && selectedFileData.name} <br/> Tamanho em bytes: {selectedFileData.size && selectedFileData.size}</p>
                            ) : (
                              <span>Selecionar arquivo .JSON</span>
                            )}
                          </label>
                          <div className="btnNextPage">
                            <button href="#step-2" onClick={() => selectStep(3)} data-tour="step-createData2-02">
                              Próximo
                            </button>
                        </div>
                    </div>
                  </div>
                  </div>
                </div>
              }
              { stepCurrent === 3 &&
                <div className="contentStep" id="step-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title">
                        <h2>Detalhes da Publicação</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="productFields">
                        <div className="field" data-tour="step-createData3-01">
                        <label>Nome da Publicação</label>
                          <input id="namePublication" name="name" type="text" value={nameAndLabels.name} onChange={handleChangeNameAndLabels} 
                          />
                        </div>
                        <div className="field" data-tour="step-createData3-02">
                          <label>Tags</label>
                          <input id="labels" type="text" name="labelAll" value={nameAndLabels.labelAll} onChange={handleChangeNameAndLabels} 
                          />
                        </div>
                        <div className="field" data-tour="step-createData3-03">
                        <label className="labelDisplay">
                          Clique
                          <img data-tip="URL para redirecionamento após clique na publicação" src={help} alt="" />
                          < ReactTooltip className="customTooltip" / >
                        </label>
                          <input type="text" placeholder="Insira o link do clique" className="fieldClique" name="click" onChange={(e) => setClick(e.target.value.trim())}/>
                        </div>
                        <div className="btnNextPage">
                          <button href="#step-3" onClick={() => selectStep(4)} data-tour="step-createData3-04">
                            Próximo
                          </button> 
                        </div>
                    </div>
                  </div>
                  </div>
                </div>
              }
              { stepCurrent === 4 &&
                <div className="contentStep" id="step-4">

                  <div className="row">
                    <div className="col-md-12">
                    <div className="title">
                      <h2>Escolha a animação que mais combina com você</h2>
                    </div>
                    </div>
                  </div>


                  <div className="row">
                      <div className="col-md-12">
                        <div className="productAnimations">
                          <Splide data-tour="step-createData4-01" aria-label="My Favorite Images" options={ {
                            perPage: 3,
                            breakpoints: {
                              1070: {
                                perPage: 3,
                              },
                              920: {
                                perPage: 2,
                              },
                              840: {
                                perPage: 2,
                              },
                              518: {
                                perPage: 1,
                              }
                            },
                            perMove: 1,
                            rewind : true,
                            } }
                          >
                            <SplideSlide>
                              <div className="btnAnimation" onClick={() => clickAnimation('chat_whatsApp_data')} name="chat_whatsApp_data"  id="btnUpload">
                                <div className="animationImg">
                                  <img className="img-fluid imgWhats" src={whats} alt="" />
                                </div>
                                <h3 className="custom-title">Chat de venda por WhatsApp</h3>
                              </div>
                            </SplideSlide>

                            <SplideSlide>
                              <div className="btnAnimation" onClick={() => clickAnimation('chat_deputado_data')} name="chat_deputado_data"  id="btnUpload">
                                <div className="animationImg">
                                  <img className="img-fluid imgWhats" src={whats} alt="" />
                                </div>
                                <h3 className="custom-title">Chat livre por WhatsApp</h3>
                              </div>
                            </SplideSlide>

                            <SplideSlide>
                              <div className="btnAnimation" onClick={() => clickAnimation('carouselScenesData')} name="carouselScenesData"  id="btnUpload">
                                <div className="animationImg">
                                  <img className="img-fluid imgWhats" src={carousel} alt="" />
                                </div>
                                <h3 className="custom-title">Carousel de imagens</h3>
                              </div>
                            </SplideSlide>

                          </Splide>
                        </div>
                    </div>
                  </div>

                  <div className="row">
                      <div className="col-md-12">
                        <div className="uploadAudio" data-tour="step-createData4-03">
                          <div className="title">
                            <h2>Adicione um áudio<span>(opcional)</span></h2>
                          </div>
                              {audioBase64 ? (
                                <div className='contentFileAudio ms-5'>
                                    <label htmlFor="selection-file-audio">
                                      <audio id="audio" className='audioDisplay' autoPlay={false} controls>
                                          <source id="source" src={audioBase64} type="audio/ogg" />
                                      </audio>

                                      <span onClick={handleConfirmeRemoverAudio} className="btnAddAudio ms-2 me-1"> Remover</span>

                                      <span onClick={handleConfirmeAddAudio} className="btnAddAudio ms-2 me-1"> Adicionar</span>
                                  </label>
                                </div>
                              ) : (
                                  <div className="contentFileAudio">
                                      <label htmlFor="selection-file-audio">
                                        <input id="selection-file-audio" type="file" onChange={handlerChangeAudio}  />
                                        <img src={audio} alt="nota musical" />
                                        <span>Faça upload do seu áudio</span>
                                    </label>
                                  </div>
                                )
                              }
                        </div>
                      </div>
                    </div> 

                    <div className="row">
                      <div className="col-md-12">
                        <div className="productPreview">
                          <h2 className="mt-5">Preview</h2>
                          <div ref={previewRef} className="productPreviewVideo" data-tour="step-createData4-02">
                            {loading.preview ? (
                              <>
                                <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', margin: '20px 0px 20px 0px'}}>
                                    <Spinner animation="border" variant="secondary" />
                                </div>
                                <span style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    paddingLeft: '10px'
                                  }}> 
                                    Processando animação...
                                </span>
                                </>
                            ) : (<RenderPreviewHTML viewTemplate={viewTemplate} />)}
                          </div>
                        </div>
                      </div>
                    </div> 

                  <div className="row">
                    <div className="col-md-12">
                      <div  className="btnGenerateAnimation" data-tour="step-createData4-04">
                        {getTimeAnimationData ? 
                            <button onClick={handlerGereration}>
                              {loading.generation ? (
                                <span><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/> Criando...</span>
                              ) : ("Gerar Animação")}
                            </button>
                          : <button disabled style={{ opacity: 0.3, backgroundColor: '#000000' }} > Aguarde o término da animação </button>                   
                        }
                      </div>
                    </div>
                  </div>   

                  

                </div>
              }                    
            </div>
          </div>
        </div>
      </TourGuiadoCreateData>
    );
}

