  import React, { useEffect, useState, useLayoutEffect } from "react";
  import { Authenticator } from "@aws-amplify/ui-react";
  import { Amplify, Auth } from "aws-amplify";
  import CryptoJS from 'crypto-js';  
  import { useLocation, withRouter } from 'react-router-dom';
  import FormLogin from "../formLogin/FormLogin";
  import Spinner from 'react-bootstrap/Spinner';
  import { setStaticIsAuth, getStaticIsAuth } from '../../GlobalValiable';
  
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
      region: process.env.REACT_APP_REGION,
      identityPoolRegion: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL,
      userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT,
    }
  });
  
  function CustomAuthenticator({ children }) {
    const { pathname } = useLocation(); 
    const [loading, setLoading] = useState(false);
  
    const isAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        return true;
      } catch {
        return false;
      }
    }
    
    useLayoutEffect(() => {
  
       
      (async()=> {
        setLoading(true);
        const auth = await isAuth();
        await setStaticIsAuth(auth);
        setLoading(false);

      })();
      
      return () => {};
    },[])
  
    useEffect(() => { 
      async function automaticSignin_site_landing() {
        if (!(await isAuth()) &&  pathname.split('/')[1] === 'signin') {
          setLoading(true);
          try {
            const bytes = CryptoJS.AES.decrypt(pathname.substring(8), 'SECRET_CRYPTOJS_IMAGINEUP');
            const userObject = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            await Auth.signIn(userObject.email, userObject.password).then(() => {
              window.location.href = '/';
            })
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.error('error signing in', error);
          }
        }
      }
      automaticSignin_site_landing(); 
  
      return () => {
        setLoading(false);
      };
    // eslint-disable-next-line
    }, [pathname]) 
  
    return (
      <>
        {loading ?
          <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '25px'}}>
            <Spinner animation="border" variant="primary" />
          </div>
        :
          <>
            {getStaticIsAuth() === true ? (
              <Authenticator>
                {() => (
                  <>
                    {children}
                  </>
                  )
                }
              </Authenticator >
            ): 
              <FormLogin />
            }
          </>
        }
      </>
    );
  }
  
  export default withRouter(CustomAuthenticator);  