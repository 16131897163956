import React, { useState } from 'react';
import './whatsappOnboarding.css';
import logoImagineup from '../../assets/images/logo2-imagineUP.png';
import logoWhatsapp from '../../assets/images/logo-colorful-whatsapp.png'
import apiSocial from '../../config/api-social';

export default function WhatsappOnboarding() {

  const [form, setForm] = useState({
    identifier_name: null,
    identifier_account: null,
    identifier_phone: null,
    token: null
  })

  const [loadWhats, setLoadWhats] = useState(false)

  const createAccountWhatsapp = (e) => {
    e.preventDefault();

    const data = {
      name: String(form.identifier_name).trim(),
      identifier_account: String(form.identifier_account).trim(),
      identifier_phone: String(form.identifier_phone).trim(),
      token: String(form.token).trim()
    }
    
    if (
      data.name === '' ||
      data.identifier_account === '' ||
      data.identifier_phone === '' ||
      data.token === ''
    ) {
      console.error('Todos os campos são obrigátorios');
      throw new Error('All field required')
    }
    
    setLoadWhats(true)
    apiSocial.post('/api/whatsapp/create', data)
      .then(() => {
        window.close();
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        setLoadWhats(false)
      })
  }

  const onChange = (e) => {
    let { name, value } = e.target
    setForm((prev) => ({...prev, [name]: value}))
  }

  return (
    <div className="onboarding-whatsapp">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1>Preencha os dados para adicionar sua conta do WhatsApp
              <img src={logoWhatsapp} alt="" />
            </h1>
            <form action="" onSubmit={(e) => createAccountWhatsapp(e)} onChange={(e) => onChange(e)}>
                <label htmlFor="identifier_name">Nome do App</label>
                <input id='identifier_name' name='identifier_name' type="text" />
             
                <label htmlFor="identifier_account">Identificação da conta do WhatsApp Business</label>
                <input id='identifier_account' name='identifier_account' type="text" />
             
                <label htmlFor="identifier_phone">Identificação do número de telefone</label>
                <input id='identifier_phone' name='identifier_phone' type="text" />
             
                <label htmlFor="token">Token</label>
                <textarea id='token' name="token" cols="25" rows="5"></textarea>

                <div>
                  <button className="btn-send">
                    {loadWhats === true ? 'Enviando...' : 'Enviar' }
                  </button>
                  <button className="btn-cancel">Cancelar</button>
                </div>       
            </form>
          </div>
          <div className="col-md-6">
            <div className="content-right">
              <img src={logoImagineup} alt="" />
              <h2>Este aplicativo poderá:</h2>
              <ul>
                <li>Disparar mensagens</li>
                <li>Enviar catálogos de produtos</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


