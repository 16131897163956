import { useAuthenticator } from "@aws-amplify/ui-react";
import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import api from "../../config/api";
import './modalDestroyPublication.css';

export default function ModalDestroyPublication ({publication_id, show, setShow}) {

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleDestroyPublication = async () => {
    setLoading(true);
    try {
      await api.delete(`/publications/publication/${user.attributes.sub}/${publication_id}`);
      history.push(`/animation/list`);
    } catch (error) {
      console.log({error, msg: error.message});
      if (error.message === "Request failed with status code 400") {
        setErrorMessage('Você não tem autorização para excluir essa publicação.');
      } else {
        setErrorMessage('Error ao excluir essa publicação.');
      }
      setTimeout(() => setErrorMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="main-modal_center"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Deleta Publicação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>       

          {loading ? <>
            <div className="loadingDestroyPublication">
              <Spinner animation="grow" variant="secondary" /> 
            </div>
            <h4 className="textInfoDestroyPublication">Excluindo publicação...</h4>
          </>
          : <>

          <h4 className="textInfo">Você está prestes a excluir essa publicação.</h4>

          {errorMessage !== '' ? <div className="msg-delete-post-error">{errorMessage}</div> : <></>}

          <div className="btnsExcluirPublication">
            <Button variant="secondary" onClick={() => setShow(false)}>Fechar</Button>
            <Button variant="primary" onClick={() => handleDestroyPublication()}>Excluir</Button>
          </div>
          </>}
          
      </Modal.Body>
      </Modal>
    </>
  );
}