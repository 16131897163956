import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
    {
      selector: '[data-tour="step-schedule-publication-01"]',
      content: 'Veja como ficará a publicação.',
    },
    {
        selector: '[data-tour="step-schedule-publication-02"]',
        content: 'Escolha a data ou período para realização da postagem.',
    },
    {
        selector: '[data-tour="step-schedule-publication-03"]',
        content: 'Escolha o horário da postagem.',
    },
    {
        selector: '[data-tour="step-schedule-publication-04"]',
        content: 'Personalize a recorrência da postagem do jeito que preferir.',
    },
    {
        selector: '[data-tour="step-schedule-publication-05"]',
        content: 'Envie o agendamento para rede social.',
    },
    {
        selector: '[data-tour="step-schedule-publication-06"]',
        content: 'Veja as informações do agendamento.',
    },
   
    // .
    // ... 
  ]
  
  export const TourGuiadoSchedulePublication = function({children}) {
  
      const { setSteps, setCurrentStep } = useTour(false);
  
      useEffect(() => {
        (async () => {
            setCurrentStep(0);
            setSteps(steps);
         })()
  
        // eslint-disable-next-line 
      }, []);  
  
      return (
       <> 
        {children}
       </>
      )
  }