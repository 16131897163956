import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
  {
    selector: '[data-tour="step-support-01"]',
    content: 'Deseja relatar um problema? Preencha os campos e informe seu relato.',
  },
  {
    selector: '[data-tour="step-support-02"]',
    content: 'Informe alguns dados pessoais',
  },
  {
    selector: '[data-tour="step-support-03"]',
    content: 'Descreva sobre seu problema. Faça upload de um anexo sobre o problema(opcional). ',
  },
  {
    selector: '[data-tour="step-support-04"]',
    content: 'Envie para a equipe de suporte.',
  },
  // ... 
]

export const TourGuiadoSupport = function({children}) {

    const { setSteps, setCurrentStep } = useTour(false);

    useEffect(() => {
      (async () => {
          setCurrentStep(0);
          setSteps(steps);
       })()

      // eslint-disable-next-line 
    }, []);  

    return (
     <> 
      {children}
     </>
    )
}