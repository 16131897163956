import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import api from '../../config/api';
import "./tableChannelsResize.css";
import { downloadFiles } from '../../helpers/downloadFiles';

import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'react-bootstrap';

function TableChannelsResize({publication_id, show, setShow}) {
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    
    (async function() {
      try {
        setLoading(true);
        const result = (await api.get(`publications/findAllDataClick/${publication_id}`)).data;

        result.sort(function (a, b) {
          if (a.size > b.size) {
            return 1;
          }
          if (a.size < b.size) {
            return -1;
          }
          return 0;
        });
        
        setData(result);
      } catch (error) {
        console.error("Error: ", error);
      } finally {
        setLoading(false);
      }
    })();
  
    return () => {
      setData([]);
    }
  }, [publication_id])


  const handledownloadFiles = async function(url, event) {
    const element = event.target;
    const type = String(element.attributes.name.value);

    let field = element
    let valueOld = field.innerText;

    field.innerText = "aguarde...";

    await downloadFiles(url, type);

    field.innerText = `${valueOld}`;
}
  
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="main-modal_full"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
          Dados de Todas as Publicações
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>       
        {loading ?
            <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '25px', marginBottom: '15px'}}>
              <Spinner animation="grow" variant="secondary" /> 
            </div>
          : 
            <Table striped>
                  <thead>
                    <tr>
                      <th>Tamanho</th>
                      <th>prints</th>
                      <th>Link de print</th>
                      <th>download</th>
                      <th>Tipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                          <td>{item.size}</td>
                          <td>{item.prints}</td>
                          <td>{item.url_link}</td>
                          <td> <span className='tableLinkDownload' name={item.type} onClick={(event) => handledownloadFiles(item.url_download, event)}>download</span></td> 
                          <td>{item.type}</td>
                        </tr>
                      ))
                    }
                  </tbody>
            </Table> 
        }

      </Modal.Body>
    </Modal>
    </>
  );
}

export default TableChannelsResize;


