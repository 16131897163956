import React, { useEffect, useRef, useState } from 'react';
import api from '../../config/api';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useParams } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Link } from 'react-router-dom';
import { downloadFiles } from '../../helpers/downloadFiles';
import { TourGuiadoFormatsPublication } from './TourGuiadoFormatsPublication';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Spinner } from 'react-bootstrap';
import RenderPreviewHTML from '../../components/renderPreviewHTML/RenderPreviewHTML';
import ReactTooltip from 'react-tooltip';
import arrowBack from '../../assets/images/arrow_back.svg';
import help from '../../assets/images/help-grey.svg';
import copy from '../../assets/images/copiar.svg';
import logoFacebook from '../../assets/images/logo-facebook.png';
import facebookName from '../../assets/images/facebook-name.png';
import logoTiktok from '../../assets/images/logo-tiktok.png';
import tiktokName from '../../assets/images/tiktok-name.svg';
import logoInstagram from '../../assets/images/logo-instagram.png';
import instagramName from '../../assets/images/instagram-name.png';
import logoWhatsapp from '../../assets/images/logo-whatsapp.png';
import whatsappName from '../../assets/images/whatsapp-name.png';
import logoTwitter from '../../assets/images/logo-twitter.png';
import twitterName from '../../assets/images/twitter-name.png';
import { addUpperCasePrimaryChar } from '../../helpers/addUpperCasePrimaryChar';


export default function FormatsPublication() {
    const { user } = useAuthenticator((context) => [context.user]);
    
    const baseURLCLIQES = process.env.REACT_APP_CLIQES_URL;

    const endResizeRef = useRef(null);
    const loadingResizeRef = useRef(null);
    const params = useParams();
    const [loading, setLoading] = useState({
        global: false,
        previewResize: false,
        generationResize: false
    });
    const [publication, setPublication] = useState({document: {}, layers: []});
    const [dataListResizes, setDataListResizes] = useState([]);
    const [viewTemplateResize, setViewTemplateResize] = useState('');
    const [formatSocialNetwork, setFormatSocialNetwork] = useState('');
    const [resolutionsCreated, setResolutionsCreated] = useState([]);
    const [newPublicationResize, setNewPublicationResize] = useState({
        documentInfo: {},
        layerInfo: []
    })

    const [stepsGenerateNewFormat, setStepsGenerateNewFormat] = useState({
        html: '',
        video: ''
    })

    const handleClickCopy = (text) => {
        navigator.clipboard.writeText(text);
    }

    // refactor code....
    useEffect(() => {
        async function effectInitial() {
            setLoading((prevState) => ({ ...prevState, global: true }));
            
            try {
                const pub = (await api.get(`/publications/one/${user.attributes.sub}/${params.publication_id}`)).data
                const { documentInfo, layerInfo } = pub;

                setPublication({
                    document: documentInfo,
                    layers: layerInfo
                });

                const medias = (await api.get(`/medias/all/${params.publication_id}`)).data

                let resourceHTML = [];
                let resourceMP4 = [];
                let resolutionsCurrent = [];

                for (let index = 0; index < medias.length; index++) {
                    const media = medias[index];

                    if (media.extension === 'html') {
                        resourceHTML.push(media);
                        resolutionsCurrent.push(media.format_social_network);
                    }

                    if (media.extension === 'mp4') {
                        resourceMP4.push(media)
                    }                    
                }

                setResolutionsCreated(resolutionsCurrent); 

                let resource = resourceHTML.map((item,index) => {
                let video = resourceMP4.find((video) => video.format_social_network === item.format_social_network);
                
                if (video) {
                    let { shortened_hash: url_shortened_video, url_print: url_print_video } = video;
                    let obj = {
                        index,
                        doc_height: item.doc_height,
                        doc_width: item.doc_width,
                        url_shortened: `${baseURLCLIQES}/${item.shortened_hash}`,
                        url_print: item.url_print,
                        format_social_network: item.format_social_network,

                        url_shortened_video: `${baseURLCLIQES}/${url_shortened_video}`,
                        url_print_video: url_print_video.replace('_playerVideo.html', '.mp4')
                    }
                    return obj
                } else {
                    let obj = {
                        index,
                        doc_height: item.doc_height,
                        doc_width: item.doc_width,
                        url_shortened: `${baseURLCLIQES}/${item.shortened_hash}`,
                        url_print: item.url_print,
                        format_social_network: item.format_social_network,
                    }
                    return obj;
                }
            })
            
            setDataListResizes(resource);               

            } catch (error) {
             console.error('Error: ', error.message)   
            }
              
            setLoading((prevState) => ({ ...prevState, global: false }));
        }
        effectInitial();

        return () => {
            setLoading({
                global: false,
                previewResize: false,
                generationResize: false
            });
            setPublication({ document: {}, layers: [] });
            setDataListResizes([]);  
            setViewTemplateResize('');  
            setNewPublicationResize({documentInfo: {},layerInfo: []}); 
            setFormatSocialNetwork('');
            setResolutionsCreated([]);
        
        }
    // eslint-disable-next-line
    }, [user, params]);

    const erroResize = (error) => {
        const erroResize = document.querySelector('.erroResize');
        erroResize.hidden = false;
        erroResize.innerHTML = `${error}`;
        setTimeout(() => {
            erroResize.innerHTML = "";
            erroResize.hidden = true;
        }, 3000);
    }
  
    const handledownloadFiles = async function(url, type, event) {
        let time_download = '';
        if(type === 'html') time_download = 'downloadHtml';
        // eslint-disable-next-line
        else time_download = 'downloadVideo';

        let field = event.target
        let valueOld = field.innerText;

        field.innerText = "Realizando download...";

        downloadFiles(url, type)
            .then( () => console.info("Download concluido."))
            .catch(() => console.error("Error no download."))
            .finally(() => field.innerText = `${valueOld}`)
    }
    
    const handleResizePublication = async ({w, h, format}) => {
        setViewTemplateResize('');
        setFormatSocialNetwork(format);
        const width = Number(w);
        const height = Number(h); 

        window.scrollTo({ behavior: 'smooth', top: loadingResizeRef.current.offsetTop });
        setLoading((prevState) => ({ ...prevState, previewResize: true }));
    
        const valueResizeHeight = height / publication.document.doc_height;
        const valueResizeWidth = width / publication.document.doc_width;
    
        const data = {
            documentInfo: {
            ...publication.document,
            doc_width: width,
            doc_height: height
        },
            layerInfo: publication.layers,
            valueResizeWidth,
            valueResizeHeight
        }

        api.post(`/medias/canalMedia`, data)
            .then( async (result) => {   
                
                const documentInfo = {
                    ...publication.document,
                    doc_width: width,
                    doc_height: height
                };
                const layerInfo = [[...result.data.metadataLayers]];

                const newResizePublication = {
                    documentInfo: documentInfo, 
                    layerInfo: layerInfo,
                    type: 'update'
                }

                setNewPublicationResize(newResizePublication);

                try {
                    await api.post(`publications/render/${documentInfo.effect}`, newResizePublication)
                        .then(template => setViewTemplateResize(template.data))
                } catch (error) {
                    console.error("Erro na renderização: ", error);
                    erroResize("Erro na renderização!");
                } finally {
                    setLoading((prevState) => ({ ...prevState, previewResize: false }));
                }

            })
        .catch(function (error) {
            console.error("Erro no redimencionamento: ", error);
            erroResize("Erro no redimencionamento!");
            setLoading((prevState) => ({ ...prevState, previewResize: false }));
        });

    }

    const handleSubmitNewResize = async () => {
        if(!(!!formatSocialNetwork)) {
            erroResize("Antes escolha uma formato.");
            return {};
        }   
        
        let isResolution = resolutionsCreated.filter(i => i === formatSocialNetwork);

        if(isResolution.length !== 0) {
            erroResize("Você já possui essa formato.");
            return {};
        }

        const dataCreateNewResize = {
            publication_id: newPublicationResize.documentInfo.id,
            template: viewTemplateResize,
            valueResizeWidth: newPublicationResize.documentInfo.doc_width,
            valueResizeHeight: newPublicationResize.documentInfo.doc_height,
            format_social_network: formatSocialNetwork,
            url_audio: newPublicationResize.documentInfo.url_audio || null
        };

        let timeAnimation = Number(
            publication.document.time_animation || 
            document.querySelector(".document").id.split("_")[1]
        );

        const oldDataListResizes = dataListResizes;

        try {
            //call gerando html
            setLoading((prevState) => ({ ...prevState, generationResize: true }));
            const result_html = await api.post(`medias/createCanalMedia/${user.attributes.sub}`, dataCreateNewResize)
                .then(res => {
                    setStepsGenerateNewFormat((prev) => ({...prev, html: 'success'}));
                    return res.data;
                })
                .catch(e => {
                    console.error("error ao gerar o html");
                    setStepsGenerateNewFormat((prev) => ({...prev, html: 'error'}));
                    throw new Error('Opsss... Erro na geração do html');
                })           
               
            let size = result_html.new_resize_name.split('_')[1];
           
            //call gerando video
            await api.post(`/medias/convertAndCreateToMp4/${user.attributes.sub}`, {
                publication_id: publication.document.id,
                nameS3: `${publication.document.id}_${size}`,
                duration: (timeAnimation),
                preview: false,
                format_social_network: formatSocialNetwork,
                url_audio: publication.document.url_audio,
                thumbnail: publication.document.thumbnail
            }).then((result_video) => {
               
                setStepsGenerateNewFormat((prev) => ({...prev, video: 'success'}));
                
                let { url_long, hash_video } = result_video.data;       
    
                oldDataListResizes.push({
                    index: oldDataListResizes.length,
                    doc_width: size.split('x')[0],
                    doc_height: size.split('x')[1],
                    url_shortened: `${baseURLCLIQES}/${result_html.shortened_hash}`,
                    url_print: result_html.url_print,
                    format_social_network: formatSocialNetwork,
                    url_shortened_video: `${baseURLCLIQES}/${hash_video}`,
                    url_print_video: url_long,      
                })
            })
            .catch(err => {
                console.error("error ao gerar video");
                setStepsGenerateNewFormat((prev) => ({...prev, video: 'error'}));
                oldDataListResizes.push({
                    index: oldDataListResizes.length,
                    doc_width: size.split('x')[0],
                    doc_height: size.split('x')[1],
                    url_shortened: `${baseURLCLIQES}/${result_html.shortened_hash}`,
                    url_print: result_html.url_print,
                    format_social_network: formatSocialNetwork    
                });
                throw new Error('Opsss... Erro na geração do video');
            })

            setTimeout(() => {
                window.scrollTo({ behavior: 'smooth', top: endResizeRef.current.offsetTop }); 
                setViewTemplateResize('');
                setFormatSocialNetwork('');
            }, 2000);
        
        } catch (error) {
            console.error("Error create new resize: ", error);
            erroResize(error.message);
        } finally {
            setDataListResizes(oldDataListResizes);
            setTimeout(() => {
                setLoading((prevState) => ({ ...prevState, generationResize: false }));
                setStepsGenerateNewFormat({video: '', html: ''})
            }, 1500);
        }
    }       

    const generateMp4 = async (event) => {
        event.preventDefault();

        let elem = event.target;
        let format = elem.name;
        let size = elem.id.split('_')[0];

        if(elem === null || !format) {
            console.log("Elemento não existe.", {elem, format});
            return {};
        }
       
        let timeAnimation = Number(
            publication.document.time_animation || 
            document.querySelector(".document").id.split("_")[1]
        );

        if (timeAnimation && format && size) {
            if (elem) elem.innerText = `Criando seu MP4 ${size}...`;

            await api.post(`/medias/convertAndCreateToMp4/${user.attributes.sub}`, {
                publication_id: publication.document.id,
                nameS3: `${publication.document.id}_${size}`,
                duration: (timeAnimation),
                preview: false,
                format_social_network: format
            }).then((response) => {
                let { url_long, hash_video, size } = response.data;
                let [ doc_width, doc_height ] = size.split('x');

                const oldDataListResizes = dataListResizes;
                
                const newDataListResizes = oldDataListResizes.map((item) => {
                    let width = Number(item.doc_width);
                    let height = Number(item.doc_height);

                    if (Number(doc_width) === width && Number(doc_height) === height) {
                        return {
                            ...item,
                            url_shortened_video: `${baseURLCLIQES}/${hash_video}`,
                            url_print_video: url_long,
                        };
                    } else {
                        return item;
                    }
                })
    
                setDataListResizes(newDataListResizes);

            }).catch((err) => {
                console.error("Error", err );
            }).finally(() => {
                if (elem) elem.innerText = `Gerar Banner em Video`;
            })
        } else {
            console.info("Tente novamento ao finalizar a animação do preview");
        }

    }

    return (
        <TourGuiadoFormatsPublication>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="pageBookmark">
                        <p>Minhas Publicações / Detalhes da Publicação / </p>
                        <span>Formatos para Redes Sociais</span>
                    </div>
                </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="topBackPage">
                  <Link key="" to={`/animation/details/${params.publication_id}`} className="link">
                    <button>
                      <img src={arrowBack} alt="seta" />
                        Cancelar
                    </button>
                  </Link>
                </div>  
              </div>
            </div>
            <section className="selectFormats">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title">
                            <h2 className="titleSelectFormats">Escolha os formatos que deseja usar</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="formatsCarousel" data-tour="step-formats-publication-01">
                            {loading.global ?  
                                <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '25px'}}>
                                    <Spinner animation="border" variant="secondary" /> 
                                </div>
                                : (
                                    <Splide options={{
                                        perPage: 4,
                                        breakpoints: {
                                            1245: {
                                              perPage: 3,
                                            },
                                            990: {
                                              perPage: 2,
                                            },
                                            590: {
                                              perPage: 1,
                                            }
                                        }, 
                                        perMove: 1,
                                        rewind : true,
                                    }}> 
                                        <SplideSlide onClick={() => handleResizePublication({w: 1080, h: 1080, format: 'facebook-feed-quadrado'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail square" src={publication.document.thumbnail}  alt="exemple thumbnail"/>
                                                <div className="formatInformation">
                                                    <div className="formatLogo">
                                                        <img className="logo" src={logoFacebook} alt="logo rede social"/>
                                                        <img className="name-facebook"src={facebookName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Feed Quadrado</span>
                                                    <span>1080x1080</span>
                                                </div>
                                            </div> 
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1080, h: 1350, format: 'facebook-feed-vertical'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail vertical" src={publication.document.thumbnail}  alt="exemple thumbnail"/>
                                                <div className="formatInformation">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoFacebook} alt="logo rede social"/>
                                                        <img className="name-facebook" src={facebookName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Feed Vertical</span>
                                                    <span>1080x1350</span>
                                                </div>
                                            </div> 
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1200, h: 630, format: 'facebook-feed-horizontal'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail facebook-horizontal" src={publication.document.thumbnail}  alt="exemple thumbnail"/>
                                                <div className="formatInformation facebook-feed-horizontal">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoFacebook} alt="logo rede social"/>
                                                        <img className="name-facebook" src={facebookName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Feed Horizontal</span>
                                                    <span>1200x630</span>
                                                </div>
                                            </div> 
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1080, h: 1920, format: 'facebook-reels'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail high" src={publication.document.thumbnail}  alt="exemple thumbnail"/>
                                                <div className="formatInformation">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoFacebook} alt="logo rede social"/>
                                                        <img className="name-facebook" src={facebookName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Reels</span>
                                                    <span>1080x1920</span>
                                                </div>
                                            </div> 
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1080, h: 1920, format: 'tiktok-feed'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail high" src={publication.document.thumbnail}  alt="exemple thumbnail"/>
                                                <div className="formatInformation">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoTiktok} alt="logo rede social"/>
                                                        <img className="name-tiktok" src={tiktokName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Feed</span>
                                                    <span>1080x1920</span>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1080, h: 1080, format: 'instagram-feed-quadrado'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail square" src={publication.document.thumbnail} alt="exemple thumbnail" />
                                                <div className="formatInformation">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoInstagram} alt="logo rede social"/>
                                                        <img className="name-instagram" src={instagramName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Feed Quadrado</span>
                                                    <span>1080x1080</span>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1080, h: 566, format: 'instagram-feed-horizontal'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail instagram-horizontal" src={publication.document.thumbnail}  alt="exemple thumbnail"/>
                                                <div className="formatInformation">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoInstagram} alt="logo rede social"/>
                                                        <img className="name-instagram" src={instagramName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Feed Horizontal</span>
                                                    <span>1080x566</span>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1080, h: 1350, format: 'instagram-feed-vertical'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail vertical" src={publication.document.thumbnail} alt="exemple thumbnail"/>
                                                <div className="formatInformation">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoInstagram} alt="logo rede social"/>
                                                        <img className="name-instagram" src={instagramName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Feed Vertical</span>
                                                    <span>1080x1350</span>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1080, h: 1920, format: 'instagram-reels'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail high" src={publication.document.thumbnail} alt="exemple thumbnail"/>
                                                <div className="formatInformation">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoInstagram} alt="logo rede social"/>
                                                        <img className="name-instagram" src={instagramName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Reels</span>
                                                    <span>1080x1920</span>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1200, h: 1200, format: 'twitter-feed-quadrado'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail square" src={publication.document.thumbnail} alt="exemple thumbnail"/>
                                                <div className="formatInformation">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoTwitter} alt="logo rede social"/>
                                                        <img className="name-twitter" src={twitterName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Feed Quadrado</span>
                                                    <span>1200x1200</span>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1600, h: 900, format: 'twitter-feed-horizontal'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail twitter-horizontal" src={publication.document.thumbnail} alt="exemple thumbnail"/>
                                                <div className="formatInformation">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoTwitter} alt="logo rede social"/>
                                                        <img className="name-twitter" src={twitterName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>Feed Horizontal</span>
                                                    <span>1600x900</span>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                        <SplideSlide onClick={() => handleResizePublication({w: 1200, h: 800, format: 'whatsapp'})}>
                                            <div className="formatCard">
                                                <img className="thumbnail square" src={publication.document.thumbnail} alt="exemple thumbnail"/>
                                                <div className="formatInformation">
                                                     <div className="formatLogo">
                                                        <img className="logo" src={logoWhatsapp} alt="logo rede social"/>
                                                        <img className="name-instagram" src={whatsappName} alt="nome da rede social"/>
                                                    </div>
                                                    <span>1200x800</span>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                    </Splide>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className="confirmFormats mt-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title">
                            <h2 className="titleGridFormats">Preview do formato</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="preview mb-4" ref={loadingResizeRef} data-tour="step-formats-publication-02">
                            {loading.previewResize && <Spinner animation="grow" variant="secondary" /> }
                            <RenderPreviewHTML viewTemplate={viewTemplateResize} />
                        </div>
                    </div>
                </div>

                <div className='erroResize' hidden={true}> </div>
                
                {loading.generationResize && <>
                    <div className="centralDisplay" >
                        <Spinner style={{marginTop: '5px'}} animation="border" variant="secondary" /> 
                    </div>     
                    <div className="centralDisplay" >
                        <div>
                            <p className='generationResize_html'> <CheckCircleIcon size={'md'} color={stepsGenerateNewFormat.html} /> Gerando novo formado em html...</p>
                            <p className='generationResize_video'> <CheckCircleIcon size={'md'} color={stepsGenerateNewFormat.video} /> Gerando novo formado em video...</p>
                        </div>
                    </div>
                </>}

                <div className="row">
                    <div className="col-md-12">
                        <div className="btnConfirm">
                            <button onClick={handleSubmitNewResize} data-tour="step-formats-publication-03">
                                {loading.generationResize 
                                    ? <>Aguarde...</>
                                    : <>Criar Formato</>
                                }
                            </button>
                        </div>
                    </div>
                </div>

            </section> 
            
            <section className="linkFormats" ref={endResizeRef} data-tour="step-formats-publication-04">
                {dataListResizes && dataListResizes.map((item, index) => {
                    let size = `${item.doc_width}x${item.doc_height}`;
                    return (
                    <div className="row" key={index}>
                        <div className="col-md-12">
                            <div className="section">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="fieldDetails">
                                            <label className="labelDisplay">
                                                
                                                Print: {addUpperCasePrimaryChar(item.format_social_network, '-')} {size} 
                                                <img data-tip="URL da publicação" src={help} alt="" />
                                                < ReactTooltip className="customTooltip" />
                                            </label>
                                            <div className="displayInput copy">
                                                <input type="text" name="link-click" value={`${item.url_shortened}`} disabled/>
                                    
                                                <div onClick={() => handleClickCopy(`${item.url_shortened}`)}>
                                                    <img src={copy} alt='img-icon-copy'/>
                                                    <span>Copiar</span>
                                                </div>
                                                
                                            </div>
                                            <div className="downloadFiles" name={size} onClick={(event)=> handledownloadFiles(item.url_print,'html', event)} data-tour="step-formats-publication-07">
                      
                                                <span id='downloadHtml' name={`download_${size}`}> <CloudDownloadIcon fontSize="small" /> Download do html</span>
   
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        {item.url_shortened_video ?
                                            <div className="fieldDetails">
                                                <label className="labelDisplay">
                                                    Vídeo: {addUpperCasePrimaryChar(item.format_social_network, '-')} ({size})
                                                    <img data-tip="URL da publicação" src={help} alt="" />
                                                    < ReactTooltip className="customTooltip" />
                                                </label>
                                                <div className="displayInput copy">
                                                    <input type="text" name="link-click" value={item.url_shortened_video} disabled/>
                                        
                                                    <div onClick={() => handleClickCopy(`${item.url_shortened_video}`)}>
                                                        <img src={copy} alt='img-icon-copy'/> 
                                                        <span>Copiar</span>
                                                    </div>
                                                </div>
                                                <div className="downloadFiles" name={size} onClick={(event)=> handledownloadFiles(item.url_print_video, 'mp4', event)} data-tour="step-formats-publication-06">
                                                    <span id='downloadHtml' name={`download_${size}`}> <CloudDownloadIcon fontSize="small" /> Download do Video</span>
                                                    {/* <span id={`${size}_loading`} name={`${item.format_social_network}`} onClick={(e) => generateMp4(e)}> Gerar Outro Video</span> */}
                                                </div> 
                                            </div>
                                            :
                                            <div className="btnGenerateVideo">
                                                <button id={`${size}_loading`} name={`${item.format_social_network}`} onClick={(e) => generateMp4(e)}  data-tour="step-formats-publication-05">                           
                                                    Gerar Banner em Vídeo
                                                </button>
                                            </div> 
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )})}

                <div className="row">
                    <div className="col-md-12">
                        <div className="btnConfirm">
                            <Link to={`/socialMediaPublication/${params.type_publication}/${params.publication_id}`}>
                                <button data-tour="step-formats-publication-08">
                                    Quero Publicar
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                
            </section>
        </div>
        </TourGuiadoFormatsPublication>
    )
}