import { useTour } from '@reactour/tour';
import { useEffect } from 'react';

const steps = [
    {
        selector: '[data-tour="step-list-social-media-publication-01"]',
        content: 'Aqui ficam listadas todas as publicações que foram realizadas no Facebook, Instagram e Tiktok.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-02"]',
        content: 'Aqui temos os filtros que facilitam a busca das publicações.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-03"]',
        content: 'Este filtro faz a busca por rede social, mostrando apenas as publicações realizadas em uma determinada. Ele ainda possibilita filtrar por canais, como Feed, Story e Reel.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-04"]',
        content: 'Este filtra as publicações que foram realizadas em um determinado período ou em uma data específica. Filtre usando as sugestões de período ou de forma personalizada escolhendo a data que preferir.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-05"]',
        content: 'Aqui cada linha da tabela é uma publicação com suas informações.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-06"]',
        content: 'Nesta coluna ficará o botão para visualizar a publicação com alguns botões de ações. Clique agora e veja!',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-07"]',
        content: 'Clique agora e veja!',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-08"]',
        content: 'Visualização da publicação.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-09"]',
        content: 'Opção para publicar de imediato a mesma publicação, na mesma rede social e canal.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-10"]',
        content: 'Opção para agendar uma data e horário para a postagem ser realizada.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-11]',
        content: 'Opção para excluir permanentemente a publicação do Feed da rede social.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-12"]',
        content: 'Nesta ficará a data e horário que foi realizada a publicação.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-13"]',
        content: 'Aqui irá informar se a publicação foi postada de modo imediato ou se foi agendada.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-14"]',
        content: 'Aqui aparecerá a quantidade de vezes que publicação foi vista.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-15"]',
        content: 'Aqui aparecerá a quantidade de contas que a publicação alcançou.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-16"]',
        content: 'E aqui a quantidade de interações que a publicação teve, como reações, comentários, compartilhamentos e cliques.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-17"]',
        content: 'Aqui ficam listadas todas as publicações que estão agendadas. Clique agora e veja!',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-18"]',
        content: 'Aqui você pode filtrar os agendamentos por rede social e canais.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-19"]',
        content: 'E aqui por data ou período que está agendado.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-20"]',
        content: 'Nesta coluna ficará o botão para visualizar a publicação e alguns botões de ações.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-21"]',
        content: 'Clique agora e veja!',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-22"]',
        content: 'Visualização da publicação.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-23"]',
        content: 'Opção para publicar imediatamente e cancelar o agendamento.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-24"]',
        content: 'Opção para alterar data e horário do agendamento.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-25"]',
        content: 'Opção para excluir permanentemente o agendamento.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-26"]',
        content: 'Aqui aparecerá data e hora do agendamento.',
    },
    {
        selector: '[data-tour="step-list-social-media-publication-27"]',
        content: 'E aqui a recorrência da publicação.',
    },
   
    // .
    // ... 
  ]
  
  export const TourGuiadoListSocialMediaPublication = function({children}) {
  
      const { setSteps, setCurrentStep } = useTour(false);
  
      useEffect(() => {
        (async () => {
            setCurrentStep(0);
            setSteps(steps);
         })()
  
        // eslint-disable-next-line 
      }, []);  
  
      return (
       <> 
        {children}
       </>
      )
  }