import './listSocialMedia.css';
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table'
import logoFacebook from '../../assets/images/logo-colorful-facebook.png';
import logoTiktok from '../../assets/images/logo-colorful-tiktok.png';
import logoInstagram from '../../assets/images/logo-colorful-instagram.png';
import logoWhatsApp from '../../assets/images/logo-colorful-whatsapp.png';
import { memo } from 'react';
import apiSocial from '../../config/api-social';
import { Spinner } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Button from 'react-bootstrap/Button';
import Calendar from 'react-calendar';
import ReactTooltip from 'react-tooltip';
import arrowCollapse from '../../assets/images/seta-collapse.svg';
import help2 from '../../assets/images/help.svg';
import { addUpperCasePrimaryChar } from '../../helpers/addUpperCasePrimaryChar';
import ModalPreviewPosts from './ModalPreviewPosts';

function ListSocialmediaPosts() {

    const [posts, setPosts] = useState([]);
    const [postsFilterNetwork, setPostsFilterNetwork] = useState([]);
    const [loadingList, setLoadingList] = useState(false);
    const [socialNetworkFilter, setSocialNetworkFilter] = useState("Todos");
    const [periodFilter, setPeriodFilter] = useState("Todos");
    const [painelPreview, setPainelPreview] = useState({});

    const handleChangePeriodFilter = (e) => {
        e.preventDefault();
        let { innerText } = e.target;
        setPeriodFilter(innerText);
    }

    const togglePreviewPublication = (className, post) => {
        setPainelPreview(post);
        const element = document.querySelector('.' + className);
        element?.classList.remove('close-preview');
        element?.classList.toggle('open');
    }

    const logos = {
        facebook: logoFacebook,
        tiktok: logoTiktok,
        instagram: logoInstagram,
        whatsapp: logoWhatsApp
    }

    const toggleCalendar = className => {
        const element = document.querySelector('.' + className);
        element?.classList.remove('close-calendar');
        element?.classList.toggle('open');
    }

    const addZeroBefore = (n) => ((n < 10 ? '0' : '') + n);

    useEffect(() => {
        setLoadingList(true);
        apiSocial.get('/api/posts').then(({ data }) => {

            const auxData = [];

            for (const index in data) {
                const iterator = data[index];
                if (iterator.media_url.includes('/thumbnail_')) {
                    iterator.thumbnail = iterator.media_url
                    auxData.push(iterator);
                } else {
                    const arr = iterator.media_url.split('/');
                    const [id_pub, rest] = (arr[arr.length - 1]).split('_');
                    const thumbnail = iterator.media_url.replace(`/${id_pub}_${rest}`, `/thumbnail_${id_pub}.png`);
                    iterator.thumbnail = thumbnail;
                    auxData.push(iterator);
                }
            }
            setPosts(auxData);
            setPostsFilterNetwork(auxData);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoadingList(false);
        })

        return () => {
            setPosts([]);
            setPostsFilterNetwork([]);
            setLoadingList(false);
            setSocialNetworkFilter('Todos');
            setPeriodFilter('Todos');
            setPainelPreview({});
        }
    }, [])

    const handleChangeNetworkFilter = (e) => {
        e.preventDefault();
        let { innerText } = e.target;
        setSocialNetworkFilter(innerText)
        const postsFilter = handleSocialNetworkFilter(innerText)
        setPostsFilterNetwork(postsFilter);
    }

    const handleSocialNetworkFilter = (network) => {
        let postsFilter = [];

        if (network === "Todos") {
            postsFilter = posts;
        } else {
            let auxPosts = posts;
            postsFilter = auxPosts.filter(post => post.type === network.toLowerCase());
        }

        return postsFilter
    }

    const handleSocialNetworkChannelFilter = (filter) => {
        const [network, channel] = filter.split('-');
        const networkFilter = handleSocialNetworkFilter(network);

        let auxPosts = networkFilter;
        let postsFilter = auxPosts.filter(post => post.channel === channel);
        setSocialNetworkFilter(`${addUpperCasePrimaryChar(filter, '-')}`)
        setPostsFilterNetwork(postsFilter);
    }

    return (
        <div className="listPosts">
            <div className="row">
                <div className="col-md-12">
                    <div className="filters" data-tour="step-list-social-media-publication-02">
                        <Dropdown className="socialNetwork" data-tour="step-list-social-media-publication-03">
                            <Dropdown.Toggle className="toggle-primary">
                                <div>
                                    Rede Social:
                                    <span> {socialNetworkFilter} </span>
                                </div>
                                <img src={arrowCollapse} alt="seta" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => handleChangeNetworkFilter(e)}>
                                    Todos
                                </Dropdown.Item>
                                <Dropdown className="dropdown-secondary">
                                    <div className="displayToggle">
                                        <Dropdown.Item onClick={(e) => handleChangeNetworkFilter(e)}>
                                            <Button>
                                                <img src={logoFacebook} alt="logotipo colorido facebook" />
                                                Facebook
                                            </Button>
                                        </Dropdown.Item>
                                        <Dropdown.Toggle>
                                            <img src={arrowCollapse} alt="seta" />
                                        </Dropdown.Toggle>
                                    </div>
                                    <Dropdown.Menu onClick={(e) => handleSocialNetworkChannelFilter(e.target.name)}>
                                        <Dropdown.Item name="facebook-feed">Feed</Dropdown.Item>
                                        <Dropdown.Item name="facebook-reels">Reel</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown className="dropdown-secondary">
                                    <div className="displayToggle">
                                        <Dropdown.Item onClick={(e) => handleChangeNetworkFilter(e)}>
                                            <Button>
                                                <img src={logoInstagram} alt="logotipo colorido instagram" />
                                                Instagram
                                            </Button>
                                        </Dropdown.Item>
                                        <Dropdown.Toggle>
                                            <img src={arrowCollapse} alt="seta" />
                                        </Dropdown.Toggle>
                                    </div>

                                    <Dropdown.Menu onClick={(e) => handleSocialNetworkChannelFilter(e.target.name)}>
                                        <Dropdown.Item name="instagram-feed">Feed</Dropdown.Item>
                                        <Dropdown.Item name="instagram-reels">Reels</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown className="dropdown-secondary">
                                    <div className="displayToggle">
                                        <Dropdown.Item onClick={(e) => handleChangeNetworkFilter(e)}>
                                            <Button>
                                                <img src={logoTiktok} alt="logotipo colorido tiktok" />
                                                TikTok
                                            </Button>
                                        </Dropdown.Item>
                                        <Dropdown.Toggle split id="dropdown-split-basic">
                                            <img src={arrowCollapse} alt="seta" />
                                        </Dropdown.Toggle>
                                    </div>
                                    <Dropdown.Menu onClick={(e) => handleSocialNetworkChannelFilter(e.target.name)}>
                                        <Dropdown.Item name="tiktok-feed" >Feed</Dropdown.Item>
                                        <Dropdown.Item name="tiktok-stories" >Story</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className="date" data-tour="step-list-social-media-publication-04">
                            <Dropdown.Toggle className="toggle-primary">
                                <div>
                                    Período:
                                    <span>{periodFilter}</span>
                                </div>
                                <img src={arrowCollapse} alt="seta" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div>
                                    <DropdownItem onClick={(e) => handleChangePeriodFilter(e)}>
                                        Todos
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleChangePeriodFilter(e)}>
                                        Últimos 7 dias
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleChangePeriodFilter(e)}>
                                        Últimos 14 dias
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleChangePeriodFilter(e)}>
                                        Este mês
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => handleChangePeriodFilter(e)}>
                                        Mês passado
                                    </DropdownItem>
                                    <button className="btn-personalized-date" onClick={() => toggleCalendar('preview-calendar')}>
                                        Personalizado
                                    </button>
                                </div>
                                <div className="calendar-filter preview-calendar">
                                    <Calendar
                                        className="custom-calendar"
                                        returnValue={'range'}
                                        selectRange={true}
                                        locale={'pt-BR'}
                                    />
                                    <p>Selecione uma data ou um período.</p>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Table striped responsive="lg" data-tour="step-list-social-media-publication-05">
                        <thead>
                            < ReactTooltip className="customTooltip" />
                            <tr>
                                <th data-tour="step-list-social-media-publication-06">Publicação</th>
                                <th data-tour="step-list-social-media-publication-12">Data / Hora da publicação</th>
                                <th data-tour="step-list-social-media-publication-13">Modo de publicação</th>
                                <th data-tour="step-list-social-media-publication-14">
                                    Impressões
                                    <img data-tip="A métrica de IMPRESSÃO mostra quantas vezes a publicação foi visualizada." src={help2} alt="ponto de interrogação" />
                                </th>
                                <th data-tour="step-list-social-media-publication-15">
                                    Alcance
                                    <img data-tip="A métrica de ALCANCE mostra o número de usuários que viram a publicação pelo menos uma vez, ou seja, quantas contas foram alcançadas." src={help2} alt="ponto de interrogação" />
                                </th>
                                <th data-tour="step-list-social-media-publication-16">
                                    Engajamento
                                    <img data-tip="O ENJAMENTO mostra o número de reações, comentários, compartilhamentos e cliques na publicação." src={help2} alt="ponto de interrogação" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loadingList && postsFilterNetwork.map((post, index) => {
                                const logo = logos[post.type];
                                const date = new Date(post.created_at);
                                let date_pubs = `${addZeroBefore(date.getDate())}-${addZeroBefore(date.getMonth())}-${date.getFullYear()}  `;
                                date_pubs += `às ${addZeroBefore(date.getHours())}:${addZeroBefore(date.getMinutes())}`;

                                return (
                                    <tr key={index}>
                                        <td>
                                            <button onClick={() => togglePreviewPublication('preview-publication', post)}>
                                                <img src={logo} alt={"logo rede social " + post.type} />
                                                {addUpperCasePrimaryChar((String(post.channel)))}
                                            </button>
                                        </td>
                                        <td>
                                            {date_pubs}
                                        </td>
                                        <td>{post.schedulingMode}</td>
                                        <td>{post.engagement?.impressions !== undefined ? post.engagement.impressions : '-'}</td>
                                        <td>{post.engagement?.reach !== undefined ? post.engagement.reach : '-'}</td>
                                        <td>{post.engagement?.engagement !== undefined ? post.engagement.engagement : '-'}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    {loadingList &&
                        <div className="loadingSpiner-listSocialMedia">
                            <Spinner animation="grow" style={{ marginBottom: '10px' }} variant="secondary" />
                        </div>
                    }
                    <ModalPreviewPosts
                        post={painelPreview}
                        postsFilterNetwork={postsFilterNetwork}
                        setPostsFilterNetwork={setPostsFilterNetwork}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(ListSocialmediaPosts);