import './listPublication.css';
import React, { useEffect, useState } from 'react';
import api from '../../config/api';
import { Link } from 'react-router-dom';
import Card from '../../components/card/Card';
import signAdd from '../../assets/images/sinal-mais-medium.svg'
import { Spinner } from 'react-bootstrap';
import { TourGuiadoList } from './TourGuiadoList';

export default function ListPublication() {

    const [publications, setPublications] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
   
        setLoading(true);

        api.get('publications/listPublication')
        .then((pubs) => {
            setPublications(pubs.data);
            setLoading(false);
        })
        .catch((err) => {
            console.error("ERROR: ", err);
        });  

        
        return () => {
            setPublications([]);
            setLoading(false);
        };

    }, []);

    return (
        <TourGuiadoList loading={loading}>
            <div className="listPublication">
                <div className="container" >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pageBookmark">
                                <p>Início /</p>
                                <span>Minhas Publicações</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="createPublication">
                                <span>Criar nova publicação</span>
                                <Link to="/typePublication"  id='redirect-upload-link'>
                                    <button>
                                        <img src={signAdd} alt="sinal de adicionar" />
                                    </button>
                                </Link>
                            </div>    
                        </div>
                    </div>
                    
                    <section data-tour="step-listPublication-01">
                        <div className="row" >
                            <div className="col-md-12">
                                <div className="featuredTitle titleListPublication">
                                    <h2>Publicações</h2>
                                    <div className="rowTitle3"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            {publications.map((item, index) => (
                                <div key={index} className="col-md-3">
                                    <Card banner={item} />
                                </div>
                            ))}
                        </div>
                   </section>

                    {loading && (
                        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '25px'}}>
                            <Spinner animation="border" variant="secondary" /> 
                        </div>
                    )}

                </div>
            </div>           
        </TourGuiadoList>
    )
}