import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link, useLocation, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import api from '../../config/api';
import "./changeWorkspace.css";
import { getAccessTRoken, setPrimaryTourGuiado } from '../../GlobalValiable';
import trash from '../../assets/images/trash-outline.svg'
import apiSocial from '../../config/api-social';
import arrowBack from '../../assets/images/arrow_back.svg';
import copy from '../../assets/images/copiar.svg';

export default function SwitchWorkspace() {
    const [createWorkspace, setCreateWorkspace] = useState(false);
    const [nameTenant, setNameTenant] = useState('');
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false);
    const [allTenants, setAllTenants] = useState([]);
    const [currentTenant, setCurrentTenant] = useState(localStorage.getItem('currentTenant'));
    const [editTenant, setEditTenant] = useState(false);
    const { user } = useAuthenticator((context) => [context.user]);
    const { workspace_id } = useParams();
    const { pathname } = useLocation();
    const copyAccessToken = getAccessTRoken();

    useEffect(() => {
        api.get(`/tenants/find-email/${user.attributes['email']}`)
            .then(({ data }) => {
                setAllTenants(data);
            })
    }, [user.attributes])

    useEffect(() => {
        if (pathname === '/new-group' && allTenants.length === 0) {
            setCreateWorkspace(true)
        } else {
            setCreateWorkspace(false)
        }
    }, [pathname, allTenants])

    useEffect(() => {
        if (workspace_id) {
            setCreateWorkspace(true)
            const tenant = allTenants.filter(tenant => tenant.id === workspace_id)[0];
            if (tenant) {
                setNameTenant(tenant.name);
                tenant.users.forEach((user, index) => {
                    user.id = index + 1;
                })
                setUsers(tenant.users);
            }
        }
    }, [workspace_id, user, allTenants])

    useEffect(() => {
        setEditTenant(false)
        const tenant = allTenants.filter(tenant => tenant.id === currentTenant)[0];
        if (tenant && tenant.users)
            tenant.users.map(tenantUser => {
                if (tenantUser.email === user.attributes['email'] && tenantUser.admin === true) {
                    setEditTenant(true)
                }
                return {};
            })
    }, [currentTenant, user, allTenants])

    const changeWorkspace = (e) => {
        e.preventDefault();
        if (Number(currentTenant) !== 0) {
            if (localStorage.getItem('currentTenant') === currentTenant) {
                api.defaults.headers.common['x-tenant-id'] = localStorage.getItem('currentTenant');
                apiSocial.defaults.headers.common['x-identifier'] = localStorage.getItem('currentTenant');
                setTimeout(()=> window.location.href = '/', 500); 
            } else {
                localStorage.setItem('currentTenant', currentTenant);
                api.defaults.headers.common['x-tenant-id'] = localStorage.getItem('currentTenant');
                apiSocial.defaults.headers.common['x-identifier'] = localStorage.getItem('currentTenant');
                setTimeout(()=> window.location.href = '/', 1000);  
            }
        } 
    }

    const onChangeUsers = (event, id) => {
        const { name, value } = event.target;
        const currentUser = users.filter(user => user.id === id)[0];
        const remainingUsers = users.filter(user => user.id !== id);
        const updatedUser = Object.assign(currentUser, { id: id, [name]: value })
        const sortedUsers = [...remainingUsers, updatedUser].sort((a, b) => a.id - b.id)
        setUsers(sortedUsers);
    }

    const handleSubmitTenant = (event) => {
        event.preventDefault();
        const name = nameTenant.trim();
        if (!name) return;

        setLoading(true);
        if (!workspace_id) {
            const formattedUsers = users;
            formattedUsers.unshift({ email: user.attributes['email'], admin: true })
            formattedUsers.map(user => {
                if (typeof user.id === "number")
                    delete user.id;
                return []
            })
            api.post("/tenants", { name: name, users: formattedUsers }).then(({ data }) => {
                localStorage.setItem('currentTenant', data.id)
                Auth.updateUserAttributes(user, {
                    'custom:animes_access': "1",
                }).then(() => {
                    setLoading(false);
                    setPrimaryTourGuiado(true);
                    window.location.href = '/';
                }).catch(err => {
                    setLoading(false);
                    console.error("Error create tenant", err);
                })
            });
        } else {
            api.put(`/tenants`, { id: workspace_id, name: name, users: users }).then(({ data }) => {
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.error("Error create tenant", err);
            })
        }
    }

    const btnLoadingGrow = (<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />);

    const handleCurrentTenant = (e) => {
        localStorage.setItem('currentTenant', e.target.value);
        setCurrentTenant(e.target.value);
    }

    const handleClickCopy = () => navigator.clipboard.writeText(copyAccessToken);

    return (
        <div className='switchWorkspaceContainer'>
            <div className="formContainer">
                <div className="tabs">
                    <ul>
                        <li className={createWorkspace === false ? 'createWorkspace active' : 'createWorkspace'} onClick={() => setCreateWorkspace(true)}>{!workspace_id ? 'Novo Workspace' : 'Editar Workspace'}</li>
                        {!workspace_id &&
                            <li className={createWorkspace === true ? 'selectWorkspace active' : 'selectWorkspace'} onClick={() => setCreateWorkspace(false)}>Selecionar Workspace</li>
                        }
                    </ul>
                    {allTenants !== [] && !createWorkspace ?
                        <form className='formCreate form-center'>
                            <h1>Selecione o seu Workspace</h1>
                            <select className='selected-workspaces' name="currentTenant" onChange={(e) => handleCurrentTenant(e)} >
                                <option value={0} >Selecionar Workspace</option>
                                {allTenants.map((tenant) => (
                                    <option key={tenant.id} selected={ currentTenant === tenant.id ? true: false } value={tenant.id}>
                                        {tenant.name}
                                    </option>
                                ))}
                            </select>
                            
                         <div className='infor-workspaces mt-4'>
                            <p><strong>Tenant_id:</strong> {currentTenant} </p>
                            <p>
                                <strong>Access Token: </strong>
                                eyJhbGciOiJIUzI1NiIsInR5c...
                                <span className='copy-infor-workspace' onClick={handleClickCopy}>
                                    <img className='ms-3' src={copy} width="15" alt='img-icon-copy' />
                                    <span> Copiar</span>
                                </span> 
                            </p>
                         </div>
                            
                            <div className="displayButtons">
                                <button onClick={changeWorkspace}>
                                    Entrar
                                </button>
                                {editTenant &&
                                    <Link to={`/edit-workspace/${currentTenant}`} >
                                        <button>Editar</button>
                                    </Link>
                                }
                            </div>
                        </form>
                        :
                        <form className='formSelect'>   
                            <div className='topBackPageWorkspace'>        
                                <Link to={`/switch-workspace`} >
                                    <button> <img src={arrowBack} alt="seta" /> Cancelar </button>
                                </Link>
                            </div>
                            <div className='form-center'>

                                {!workspace_id ?
                                    <h1>Crie um Workspace</h1>
                                    :
                                    <h1>Modifique o seu Workspace</h1>
                                }
                                <div className="card-users card-custom">
                                    <label>Nome</label>
                                    <input
                                        required
                                        name='name'
                                        value={nameTenant}
                                        onChange={(e) => setNameTenant(e.target.value)}
                                    />
                                </div>
                                {users.map((user, index) => {
                                    return <div key={index} className="card-users">

                                        <label>Email</label>
                                        <div className="display-input">
                                            <input name="email" value={user.email} onChange={(e) => onChangeUsers(e, user.id)} type="text" />
                                            {!user.admin &&
                                                <button className="btn-remove-user" type="button" onClick={() => setUsers(users.filter((editingUser) => editingUser.id !== user.id))} >
                                                    <img src={trash} alt="" />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                })}
                                <div className="displayButtonAdd">
                                    <button className="btn-add-user" onClick={(e) => { e.preventDefault(); setUsers([...users, { id: users.length + 1, email: '' }]) }} >
                                        Adicionar Usuário
                                    </button>
                                </div>
                                {!workspace_id ?
                                    <button onClick={handleSubmitTenant} className="btn-create-workspace">
                                        {loading ?
                                            <span>{btnLoadingGrow} Aguarde...</span>
                                            : <span>Criar Workspace</span>
                                        }
                                    </button>
                                    :
                                    <button onClick={handleSubmitTenant} className="btn-create-workspace">
                                        {loading ?
                                            <span>{btnLoadingGrow} Aguarde...</span>
                                            : <span>Salvar Workspace</span>
                                        }
                                    </button>
                                }
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div>
    )
}


