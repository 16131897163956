import React, { useState, useEffect } from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import api from '../../config/api';
import apiSocial from '../../config/api-social';
import { TourGuiadoSocialMedia } from '../../pages/socialMediaPublication/TourGuiadoSocialMedia';
import { Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ReactTooltip from 'react-tooltip';

import { Link } from 'react-router-dom';
import copy from '../../assets/images/copiar.svg';
import logoTiktok from '../../assets/images/logo-tiktok.png';
import tiktokName from '../../assets/images/tiktok-name.svg';
import calendar from '../../assets/images/calendar.png';
import arrowCollapse from '../../assets/images/seta-collapse.svg';
import mockupRight from '../../assets/images/mockup-tiktok-right.png';
import mockupBottom from '../../assets/images/mockup-tiktok-bottom.png';
import { addUpperCasePrimaryChar } from '../../helpers/addUpperCasePrimaryChar';

export const TiktokSocialMedia = ({ CustomToggle, publication_id }) => {

  const [isAuthenticatedTiktok, setIsAuthenticatedTiktok] = useState(false);
  const [checkboxActiveTiktok, setCheckboxActiveTiktok] = useState(false);

  const [loadingTiktok, setLoadingTiktok] = useState({
    verifyAuthenticate: false,
    connectionTiktok: false,
    disconnectionTiktok: false,
    medias: false,
  });
  const { user } = useAuthenticator((context) => [context.user]);
  const [dataListTiktok, setDataListTiktok] = useState([]);

  const [publication, setPublication] = useState({
    document: {},
    layers: []
  })

  useEffect(() => {
    effectInitial();
    isAUserthenticatedTiktok();

    return () => {
      setIsAuthenticatedTiktok(false);
      setCheckboxActiveTiktok(false);
      setLoadingTiktok({
        verifyAuthenticate: false,
        connectionTiktok: false,
        medias: false,
      });
      setDataListTiktok([]);
      setPublication({ document: {}, layers: [] });
    }

    // eslint-disable-next-line
  }, [user])

  async function effectInitial() {
    try {
      setLoadingTiktok((prevState) => ({ ...prevState, medias: true }));
      const pubs = (await api.get(`/publications/one/${user.attributes.sub}/${publication_id}`)).data;
      const { documentInfo, layerInfo } = pubs;

      setPublication({
        document: documentInfo,
        layerInfo: layerInfo
      });

      let social_network = 'tiktok';
      const medias = (await api.get(`/medias/search/${documentInfo.id}/${social_network}`)).data;
      const mediasTiktok = medias
        .filter((item) => item.extension === 'mp4')
        .map((currentMedia) => {
          return {
            key: currentMedia.id,
            format: currentMedia.format_social_network,
            size: (`${currentMedia.doc_width}x${currentMedia.doc_height}`),
            type: currentMedia.extension,
            width: currentMedia.doc_width,
            height: currentMedia.doc_height,
            url_print_video: currentMedia.url_print.replace('_playerVideo.html', '.mp4'),
            url_print: currentMedia.url_print
          }
        })

      setDataListTiktok(mediasTiktok);

    } catch (error) {
      console.error("ERROR: ", error);
    } finally {
      setLoadingTiktok((prevState) => ({ ...prevState, medias: false }));
    }

  }

  const isAUserthenticatedTiktok = async () => {
    setLoadingTiktok((prevState) => ({ ...prevState, verifyAuthenticate: true }));
    apiSocial.get(`/api/tiktok/find-user-tiktok`)
      .then(({ data }) => {
        if (data.status) {
          setIsAuthenticatedTiktok(data.status);
          setCheckboxActiveTiktok(data.status);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingTiktok((prevState) => ({ ...prevState, verifyAuthenticate: false }));
      })
  }

  const toggleInputIncorporate = className => {
    const element = document.querySelector('.' + className);
    element?.classList.toggle('open');
  }

  const closeModalDisconnect = className => {
    setCheckboxActiveTiktok(true);
    const element = document.querySelector('.' + className);
    element?.classList.toggle('close');
}

  const handeConnectAuthUserTikTok = async () => {
    const tenant = user.storage['currentTenant'];
    const { id: pub_id, type_publication } = publication.document;

    if (!tenant || !pub_id) return;
    setLoadingTiktok((prevState) => ({ ...prevState, connectionTiktok: true }));

    apiSocial.get(`/api/tiktok/authorize/${tenant}/${type_publication}/${pub_id}`)
      .then(redirect => {
        window.location.href = redirect.data.url;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoadingTiktok((prevState) => ({ ...prevState, connectionTiktok: false }));
      })
  }

  const handlStatusMessage = (elem, status, message) => {
    const statusMessage = elem;

    statusMessage.innerHTML = message;
    statusMessage.classList.add(`status-message-${status}`);

    setTimeout(() => {
      statusMessage.innerHTML = "";
      statusMessage.classList.remove(`status-message-${status}`);
    }, 4500);
  }

  const handleUploadTiktok = async (url, key) => {

    let statusText = document.querySelector(`.text-publish-${key}`)
    statusText.innerHTML = 'Enviando...'

    const statusMessage = document.getElementById(`status-message-${key}`);

    const data = { media_url: url, media_id: key };

    apiSocial.post(`/api/tiktok/share_video`, data)
      .then((response) => {
        handlStatusMessage(statusMessage, 'success', 'Video enviado. Verifique sua aba de notificações no App do TikTok.');
        console.log('Upload success: ', response.data);
      })
      .catch((error) => {
        handlStatusMessage(statusMessage, 'error', 'Erro no envio do video ao TikTok.');
        console.error(`Error upload video for Tiktok: `, error)
      })
      .finally(() => {
        statusText.innerHTML = 'PUBLICAR';
      })
  }

  const handleClickCopy = (text) => navigator.clipboard.writeText(text);

  const handleDisconnectTiktok = (e) => {
    console.log('revoke: ' + e.target.name);

    setLoadingTiktok((prevState) => ({ ...prevState, disconnectionTiktok: true }));

    apiSocial.delete('/api/tiktok/revoke')
      .then(() => {
        setIsAuthenticatedTiktok(false);
      }).catch((er) => {
        console.error(er.data);
      }).finally(() => {
        setLoadingTiktok((prevState) => ({ ...prevState, disconnectionTiktok: true }));
      });
  }

  return (
    <TourGuiadoSocialMedia>
      <>
        <Accordion defaultActiveKey="1" data-tour="step-social-media-03">
          <Card>
            <Card.Header>
              <div className="headerLeft">
                <img className="img-fluid logo-media" src={logoTiktok} alt="logotipo do tiktok" />
                <img className="img-fluid logo-name logo-name-tiktok" src={tiktokName} alt="nome do tiktok" />
              </div>
              <div className="headerRight">
                {loadingTiktok.verifyAuthenticate ?
                  <div className='spinnerSocialMedia'>
                    <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
                  </div>
                  :
                  <>

                    <input
                      id="status-tiktok"
                      type="checkbox"
                      className="toggle toggle-tiktok"
                      onChange={() => setCheckboxActiveTiktok(!checkboxActiveTiktok)}
                      defaultChecked={checkboxActiveTiktok}
                      checked={checkboxActiveTiktok}
                    />

                    <label htmlFor="status-tiktok">
                      <span className={`${checkboxActiveTiktok ? "active" : "no-active"}`}>
                        {checkboxActiveTiktok ? "Conectado" : "Desconectado"}
                      </span>
                    </label>
                    <CustomToggle eventKey="0">
                      <img src={arrowCollapse} alt="Accordion" />
                    </CustomToggle>
                  </>
                }

              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {!loadingTiktok.verifyAuthenticate && !isAuthenticatedTiktok &&
                  <div className="alertConnect">
                    <span>Antes é necessário ativar a rede</span>
                    <button className="btn-tiktok" onClick={() => handeConnectAuthUserTikTok()}>
                      {loadingTiktok.connectionTiktok
                        ? <>Conectando...</>
                        : <>Conectar-se com TikTok</>
                      }
                    </button>
                  </div>
                }

                {!loadingTiktok.verifyAuthenticate && isAuthenticatedTiktok && !checkboxActiveTiktok &&
                  <div className="alertConnect modal-disconnect">
                    <button className="btn-close" onClick={()=> closeModalDisconnect('modal-disconnect')}></button>
                    <span>Deseja realmente desconectar-se da rede?</span>
                    <button
                      className="btn-disconnect"
                      name='disconnect-tiktok'
                      onClick={(e) => handleDisconnectTiktok(e)}
                    >
                      Desconectar
                    </button>
                  </div>
                }

                {isAuthenticatedTiktok && dataListTiktok && !loadingTiktok.medias && dataListTiktok.map((item, index) => {
                  let contentIncorporat = "<div>" +
                    "<iframe width='800px' height='450px' src=" + item.url_print + " title='content Incorporat'></iframe>" +
                    "</div>";
                  return (
                    <div className="row" key={index}>
                      <div className="displayBody">
                        <div className="formatPreview">
                          <div className="mockup-content">
                            <img src={mockupRight}  alt="mockupRight" className="right" />
                            <img src={mockupBottom} alt="mockupBottom"  className="bottom-tiktok" />
                              <video className="video-mockup" min-height="360px" width="300" src={item.url_print_video} autoplay controls ></video>
                          </div>
                        </div>
                        <div className="formatInformation">
                          <div className="formatInformationHeader">
                            <Link to={`/schedulePublication/${item.key}`}>   {/*type.publication*/}
                              <button className="btn-scheduling" data-tip="Clique para agendar publicações.">
                                < ReactTooltip className="customTooltip" />
                                <img src={calendar} alt="calendário" />
                              </button>
                            </Link>
                            <button className="btn-incorporate" data-tip="Clique para incorporar em sites ou blogues." onClick={() => toggleInputIncorporate('input-incorporat-tiktok' + index)}>
                            <span>INCORPORAR</span>
                            </button>
                            < ReactTooltip className="customTooltip" />
                            <button className="btn-publish" data-tip="Clique para publicar o vídeo no feed." onClick={() => handleUploadTiktok(item.url_print_video, item.key)}>
                              <span className={`text-publish-${item.key}`}>PUBLICAR</span>
                            </button>
                          </div>
                          <div className="incorporateCode">
                            <div className={`displayInput input-incorporat-tiktok${index}`} >
                              <input type="text" defaultValue={contentIncorporat} />
                              <button className="btn-copy">
                                <img src={copy} alt="" onClick={() => handleClickCopy(contentIncorporat)} />
                              </button>
                            </div>
                          </div>
                          <table>
                            <tbody>
                              <tr>
                                <td className="typeInformation">
                                  <span>Formato:</span>
                                </td>
                                <td className="contentInformation">
                                  <span>{addUpperCasePrimaryChar(item.format, '-')}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className="typeInformation">
                                  <span>Tamanho:</span>
                                </td>
                                <td className="contentInformation">
                                  <span>{item.size}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className="typeInformation">
                                  <span>Tipo:</span>
                                </td>
                                <td className="contentInformation">
                                  <span>{addUpperCasePrimaryChar(item.type)}</span>
                                </td>
                              </tr>
                              <tr>
                                <td className="typeInformation">
                                  <span>Canal de destino:</span>
                                </td>
                                <td className="contentInformation">
                                  <span>Feed</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div id={`status-message-${item.key}`}></div>
                    </div>
                  )
                })}
                {isAuthenticatedTiktok && loadingTiktok.medias ? (
                  <div className='loadingMedias_socialMedias'>
                    <Spinner animation="border" role="status" aria-hidden="true" />
                  </div>
                ) : <></>}

                {isAuthenticatedTiktok && !loadingTiktok.medias && dataListTiktok.length === 0 &&
                  <div className='text-no-content-compatible'>
                    <p>Você não possui nenhum vídeo gerado compatível com TikTok.</p>
                  </div>
                }

              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    </TourGuiadoSocialMedia>
  );
}